import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Header::Base ...attributes>\n  <:start as |start|>\n    <start.LogoLink />\n\n    <start.Button\n      @tooltip={{t \"components.header.product.search\"}}\n      @active={{this.isNavigationDrawerOpen}}\n      @onClick={{this.openNavigationDrawer}}\n      as |button|\n    >\n      <button.Icon @name=\"search-menu\" />\n    </start.Button>\n\n    {{#if this.breadcrumbEntities.length}}\n      <start.EntityBreadcrumb\n        class=\"nx1 hide-sm\"\n        @entities={{this.breadcrumbEntities}}\n      />\n    {{/if}}\n\n    <start.MetaButton />\n  </:start>\n</Header::Base>\n\n{{#if this.isNavigationDrawerOpen}}\n  <Header::Base::NavigationDrawer\n    @activeProduct={{@activeProduct}}\n    @activeCourse={{@activeCourse}}\n    @inCourse={{false}}\n    @onClose={{this.closeNavigationDrawer}}\n  />\n{{/if}}", {"contents":"<Header::Base ...attributes>\n  <:start as |start|>\n    <start.LogoLink />\n\n    <start.Button\n      @tooltip={{t \"components.header.product.search\"}}\n      @active={{this.isNavigationDrawerOpen}}\n      @onClick={{this.openNavigationDrawer}}\n      as |button|\n    >\n      <button.Icon @name=\"search-menu\" />\n    </start.Button>\n\n    {{#if this.breadcrumbEntities.length}}\n      <start.EntityBreadcrumb\n        class=\"nx1 hide-sm\"\n        @entities={{this.breadcrumbEntities}}\n      />\n    {{/if}}\n\n    <start.MetaButton />\n  </:start>\n</Header::Base>\n\n{{#if this.isNavigationDrawerOpen}}\n  <Header::Base::NavigationDrawer\n    @activeProduct={{@activeProduct}}\n    @activeCourse={{@activeCourse}}\n    @inCourse={{false}}\n    @onClose={{this.closeNavigationDrawer}}\n  />\n{{/if}}","moduleName":"babel/components/header/product/index.hbs","parseOptions":{"srcName":"babel/components/header/product/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { trackPageHeaderEvent } from 'babel/utils/matamo-events';

export default class ProductHeader extends Component {
  @tracked isNavigationDrawerOpen = false;

  get breadcrumbEntities() {
    const ancestors = this.args.activeEntity?.ancestors?.toArray() || [];

    return [
      this.args.activeEntity,
      ...ancestors,
      this.args.activeProduct?.hasProductPage ? this.args.activeProduct : null,
    ]
      .filter(Boolean)
      .reverse();
  }

  @action
  openNavigationDrawer() {
    this.isNavigationDrawerOpen = true;

    trackPageHeaderEvent('Innehållsmeny - Öppna', 'produkt');
  }

  @action
  closeNavigationDrawer(initiator) {
    this.isNavigationDrawerOpen = false;

    if (initiator !== 'navigation') {
      trackPageHeaderEvent('Innehållsmeny - Stäng', 'produkt');
    }
  }
}
