import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class TeacerAssignmentDetailComponent extends Component {
  @service router;
  @service colorTheme;

  @action
  onCancel() {
    if (this.args.cancelRouteName) {
      return this.router.transitionTo(
        this.args.cancelRouteName,
        this.args.teacherAssignment.id
      );
    }

    return this.router.transitionTo(this.args.indexRouteName);
  }
}
