define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "components": {
      "additionCollator": {
        "filterSelectItems": {
          "all": "All"
        },
        "filterSelectLabel": "Filter",
        "searchButtonLabel": "Search",
        "searchInputLabel": "Search",
        "searchInputPlaceholder": "Search ..."
      },
      "additionCustomizer": {
        "group": {
          "section": {
            "toggleButtonLabel": "{isExpanded, select, true {Expand} false {Collapse} }",
            "typeLabel": "{type, select, teacherAssignments {Assignments} wordlists {Wordlists} other {Addition} }"
          }
        }
      },
      "answerComments": {
        "calendar": {
          "lastWeekAtTime": "{dayName} at {time}",
          "lastWeekAtTimeLower": "{dayName} at {time}",
          "todayAtTime": "Today at {time}",
          "todayAtTimeLower": "today at {time}",
          "yesterdayAtTime": "Yesterday at {time}",
          "yesterdayAtTimeLower": "yesterday at {time}"
        },
        "commentButton": "Comment",
        "commentPlaceholder": "Write a comment",
        "events": {
          "heading": "History",
          "rejectedUserCommented": "<em>Deleted user</em> added a comment",
          "rejectedUserCorrect": "<em>Deleted user</em> got version {version} correct",
          "rejectedUserStartedVersion": "<em>Deleted user</em> started a new version",
          "rejectedUserSubmitted": "<em>Deleted user</em> submitted version {version}",
          "rejectedUserWrong": "<em>Deleted user</em> got version {version} wrong",
          "someoneCommented": "{name} added a comment",
          "someoneCorrect": "{name} got version {version} correct",
          "someoneStartedVersion": "{name} started a new version",
          "someoneSubmitted": "{name} submitted version {version}",
          "someoneWrong": "{name} got version {version} wrong",
          "youCommented": "You added a comment",
          "youCorrect": "You got version {version} correct",
          "youNoted": "You added a note",
          "youStartedVersion": "You started a new version",
          "youSubmitted": "You submitted version {version}",
          "youWrong": "You got version {version} wrong"
        },
        "noComments": "No comments",
        "noteButton": "Note",
        "notePlaceholder": "Write a note",
        "notesDisclaimer": "Notes are only visible for you",
        "remove": "Remove",
        "tabs": {
          "comment": "Comment",
          "note": "Own note"
        }
      },
      "answerLog": {
        "commentFilter": {
          "all": "Show all",
          "commented": "Show commented"
        },
        "diagnosis": {
          "aborted": "The attempt was aborted",
          "active": "The attempt is active",
          "attempt": "Attempt {attempt}",
          "noResults": "No results"
        },
        "entityFilter": {
          "allOption": "All",
          "areaLabel": "Area",
          "areaPlaceholder": "Select book first",
          "assignmentSearchLabel": "Search",
          "assignmentSearchPlaceholder": "Search assignment",
          "bookLabel": "Book",
          "contentLabel": "Content",
          "contentPlaceholder": "Select area first"
        },
        "files": {
          "filterOptions": {
            "all": "All"
          }
        },
        "filterShowing": "Showing:",
        "filterString": "{numStatuses, plural, =0 {No statuses} =1 {{lastStatus}} =7 {All statuses} other {{otherStatuses} and {lastStatus}}} {commentStatus, select, commented {with comments} other {}} in {selectedContent, select, null {all contents} other {{selectedContent}}} {assignmentSearch, select, null {} other {where the assignment's title contains {assignmentSearch}}}",
        "markAllAsRead": "Mark all in the selection as reviewed",
        "results": {
          "entityFailed": "The answer cannot be displayed because you no longer have access to the exercise.",
          "entityMissing": "The answer cannot be displayed because the exercise is no longer available.",
          "numStudentsAnswered": "{num} answers",
          "types": {
            "diagnosis": "Diagnoses",
            "standard": "Exercises",
            "teacher_assignments": "Teacher assignments"
          }
        },
        "standard": {
          "allAssignments": "All assignments",
          "autoCorrected": "Auto corrected",
          "filters": {
            "audited": "Reviewed",
            "correct": "Correct answer",
            "inbox": "Turned in*",
            "started": "Started",
            "teacherCorrect": "Completed",
            "teacherWrong": "Not completed",
            "turnedIn": "Turned in",
            "wrong": "Wrong answer"
          },
          "hideFilters": "Hide filters",
          "noResults": "No results",
          "showFilters": "Show filters",
          "teacherCorrected": "Teacher corrected"
        },
        "teacherAssignment": {
          "noResults": "No results"
        },
        "unknownDate": "Unknown date"
      },
      "answerStatusIcon": {
        "exercise": {
          "status": {
            "audited": "Reviewed",
            "correct": "Correct",
            "done": "Answered",
            "editing": "Editing …",
            "incomplete": "Answer(s) missing",
            "notStarted": "Not started",
            "saving": "Saving …",
            "started": "Started",
            "teacherCorrect": "Completed",
            "teacherWrong": "Not completed",
            "turnedIn": "Turned in",
            "wrong": "Wrong"
          }
        }
      },
      "archiveForm": {
        "cancelLinkOrButtonLabel": "Cancel",
        "deleteButtonLabel": "Delete",
        "saveButtonLabel": "Save"
      },
      "archiveView": {
        "allCategories": "All categories",
        "category": "Category",
        "orderBy": "Sorting",
        "random": "Random",
        "search": {
          "noResultsOn": "Your search had no results. Use the general search function for a broad search in the library.",
          "placeholder": "Search the titles on the page …",
          "relatedSearchResults": "Related search results",
          "searchResults": "Search results",
          "searching": "Searching …",
          "showingResults": "Showing {results} of {total} results."
        },
        "showAllInFolder": "Show all in {folder}",
        "sort": {
          "date_asc": "Latest first",
          "date_desc": "Oldest first",
          "inverse": "Inverse",
          "name_asc": "Title A-Z",
          "name_desc": "Title Z-A",
          "random": "Random",
          "standard": "Standard"
        }
      },
      "areaView": {
        "allCategories": "All categories",
        "category": "Category",
        "orderBy": "Sorting",
        "random": "Random",
        "search": {
          "label": "Search results",
          "noResultsOn": "Your search had no results. Use the general search function for a broad search in the library.",
          "placeholder": "Search the titles on the page …",
          "results": "Showing {hits} results.",
          "searching": "Searching …"
        },
        "sort": {
          "name_asc": "Title A-Z",
          "name_desc": "Title Z-A",
          "standard": "Standard"
        },
        "view": {
          "repeat": "Repeat"
        }
      },
      "attachLinkModal": {
        "actions": {
          "attach": "Attach",
          "cancel": "Cancel"
        },
        "link": {
          "label": "Link",
          "placeholder": "Paste your link here"
        },
        "modalHeader": "Attach link",
        "name": {
          "label": "Name",
          "placeholder": "Choose a name"
        },
        "saveFailed": "The link could not be attached"
      },
      "attachmentPreview": {
        "item": {
          "removeButtonLabel": "Remove"
        }
      },
      "availableProducts": {
        "assignedLicenses": "{is_assigned} av {total} licenser",
        "externalLicenses": "{total} externa licenser",
        "extraMaterials": "Extramaterial",
        "licenseTime": "Licenstid {licenseTime}",
        "licenseTimeUntil": "Licenses are valid until {licenseTime}",
        "manageLicenses": "Hantera licenser",
        "noProducts": "Hittade inga produkter",
        "showLicenses": "Visa licenser",
        "unlimitedLicenses": "Obegränsade licenser"
      },
      "bookBrowser": {
        "checkboxLabel": "Show to students"
      },
      "carouselResult": {
        "close": "Close",
        "completed": "Great job, you got all the answers right!",
        "redo": "Redo",
        "result": "{numCorrect} out of {numTotal} correct",
        "tryAgain": "Try again",
        "tryAgainInfo": "{numCorrect} out of {numTotal} correct in total"
      },
      "chapterNavigation": {
        "ofIndex": "of",
        "tableOfContents": "Table of contents"
      },
      "chooseModal": {
        "cancelButtonLabel": "Cancel",
        "chooseButtonLabel": "{count, plural, =0 {Choose} one {Choose} other {Choose ({count})} }",
        "deleteButtonLabel": "{count, plural, =0 {Delete} one {Delete} other {Delete ({count})} }"
      },
      "collectionAudio": {
        "audioToolTip": "Click here to listen"
      },
      "collectionExerciseIndex": {
        "item": {
          "draftBadgeLabel": "Draft",
          "numAnswers": "Amount of answers"
        }
      },
      "collectionList": {
        "assignments": "Assignments"
      },
      "collectionListItem": {
        "carousel": {
          "continue": "Continue",
          "result": "{score} correct out of {scoreMax}"
        },
        "diagnosis": {
          "continue": "Continue",
          "result": "{score} correct out of {scoreMax}"
        },
        "diagnosisInfo": "{count, plural, one {# exercise} other {# exercises}}, {time, plural, one {# minute} other {# minutes}}, {attempts, plural, one {# attempt left} other {# attempts left}}",
        "numExercises": "{count, plural, one {# exercise} other {# exercises}}"
      },
      "collectionModal": {
        "assignments": "Assignments"
      },
      "collectionOptions": {
        "clear": "Clear all answers",
        "preview": "Preview",
        "reset": "This will reset all your answers in the collection <b>{collection}</b>. Do you want to proceed?",
        "share": "Share",
        "showKey": "Show answers"
      },
      "collectionSummary": {
        "close": "Close",
        "title": "Summary"
      },
      "collectionTopbar": {
        "pagination": {
          "firstPage": "First page",
          "lastPage": "Last page",
          "nextPage": "Next page",
          "prevPage": "Previous page"
        },
        "step": "{number} of {total}"
      },
      "collectionView": {
        "top": {
          "practice": "Exercises"
        }
      },
      "contentChapter": {
        "contentIsHidden": "There is no content here."
      },
      "contentCover": {
        "list": {
          "collectionSpaceTitle": "Assignments",
          "noSectionsWarning": "There is no content here",
          "sectionSpaceTitle": "Content",
          "wordlistSpaceTitle": "Wordlists"
        },
        "noSectionsMessage": "No content",
        "start": "Start"
      },
      "contentMeta": {
        "edition": "Version",
        "editionTooltip": "Text version",
        "levelTooltip": "Difficulty",
        "typeTooltip": "Type of content",
        "types": {
          "archives": "Archive",
          "areas": "Area",
          "assignments": "Assignment",
          "books": "Book",
          "collections": "Collection",
          "contents": "Content",
          "exercises": "Exercise",
          "folders": "Folder",
          "glossaries": "Word list",
          "interactives": "Interactive",
          "sections": "Section",
          "studies": "Self study"
        }
      },
      "contentMissionNext": {
        "nextContentLabel": "Next content",
        "proceedButton": "Continue"
      },
      "contentPagination": {
        "back": "Back",
        "done": "Done",
        "nextPage": "Next page"
      },
      "contentPlaysettings": {
        "alwaysSpeechSynthesis": "Always speech synthesis",
        "behavior": "Behaviour",
        "marking": "Text highlighting",
        "markings": {
          "none": "Off",
          "onlySentences": "Sentences only",
          "onlyWords": "Words only",
          "wordsAndSentences": "Words and sentences"
        },
        "showSpeakerInfo": "Show instructions",
        "speechSpeed": {
          "fast": "Fast",
          "slow": "Slow",
          "standard": "Default",
          "verySlow": "Very slow"
        },
        "speechSpeedDisabled": "The setting does not work for recorded speech on your device, but you can still set the speed of speech synthesis.",
        "speed": "Reading speed",
        "stickWithText": "Autoscroll text",
        "voice": "Voice"
      },
      "contentSpeakerInfoModal": {
        "click": "Press <b> Listen </b> above the text to turn the reading function on or off. Press a piece of text to start or pause the reading.",
        "clickHeading": "You can listen to all text",
        "confirm": "Okay, I understand",
        "customize": "You can customize the listening experience just as you want it, for example changing pace and voice. Press <b>Customize</b> at the top to select your settings. There you can also find this information again.",
        "customizeHeading": "Customize the listening experience",
        "heading": "Listen to content",
        "keyboard": "You can control playback with your keyboard. Use the following commands: <ul class=\"nt1\"><li><b>Shift and spacebar: Start and stop</b></li><li><b>Shift and arrow up: Previous paragraph</b></li><li><b>Shift and arrow down: Next paragraph</b></li></ul>",
        "keyboardHeading": "Control with the keyboard"
      },
      "contentSummary": {
        "header": "This is the last page",
        "ingress": "Continue to work with this section:"
      },
      "contentTopbar": {
        "exercises": "Assignments",
        "notes": "Notes",
        "settings": {
          "customize": "Customize",
          "listen": "Listen",
          "presentation": "Appearance",
          "reading": "Listen",
          "speakerToggleButtonTooltip": "Reading is not available for the current language.",
          "textVersions": "Text versions"
        },
        "wordlists": "Wordlists"
      },
      "contentsStory": {
        "hideText": "Hide text",
        "showText": "Show text"
      },
      "contextSelect": {
        "selectCourseContext": {
          "heading": "Select course",
          "info": "This content is part of several courses you are participating in. Select a course to continue."
        },
        "selectProductContext": {
          "heading": "Select product",
          "info": "This book is part of more than one licensed product. Select product to continue."
        }
      },
      "cookieMessage": {
        "about": "About cookies and personal information",
        "accept": "Accept cookies",
        "info": "We use cookies to provide you with a better experience on our website and our digital products.",
        "title": "This site uses cookies"
      },
      "countdownTimer": {
        "minutesShort": "min"
      },
      "courseAdaptSettings": {
        "changeImageButtonText": "Change image",
        "chooseImageButtonText": "Choose image",
        "coverImage": "Cover image",
        "hasInternalAndExternalProducts": "The course contains books that are not affected by these settings.",
        "hideDiagnosis": "Lock diagnostic tests",
        "hideDiagnosisSubLabel": "Unlock a diagnosis by sharing it through a mission",
        "hideKey": "Hide key",
        "hideLabel": "Key and diagnoses in the course",
        "language": {
          "en": "English",
          "noSelectedLanguage": "User language setting",
          "sv": "Svenska"
        },
        "languageLabel": "Language",
        "overviewSortSetting": "Display order",
        "removeImageButtonText": "Remove image",
        "showProducts": "Show books",
        "sort": {
          "missions": "Missions first",
          "shortcuts": "Shortcuts first"
        }
      },
      "courseAudit": {
        "audit": "Follow-up",
        "bottombar": {
          "edit": "Edit",
          "hideKeyTooltip": "Hide key",
          "hideSolutionTooltip": "Hide key",
          "keyTooltip": "Show key",
          "nextStudent": "Next student",
          "solutionTooltip": "Show key",
          "status": {
            "audited": "Reviewed",
            "correct": "Correct",
            "done": "Answered",
            "editing": "Editing …",
            "incomplete": "Answer(s) missing",
            "notStarted": "Not started",
            "saving": "Saving …",
            "started": "Started",
            "teacherCorrect": "Completed",
            "teacherWrong": "Not completed",
            "turnedIn": "Turned in",
            "wrong": "Wrong"
          },
          "version": "Version {version}"
        },
        "breadcrumb": {
          "comments": "Comments",
          "inbox": "New",
          "index": "Index",
          "log": "Students",
          "missions": "Missions",
          "overview": "All contents"
        },
        "collection": {
          "assignments": "Assignments",
          "compare": "Compare",
          "exercise": {
            "noAnswers": "No answers turned in for this exercise"
          },
          "students": "Students"
        },
        "comments": {
          "calendar": {
            "lastWeekAtTimeLower": "{dayName} at {time}",
            "todayAtTimeLower": "today at {time}",
            "yesterdayAtTimeLower": "yesterday at {time}"
          },
          "commentedOn": "{userString} commented {dateString}",
          "noComments": "Could not find any comments.",
          "you": "You"
        },
        "diagnosis": {
          "group": {
            "attempts": "{num} attempts"
          },
          "noDiagnosis": "The book does not contain any diagnostic tests.",
          "part": {
            "attempts": "{num} attempts",
            "diagnosisResultTypes": {
              "attempt": "Attempt {attempt}",
              "best": "The best attempt",
              "first": "The first attempt",
              "latest": "The latest attempt"
            },
            "loadingDiagnosis": "Loading attempts",
            "noAttempts": "No attempts"
          }
        },
        "modalTitle": {
          "comments": "Comments",
          "inbox": "New",
          "log": "Students",
          "overview": "All"
        },
        "standard": {
          "entity": {
            "compare": "Compare",
            "loading": "Loading answers",
            "noAnswers": "No answers",
            "noAudits": "There are no exercises here.",
            "teacherAssignments": "Teacher assignments"
          },
          "exercise": {
            "numAnswered": "{count} av {total}"
          }
        },
        "studentList": {
          "numAnswers": "Amount of answers"
        },
        "teacherAssignments": {
          "noTeacherAssignments": "The book does not contain any teacher assignments.",
          "teacherCollection": {
            "compare": "Compare"
          }
        },
        "versionPopup": {
          "version": "Version {version}",
          "versions": "Versions"
        }
      },
      "courseMain": {
        "menu": {
          "audit": "Follow-up",
          "books": "Content",
          "missions": "Plan missions",
          "myResults": "My activity",
          "overview": "Overview",
          "settings": "Settings"
        },
        "pages": "Course pages",
        "restore": "Restore",
        "restoreInfo": "The course is archived and therefore not visible to the course members.",
        "teacherLicenseAlert": {
          "linkAction": "Administer course",
          "studentMissingLicense": "The course has users who lack licenses.",
          "teacherMissingLicense": "A license is required."
        },
        "userLicenseAlert": {
          "studentMessage": "A license is required. Please inform your teacher."
        }
      },
      "courseMissionActivateModal": {
        "activateButtonLabel": "{count, plural, 0 {Add selected} other {Add selected ({count})} } ",
        "cancelButtonLabel": "Cancel",
        "title": "Add from existing course"
      },
      "courseMissionCopyFromModal": {
        "cancelButtonLabel": "Cancel",
        "chooseCourseButtonLabel": "Choose course",
        "copyButtonLabel": "{count, plural, 0 {Kopiera valda} other {Kopiera valda ({count})} } ",
        "instructionsMessage": "Choose a course to copy missions from:",
        "noMissionsSelected": "You need to select at least one mission mission to copy.",
        "title": "Copy mission"
      },
      "courseMissionCopyToModal": {
        "cancelButtonLabel": "Cancel",
        "copyButtonLabel": "Copy to ({count})",
        "instructionsMessage": "Select courses to copy the mission to",
        "title": "Copy",
        "validationErrorMessage": "You must select at least one course to copy to."
      },
      "courseProductCard": {
        "new": "New"
      },
      "courseShortcutActionMenu": {
        "archive": "My archive",
        "file": "Own file",
        "internalLink": "Product content",
        "link": "Link"
      },
      "courseShortcuts": {
        "addShortcut": "Add shortcut",
        "done": "Done",
        "noShortcuts": "No shortcuts added",
        "sort": "Sort"
      },
      "courseUserList": {
        "courseNoUsers": "There are no students connected to this course. You can add students under Administer course.",
        "courseSettings": "Administer course",
        "hasNotLoggedIn": "Has not logged in",
        "helpList": {
          "activate": "Start help list",
          "active": "The symbol for the help list is now visible to your course participants. When someone asks for help, you'll see it here.",
          "deactivate": "Turn off help list",
          "description": "In the help list you can see whose turn it is to get help.",
          "empty": "No one has asked for help yet",
          "info": "When you start the help list, it will be active for 90 minutes or until you turn it off.",
          "title": "Help list"
        },
        "popover": {
          "licenses": "Licenses"
        },
        "product": {
          "extended": "Extended",
          "handleLicenses": "Handle licenses"
        },
        "students": "Students",
        "teachers": "Teachers",
        "user": {
          "administerUser": "Administer student",
          "administerUserDescription": "Handle student settings such as class, name, and password. View the student’s licenses and courses.",
          "auditComments": "View comments",
          "auditExercises": "View exercises",
          "showLog": "View the log"
        }
      },
      "customContentDetailModal": {
        "cancelButtonLabel": "Cancel",
        "numberOfTriesInputLabel": "Number of tries",
        "numberOfTriesInputPlaceholder": "Unlimited",
        "saveButtonLabel": "Save",
        "teacherMaterial": "Teacher material",
        "title": "Customize: {entityTitle}"
      },
      "diagnosisDone": {
        "close": "Close diagnosis",
        "textResult": "You had {correct} correct out of {total} possible",
        "yourAnswers": "Your answers"
      },
      "diagnosisIntro": {
        "aborted": "Aborted",
        "cancelButton": "Cancel",
        "diagnosisDisabledInCourse": "Diagnoses are disabled by your teacher.",
        "info": "Time: {allowedTime} min, Exercises: {count}",
        "noAttemptsLeft": "You have no attempts left.",
        "noResult": "No previous attempts",
        "onTimeUp": "If the time runs out, the diagnosis will end.",
        "pointsRequired": "If you score less than {required}, you need to practice more.",
        "result": "{score} of {scoreMax}",
        "resultsSummary": {
          "aborted": "Aborted",
          "attemptNumber": "Attempt {attempt}",
          "result": "{score} correct out of {scoreMax}"
        },
        "showAttempts": "Show attempts ({attempts})",
        "startButton": "Start attempt {count} of {total}",
        "startInfo": "Once the diagnosis has been started, it cannot be paused.",
        "teacherAttemptsInfo": "As a teacher, you can always start a diagnosis."
      },
      "diagnosisShow": {
        "savingResult": "Saving your result"
      },
      "entityBrowser": {
        "tree": {
          "item": {
            "collapse": "Close",
            "expand": "Open",
            "external": "External product"
          }
        }
      },
      "entityCustomizer": {
        "tree": {
          "group": {
            "toggleButtonLabel": "{isAllIncluded, select, true {Deselect all} false {Select all} }"
          },
          "item": {
            "numberOfTriesHintLabel": "tries",
            "timeAllowed": "minutes",
            "toggleButtonLabel": "{isExpanded, select, true {Expand} false {Collapse} }"
          }
        }
      },
      "entityImportModal": {
        "cancelButtonLabel": "Cancel",
        "importButtonLabel": "Choose",
        "noEntities": "The course has no books. Go to Administer course and select books for the course.",
        "noEntitiesStudent": "The course has no books.",
        "title": "Choose content"
      },
      "entityIndex": {
        "item": {
          "headingLabel": "Assignments"
        }
      },
      "entityInspector": {
        "detail": {
          "authors": "{count, plural, one {Author} other {Authors} }",
          "codeCopiedToClipboard": "The code was added to the clipboard",
          "codeFailedToCopyToClipboard": "The code could not be added to the clipboard",
          "copyReference": "Copy reference",
          "description": "Description",
          "illustrators": "{count, plural, one {Illustrator} other {Illustrators} }",
          "includedInProducts": "{num, plural, =0 {The material is not included in any products.} =1 {The material is included in the product:} other {The material is included in the products:}}",
          "isbn": "(ISBN) {id}",
          "language": "Language",
          "languageCodes": {
            "Danska": "Danish",
            "da_DK": "Danish",
            "de_DE": "German",
            "en_GB": "English",
            "es_ES": "Spanish",
            "fr_FR": "French",
            "it_IT": "Italian",
            "nn_NO": "Norwegian",
            "sv_SE": "Swedish"
          },
          "lastUpdated": "Last updated",
          "level": "Level",
          "loading": "Loading …",
          "locationCopiedToClipboard": "The link was added to the clipboard",
          "locationFailedToCopyToClipboard": "The link could not be added to the clipboard",
          "overview": "Overview",
          "product": {
            "articleNumber": "Article number",
            "authors": "{count, plural, one {Author} other {Authors} }",
            "firstReleased": "First edition",
            "illustrators": "{count, plural, one {Illustrator} other {Illustrators} }",
            "translators": "{count, plural, one {Translator} other {Translators} }"
          },
          "productInformation": "Product information",
          "sourceCopiedToClipboard": "The source was added to the clipboard",
          "sourceDigilar": "This content as source",
          "sourceFailedToCopyToClipboard": "The source could not be added to the clipboard",
          "sourceReference": "Source reference",
          "sourceString": "{url} (available: {datetime})",
          "textVersions": "Text versions",
          "translators": "{count, plural, one {Translator} other {Translators} }",
          "type": "Content type",
          "versionTab": {
            "pane": {
              "about": "Information",
              "copyright": "Copyright",
              "entityMaterialGroupList": {
                "item": {
                  "detailLabel": "© {copyright}",
                  "typeLabel": "{type, select, audios {Audio} images {Image} videos {Video} other {Material} }",
                  "typeWithIndexLabel": "{type, select, audios {Audio} images {Image} videos {Video} other {Material} } in chapter {index}"
                }
              },
              "numSections": "Sections:",
              "numWords": "Words:",
              "readingTime": "Estimated reading time:",
              "readingTimeMin": "{time} min"
            }
          }
        },
        "guidanceViewer": {
          "noResultMessage": "There are no {type, select, guidances {teacher guidance} } at this level.",
          "show": {
            "tableOfContents": "Table of contents"
          }
        },
        "missionCustomizer": {
          "categoryIsHidden": "category is now hidden for all",
          "chooseCategory": "Select category",
          "description": "Here you can choose which parts that should be visible in the content for the course participants.",
          "firstChooseCategory": "Select a category to customize",
          "hideCategory": "Hide category",
          "teacherMaterial": "Teacher material",
          "teacherMaterialDescription": "Customizations of teacher material are for all categories."
        }
      },
      "entityMeta": {
        "guidance": "Teacher guidance"
      },
      "entityPreviewPage": {
        "login": "Sign in",
        "types": {
          "archives": "Archive",
          "areas": "Area",
          "assignments": "Assignment",
          "books": "Book",
          "collections": "Collection",
          "contents": "Content",
          "exercises": "Exercise",
          "folders": "Folder",
          "glossaries": "Word list",
          "interactives": "Interactive",
          "sections": "Section",
          "studies": "Self study"
        }
      },
      "entitySearch": {
        "noHit": "The search returned no hits.",
        "results": "Showing {hitsLow}-{hitsHigh} of {totalHits}",
        "searchInputToShort": "The input need to be at least 3 signs long",
        "searchSubmit": "Search",
        "serachInputPlaceholder": "Search for content"
      },
      "errorPage": {
        "content": "Something went wrong while trying to retrieve data from the server. Please try again in a moment.",
        "heading": "The content could not be retrieved",
        "noInternetConnection": "The content is not available offline. Connect to the internet and try again.",
        "other": "The content could not be found. Try to reload the page.",
        "toHomescreen": "Go to the start page",
        "tryAgain": "Try again"
      },
      "exerciseBottombar": {
        "button": {
          "correct": "Answer",
          "done": "Done",
          "hideKeyTooltip": "Hide key",
          "hideSolutionTooltip": "Hide key",
          "keyTooltip": "Show key",
          "keyboardTooltip": "Show keyboard",
          "next": "Next",
          "resetTooltip": "Reset answer",
          "solutionTooltip": "Show key",
          "submit": "Submit"
        },
        "status": {
          "audited": "Reviewed",
          "correct": "Correct",
          "done": "Answered",
          "editing": "Editing …",
          "incomplete": "Answer(s) missing",
          "notStarted": "Not started",
          "saving": "Saving …",
          "started": "Started",
          "teacherCorrect": "Completed",
          "teacherWrong": "Not completed",
          "turnedIn": "Turned in",
          "wrong": "Wrong"
        }
      },
      "exerciseTopbar": {
        "goToExercise": "Go to exercise"
      },
      "exerciseView": {
        "assignment": {
          "check": {
            "incompleteMessage": "You must mark that you are done."
          },
          "choice": {
            "hint": "<strong>Tip!</strong> You have not checked all the correct boxes.",
            "incompleteMessage": "Choose an answer.",
            "incompleteMessageMultiple": "Choose one or more answers."
          },
          "editableMatrix": {
            "incompleteMessage": "Answer is missing."
          },
          "editor": {
            "incompleteMessage": "Write an answer."
          },
          "media": {
            "access": "You have not given access to your microphone",
            "incomplete": "You have to record an audio to submit.",
            "noRecording": "No audio recorded",
            "processing": "Processing",
            "readMore": "Read more",
            "record": "Record audio",
            "recordingFinished": "Recording finished",
            "removeConfirm": "Are you sure? This will delete the audio you have recorded.",
            "removeSoundFile": "Retake",
            "stop": "Stop"
          },
          "missingAnswer": {
            "info": "The answer cannot be displayed. The assignment has been answered previously. The answer is missing but the result is still here. If you want, you can answer the assignment again.",
            "startOver": "Do the assignment again"
          },
          "numberLine": {
            "hint": "<strong>Hint!</strong> You have not selected the correct answer"
          },
          "placeChoices": {
            "highlightMarkersMessage": "Try selecting an answer.",
            "hint": "<strong>Tip!</strong> You have not checked all the correct boxes.",
            "incompleteMessage": "Choose an answer.",
            "incompleteMessageMultiple": "Choose one or more answers."
          },
          "placeImages": {
            "hint": "Hint!",
            "hintMessage": "There are images left that you have to place on a marker.",
            "imagesEmpty": "No more images to pick",
            "triedDrag": "Click on a marker to place the image."
          },
          "placeWords": {
            "hint": "Hint!",
            "hintMessage": "There are words left that you have to place on a marker.",
            "triedDrag": "Click on a marker to place the word.",
            "wordsEmpty": "No more words to pick"
          },
          "reply": {
            "close": "Close",
            "instruction": "Instruction",
            "mathHelp": {
              "content": "<p>SHow to write math using the keyboard: <br> <b>Multiplication:</b> Type the character *<br> <b>Division and fractions: </b> Type the character /<br> <b>Fractions in mixed form:</b> Enter a space after the integer 3 2/5<br> <b>Numbers and mathematical characters:</b> Write without spaces 3+5=8 <i>or</i> 4*3 <i>or</i> 6x</p>",
              "title": "Mathematical characters on the keyboard"
            }
          },
          "sort": {
            "hint": "<strong>Tip!</strong> There are options left that you need to drag to the correct place."
          },
          "textGap": {
            "incompleteMessage": "Answer is missing."
          }
        },
        "comments": "Comments",
        "common": {
          "dragAlternatives": {
            "imageDragEmpty": "No more images to drag.",
            "textGapDragEmpty": "No more words to drag."
          }
        },
        "draftBadgeLabel": "Draft",
        "edit": "Edit",
        "editor": {
          "solution": "Key"
        },
        "hideDescription": "Hide description",
        "missingContenteditor": "<p class=\"text-secondary\">Something went wrong!</p> The assignment could not be loaded.",
        "removeFromWorkspace": "Unlink",
        "reset": {
          "confirm": "Do you really want to reset your answer?"
        },
        "showDescription": "Show description"
      },
      "fileAssignment": {
        "attachButtonLabel": "Attach",
        "attachedHeadingLabel": "Attached files ({count})",
        "fromArchiveButton": "My archive",
        "fromDeviceButton": "Own file",
        "fromLinkButton": "Link"
      },
      "fileChooseModal": {
        "additionCollatorEmptyMessage": "<b>You have no files yet.</b> Here you will find all pictures, video files, audio files and documents that you have attached to various exercises in your books. You can reuse files and attach them to multiple exercises in your books.",
        "additionCollatorNoResultMessage": "No files were found.",
        "title": "Files"
      },
      "fileType": {
        "item": {
          "typeLabel": "{type, select, audio {audio} document {document} image {image} video {video} }"
        }
      },
      "fileUpload": {
        "performButtonLabel": "Upload file ..."
      },
      "fileWarning": {
        "missingFiles": "{num, plural, =1 {An attached file} other {# attached files}} could not be found."
      },
      "focusMode": {
        "action": "Hide menus",
        "close": "Show menus",
        "description": "Your adjustments follow into focus mode",
        "title": "Focus"
      },
      "footnoteList": {
        "headingLabel": "Footnotes"
      },
      "header": {
        "base": {
          "helpButton": {
            "button": "Ask for help",
            "cancel": "Leave queue",
            "description": "If you ask for help, the teacher will see it and you will be placed in a queue.",
            "info": "If many course participants want help at the same time, it can take a little while.",
            "labelStudent": "Ask the teacher for help",
            "labelTeacher": "Help list",
            "que": "You have place <strong>{que}</strong> in the queue.",
            "title": "Need help?",
            "waitingTitle": "You are now asking for help"
          },
          "logoLink": {
            "logoTitle": "Go to the start page for NOKportalen"
          },
          "metaButton": {
            "tooltip": "Content alternatives"
          },
          "metaMenu": {
            "breadcrumb": {
              "courseTitle": "Course startpage",
              "locationCopiedToClipboard": "The link was added to the clipboard",
              "locationFailedToCopyToClipboard": "The link could not be added to the clipboard",
              "menu": {
                "copyLink": "Copy link",
                "share": "Share"
              },
              "productTitle": "Product startpage"
            },
            "courseUsers": "Participants",
            "createCourse": "Create course",
            "customize": "Customize content",
            "information": "Information"
          },
          "navigationDrawer": {
            "courseSettings": "Administer course",
            "fallbackTitle": "Content",
            "start": "Course startpage"
          },
          "supportButton": {
            "label": "Support"
          },
          "teacherButton": {
            "course": "Create course",
            "customize": "Customize content",
            "descriptionCourse": "Collaborate with your students and assign them licenses.",
            "descriptionCustomize": "Select which content to display for your students.",
            "descriptionGuidance": "Information on how to work with the learning material.",
            "descriptionHelpList": "View the students enrolled in the course, and start a digital help list if needed.",
            "guidance": "Teacher's manual",
            "helpList": "Student list",
            "label": "For teachers",
            "news": "News!",
            "teacherButtonInfo": "All teacher-related features are now in one place. Find any available guides here.",
            "title": "Teacher-related features in one place"
          },
          "userDrawer": {
            "account": "My account"
          }
        },
        "book": {
          "search": "Search"
        },
        "course": {
          "search": "Search",
          "switchCoursePopup": {
            "switchCourse": "Switch course"
          }
        },
        "courseUsersButton": {
          "ariaButtonLabel": "Open participants menu",
          "tooltip": "Participants menu"
        },
        "courseUsersDrawer": {
          "title": "Student list"
        },
        "mission": {
          "customizeButtonTooltip": "Customize content",
          "customizeInfoPopup": {
            "description": "Here you can customize which parts of the content and exercises that are shown to your course participants.",
            "title": "Customize the content"
          },
          "leaveMission": "Close mission",
          "returnButtonTooltip": "Back"
        },
        "product": {
          "search": "Search"
        }
      },
      "imageCardCollection": {
        "isCourse": "Course",
        "students": "{students} students",
        "teachers": "{teachers} teachers"
      },
      "interactives": {
        "collectionItem": {
          "carousel": {
            "continue": "Continue",
            "numExercises": "{count, plural, one {# exercise} other {# exercises}}",
            "result": "{score} correct out of {scoreMax}"
          },
          "diagnosis": {
            "continue": "Continue",
            "diagnosisInfo": "{count, plural, one {# exercise} other {# exercises}}, {time, plural, one {# minute} other {# minutes}}, {attempts, plural, one {# attempt left} other {# attempts left}}",
            "result": "{score} correct out of {scoreMax}"
          }
        }
      },
      "latestVisitedContent": {
        "latestVisited": "Last visited:"
      },
      "materialViewer": {
        "attachmentDialog": {
          "downloadHeadingLabel": "Download ...",
          "retryButtonLabel": "Is the file not downloading? Try again"
        },
        "lightbox": {
          "closeButton": {
            "label": "Close"
          }
        },
        "linkDialog": {
          "cancelButtonLabel": "Cancel",
          "continueButtonLabel": "Continue",
          "message": "All links are carefully selected and quality reviewed by our authors. They are continually checked, but we can not be held responsible for any changes or errors that occur over time on other websites. Please help us by <a class=\"ui-link ui-link--link--default\" href=\"{contactUrl}\" target=\"_blank\">reporting</a> any problems to us.",
          "title": "You are leaving Natur & Kultur's platform"
        }
      },
      "missionAddActionMenu": {
        "items": {
          "copy": {
            "description": "Copy missions you have previously created in a course.",
            "label": "Copy mission"
          },
          "new": {
            "description": "Create a new mission.",
            "label": "New mission"
          }
        }
      },
      "missionBrowser": {
        "item": {
          "auditLinkLabel": "Follow-up",
          "cancelSchedulingButtonLabel": "Cancel",
          "editButtonLabel": "Edit",
          "endBadgeLabel": "{daysLeft, plural, =0 {Ends today} one {Ends tomorrow} other {Ends in # days} }",
          "hideButtonLabel": "Hide from the overview",
          "openLinkLabel": "Open",
          "scheduleButtonLabel": "Schedule",
          "scheduledLabel": "Scheduled:",
          "scheduledMessage": "{schedule, select, until {Shown on the overview until {endDate}} infinite {Shown on the overview} ended {Mission ended on {endDate}} between {Shown on the overview between {startDate} and {endDate}} equal {Shown on the overview on {startDate}} from {{daysUntil, plural, one {Shown on the overview starting from tomorrow} other {Shown on the overview starting from {startDate}} }} }",
          "targetedBadgeLabel": "Targeted",
          "toggleButtonLabel": "{isExpanded, select, true {Expand} false {Collapse} }",
          "visibleSwitchLabel": "Show on the overview"
        }
      },
      "missionCollator": {
        "resultAlertMessage": "{hasSearch, select, true {Your search had no results.} false {No missions were found.} }",
        "searchInputLabel": "Search missions",
        "searchInputPlaceholder": "Search by mission name"
      },
      "missionContent": {
        "customContentList": {
          "item": {
            "customizeButtonLabel": "Customize",
            "invalidAlertMessage": "This content cannot be displayed. It belongs to a book that is not included in this course.",
            "rejectedAlertMessage": "This part of the book cannot be displayed. This may be because your license has expired or the content has been removed in a revision."
          }
        },
        "customizeContent": "Customize content",
        "emptyMessage": "{isTeacher, select, true {There is no content for this mission. Click Edit to add content.} false {There is no content for this mission.} }",
        "types": {
          "archives": "Archive",
          "areas": "Area",
          "assignments": "Assignment",
          "books": "Book",
          "books_external": "Book",
          "collections": "Collection",
          "contents": "Content",
          "exercises": "Exercise",
          "folders": "Folder",
          "glossaries": "Word list",
          "interactives": "Interactive",
          "sections": "Section",
          "studies": "Self study"
        }
      },
      "missionDetailModal": {
        "attachMenuButtonItems": {
          "fromArchive": "My archive",
          "fromDevice": "Own file",
          "internalLink": "Product content",
          "link": "Link"
        },
        "attachMenuButtonLabel": "Attach",
        "cancelButtonLabel": "Cancel",
        "customContentList": {
          "addNewButtonLabel": "Add content",
          "customizeMessage": "You can customize individual content once the mission has been saved.",
          "headingLabel": "Content",
          "item": {
            "invalidAlertMessage": "This content cannot be displayed. It belongs to a book that is not included in this course.",
            "rejectedAlertMessage": "This part of the book cannot be displayed. This may be because your license has expired or the content has been removed in a revision.",
            "removeButtonLabel": "Remove content"
          }
        },
        "customizeInfo": "You can hide / show parts of the content by visiting the content inside the mission.",
        "deleteButtonLabel": "Delete",
        "descriptionEditorLabel": "Description",
        "hideSolutionsCheckboxLabel": "Hide key",
        "optionsHeadingLabel": "Options",
        "saveButtonLabel": "Save",
        "targetIndividualReceiversCheckboxLabel": "Target selected students",
        "title": "{isNew, select, true {New mission} false {Edit mission} }",
        "titleInputLabel": "Title",
        "titleInputPlaceholder": "Enter a title",
        "types": {
          "archives": "Archive",
          "areas": "Area",
          "assignments": "Assignment",
          "books": "Book",
          "books_external": "Book",
          "collections": "Collection",
          "contents": "Content",
          "exercises": "Exercise",
          "folders": "Folder",
          "glossaries": "Word list",
          "interactives": "Interactive",
          "sections": "Section",
          "studies": "Self study"
        },
        "visibilityHeadingLabel": "Visibility",
        "visibilityOptions": {
          "hidden": "Hide from the overview",
          "scheduled": "Schedule",
          "visible": "Show on the overview"
        }
      },
      "missionLinkedEntity": {
        "invalidLink": "This content cannot be displayed. It belongs to a book that is not included in this course.",
        "types": {
          "archives": "Archive",
          "areas": "Area",
          "assignments": "Assignment",
          "books": "Book",
          "books_external": "Book",
          "collections": "Collection",
          "contents": "Content",
          "exercises": "Exercise",
          "folders": "Folder",
          "glossaries": "Word list",
          "interactives": "Interactive",
          "sections": "Section",
          "studies": "Self study"
        }
      },
      "missionList": {
        "item": {
          "contentLabel": "Content:",
          "noContentMessage": "No content"
        }
      },
      "missionOptionsActionMenu": {
        "items": {
          "audit": {
            "label": "Follow-up"
          },
          "copy": {
            "label": "Copy mission"
          },
          "edit": {
            "label": "Edit"
          },
          "hide": {
            "label": "Hide from the overview"
          },
          "schedule": {
            "label": "Schedule"
          },
          "share": {
            "label": "Share mission"
          },
          "show": {
            "label": "Show on the overview"
          }
        }
      },
      "missionOptionsButton": {
        "label": "Options"
      },
      "missionScheduleFormPart": {
        "endDateDatepickerLabel": "Until",
        "startDateDatepickerLabel": "From"
      },
      "missionShareLinkModal": {
        "title": "Share mission"
      },
      "modelRejection": {
        "noInternetConnection": "The content is not available offline. Connect to the internet and try again.",
        "other": "The content could not be found. Try to reload the page."
      },
      "noteBrowser": {
        "performMenuButtonItems": {
          "delete": "Delete",
          "edit": "Edit",
          "unlink": "Unlink"
        }
      },
      "noteChooseModal": {
        "additionCollatorEmptyMessage": "<b>You have no notes yet.</b> Here you will find all the notes you have created in your books. If you want to, you can add the same note to multiple places in your books.",
        "additionCollatorNoResultMessage": "No notes were found.",
        "placeholderSearch": "Search in notes",
        "title": "Notes"
      },
      "noteDetail": {
        "activeBreadcrumbItemLabel": "{isNew, select, true {New note} false {Edit note} }",
        "contentInputLabel": "Content",
        "createdLabel": "Created: {created}",
        "indexBreadcrumbItemLabel": "Notes",
        "titleInputLabel": "Title"
      },
      "noticeManager": {
        "maintenanceMessage": {
          "buttonText": "Ok, thanks"
        },
        "snacks": {
          "close": "Close"
        },
        "topMessage": {
          "close": "Close message"
        }
      },
      "pageContentMenu": {
        "courseAdministrationDescriptionMessage": "Handle participants and products for the course.",
        "courseAdministrationLinkLabel": "Administer course"
      },
      "productCollator": {
        "noResultAlertMessage": "No books were found.",
        "searchButtonLabel": "Search",
        "searchInputPlaceholder": "Search books",
        "showExtraMaterial": "Show extra material",
        "stageSelectPlaceholder": "All stages",
        "subjectSelectPlaceholder": "All subjects"
      },
      "productLicenseBrowser": {
        "overlay": {
          "readMoreLinkLabel": "Read more about the product"
        },
        "schoolItem": {
          "activateButtonLabel": "Assign",
          "activatedTextLabel": "Assigned",
          "daysLeftOnLicenseTextLabel": "{daysLeft, plural, one {One day} other {# days} } left of your license",
          "daysLeftOnTextLabel": "The license expires {days}",
          "demoLicenseOnTextLabel": "The trial license expires in {days} days",
          "expiredTextLabel": "The license expired {date}",
          "extendButtonLabel": "Extend",
          "extramaterialPlaceholder": "Free extra material",
          "extramaterialTextLabel": "Extra material",
          "freeTextLabel": "Try for free",
          "freeTooltip": "Try for free 30 days",
          "numberOfLicensesText": "{numberOfUsesLeft, plural, one {1 license} other {# licenses} }",
          "numberOfLicensesTooltip": "Number of licenses to assign",
          "purchase": "Buy",
          "unlimitedPlaceholder": "Unlimited licenses to assign",
          "unlimitedTextLabel": "Unlimited",
          "waitingForExtension": "Will be extended"
        },
        "trialItem": {
          "activatedTextLabel": "Activated",
          "buyLinkLabel": "Buy",
          "daysLeftOnTrialTextLabel": "Trial until {date}",
          "extramaterialTextLabel": "Extra material",
          "extramaterialTooltip": "Free extra material",
          "freeTextLabel": "Try for free",
          "freeTooltip": "Try 30 days for free",
          "freeTooltipUntil": "Try for free until {date}",
          "trialEndedText": "Trial ended",
          "tryButtonLabel": "Add",
          "unabledToCreateLicense": "You have already tested this product.",
          "unlimitedTextLabel": "Unlimited",
          "unlimitedTooltip": "Unlimited licenses to assign"
        }
      },
      "productLicenseManagerModal": {
        "activationCode": "Activation code",
        "addBooksMessageHeading": "Do you want to buy more licenses for your school?",
        "addBooksMessageParagraph": "Do you want to buy more licenses for any product, or buy new products that your school does not yet use? Go to your retailer (Läromedia, GR or Skolon) or go to <a href=\"https://nok.se\" target=\"_blank\" class=\"ui-link ui-link--default\">nok.se</a>. You can always try a teaching material 30 days for free under the tab <em>Try books</em>.",
        "handledExternally": "Licenses are managed in {provider}",
        "handledInUnknownSystem": "another system",
        "navItems": {
          "products": "Products",
          "tryForFree": "Try for free"
        },
        "title": "Add products"
      },
      "productPicker": {
        "item": {
          "extramaterialTextLabel": "Extra material",
          "numberOfLicensesText": "{numberOfLicensesLeft, plural, one {1 license} other {# licenses} }",
          "pickButtonLabel": "Add",
          "trialTextLabel": "Try for free",
          "unableToPickButtonLabel": {
            "invalidType": "Internal and external products can not be used in the same course at the moment",
            "teachersOnly": "The product is for teachers only and can not be added to the course"
          },
          "unlimitedTextLabel": "Unlimited",
          "unpickButtonLabel": "Remove"
        }
      },
      "productPickerModal": {
        "abortButtonLabel": "Cancel",
        "saveButtonLabel": "Save",
        "title": "Choose products"
      },
      "productQuerier": {
        "errorAlertMessage": "The books could not be loaded.",
        "extraCheckboxLabel": "Show extra material",
        "noResultAlertMessage": "No books were found.",
        "searchButtonLabel": "Search",
        "searchInputPlaceholder": "Search books",
        "stageSelectPlaceholder": "All stages",
        "subjectSelectPlaceholder": "All subjects"
      },
      "productRoot": {
        "productInformation": "Product information"
      },
      "products": {
        "productActivityList": {
          "continueWhereIwas": "Continue",
          "welcomeProducts": "Content"
        },
        "productMain": {
          "books": "Content",
          "myResults": "My activity",
          "pages": "Product pages"
        }
      },
      "productsCoursesOverview": {
        "filters": {
          "ordering": {
            "all": "Courses first",
            "courses": "Courses first",
            "label": "Showing:",
            "products": "Products first"
          },
          "sorting": {
            "date": "Last added",
            "label": "Order by:",
            "title": "A - Z"
          }
        },
        "subtitles": {
          "courses": "Courses",
          "products": "Products"
        }
      },
      "productsOverview": {
        "filter": {
          "all": "All",
          "courses": "Courses",
          "products": "Products"
        },
        "order": {
          "date": "Last added",
          "title": "Alphabetical order"
        }
      },
      "qrCodeModal": {
        "closeButtonLabel": "Close"
      },
      "readSettings": {
        "action": "Hide menus",
        "behavior": "Behaviour",
        "browse": "Browse",
        "colorSchemes": {
          "dark": "Dark",
          "dyslexi": "Yellow",
          "standard": "Standard"
        },
        "defaultFont": "Default",
        "font": "Font",
        "fontsize": "Text size",
        "fontsizes": {
          "large": "Larger",
          "medium": "Default"
        },
        "lineHeight": "Line height",
        "lineHeights": {
          "large": "Larger",
          "medium": "Default"
        },
        "marking": "Markings in text",
        "markings": {
          "colored": "Completely colored",
          "discreet": "Discreet",
          "underlined": "Underlined"
        },
        "onlyImage": "Images only",
        "onlyText": "Text only",
        "readingRuler": "Reading ruler",
        "scroll": "Scroll",
        "title": "Focus",
        "tools": "Accessibility",
        "translationLink": "Translate"
      },
      "readingBuddy": {
        "avatarImageAlt": "Hund",
        "restartButton": "Från början",
        "states": {
          "ended": "Tack för samtalet! Tryck på play om du vill prata igen.",
          "listening": "Prata in ditt svar, jag lyssnar …",
          "notStarted": "Hej! Prata med mig.",
          "paused": "Jag har pausat.",
          "speaking": "Pratar …"
        }
      },
      "scheduleDetailModal": {
        "cancelButtonLabel": "Cancel",
        "message": "Schedule the display of the mission for your students on the overview.",
        "scheduleButtonLabel": "Schedule",
        "title": "Schedule mission"
      },
      "shareLinkModal": {
        "copy": "Copy link",
        "copyLink": "Copy link",
        "downloadQrCodeButtonLabel": "Download QR code",
        "includeProviderSwitchLabel": "Include login via {provider} in the link",
        "locationCopiedToClipboard": "The link was added to the clipboard",
        "locationFailedToCopyToClipboard": "The link could not be added to the clipboard",
        "message": {
          "body": "{title}",
          "title": "{title}"
        },
        "shareMessage": "The recipient of the link must have access to the content to view it.",
        "shareTo": "Share to:",
        "showQrCodeButtonLabel": "Show QR code",
        "title": "Share content"
      },
      "shareMeta": {
        "locationCopiedToClipboard": "The link was added to the clipboard",
        "locationFailedToCopyToClipboard": "The link could not be added to the clipboard",
        "menu": {
          "aboutContent": "Information",
          "copyLink": "Copy link",
          "share": "Share"
        }
      },
      "shareMetaMenu": {
        "modal": {
          "breadcrumb": {
            "courseTitle": "Course startpage",
            "productTitle": "Product startpage"
          },
          "createCourse": "Create course",
          "information": "Information"
        }
      },
      "simpleSpeechPlayer": {
        "listen": "Lyssna"
      },
      "speakerContext": {
        "noAutoplayAction": "Press here to try again",
        "noAutoplayContent": "For some reason, your browser did not want to start playback.",
        "noAutoplayHeading": "Playback could not be started"
      },
      "stickyUpdateNotice": {
        "buttonLabel": "Hämta ändringar",
        "message": "Licenserna har ändrats av en annan användare."
      },
      "storyModal": {
        "header": "Picture story",
        "ingress": "This is a slide show that can be played automatically with sound. Select Listen to start the slide show. Stop the slide show at any time to read and listen at your own speed.",
        "listenButton": "Listen",
        "onlyImageCheckboxLabel": "Pictures only (text is hidden while listening)",
        "readButton": "I just want to read"
      },
      "studentSelect": {
        "allStudents": "All the students in the course",
        "allStudentsInMission": "All the students in the mission",
        "selectStudent": "Select student"
      },
      "tableOfContents": {
        "list": {
          "assignments": "Assignments",
          "wordlists": "Wordlists"
        }
      },
      "tableOfContentsItem": {
        "headingLabel": "Assignments",
        "numAssignments": "{count, plural, =0 {No assignments} =1 {One assignment} other {# assignments}}"
      },
      "tbRender": {
        "assignmentLink": {
          "excludedAlertMessage": "The teacher has removed the exercise"
        },
        "attachment": {
          "download": "Download"
        },
        "gallery": {
          "fullscreen": "Enlarge image",
          "indexLabel": "Image",
          "indexOf": "of",
          "next": "Next",
          "prev": "Previous"
        },
        "image": {
          "fullscreenTooltip": "View in full screen"
        },
        "placeholder": {
          "toolTip": "You have selected the customization Text only"
        }
      },
      "teacherAssignmentBrowser": {
        "item": {
          "draftBadgeLabel": "Draft"
        }
      },
      "teacherAssignmentChooseModal": {
        "collatorEmptyMessage": "<b>You have no teacher assignments yet.</b> Here you will find all the assignments that you as a teacher have created for different parts of your books. If you want, you can reuse assignments and link them to several places in your books or in several courses for different student groups.",
        "collatorNoResultMessage": "No teacher assignments were found.",
        "placeholderSearch": "Search in assignments",
        "title": "Assignments"
      },
      "teacherAssignmentDetail": {
        "activeBreadcrumbItemLabel": "{isNew, select, true {New assignment} false {Edit assignment} }",
        "answerTemplateEditorLabel": "Answer template",
        "cancelLinkLabel": "Cancel",
        "deleteButtonLabel": "Delete",
        "indexBreadcrumbItemLabel": "Assignments",
        "moreOptionsButtonLabel": "More options",
        "questionEditorLabel": "Assignment",
        "saveButtonLabel": "Publish",
        "saveButtonTooltipLabel": "Share the assignment with your students",
        "switchToDraftButtonLabel": "{isPublished, select, true {Unpublish} false {Save} }",
        "titleInputLabel": "Title",
        "titleInputPlaceholder": "Add title"
      },
      "teacherGuidanceButton": {
        "guidanceButton": "Teacher guidance"
      },
      "themeSwitch": {
        "colorScheme": "Color theme",
        "colorSchemes": {
          "dark": "Dark",
          "dyslexi": "Yellow",
          "standard": "Standard"
        }
      },
      "userExternalLinkDialog": {
        "cancelButtonLabel": "Cancel",
        "continueButtonLabel": "Continue",
        "message": "You will be transferred to the following address. Do you want to continue?",
        "title": "You are leaving Natur & Kultur's platform"
      },
      "userProfile": {
        "logout": "Log out"
      },
      "userSelect": {
        "cancel": "Cancel",
        "done": "Done",
        "list": {
          "noUsersMessage": "No users",
          "numStudents": "{count, plural, one {One student} other {{count} students }}{hasGroups, select, true { in {countGroups, plural, one {one group} other {{countGroups} groups}}} other {}}",
          "searchPlaceholder": "Search users"
        },
        "noAddedUsers": "No students selected",
        "searchHeading": "Search",
        "selectUser": "Select users",
        "table": {
          "userEmailHeading": "E-mail",
          "userNameHeading": "Name"
        }
      },
      "videoPlayer": {
        "advertisement": "Ad",
        "all": "All",
        "buffered": "Buffered",
        "captions": "Captions",
        "currentTime": "Current time",
        "disableCaptions": "Disable captions",
        "disabled": "Disabled",
        "duration": "Duration",
        "enableCaptions": "Enable captions",
        "enabled": "Enabled",
        "end": "End",
        "enterFullscreen": "Enter fullscreen",
        "exitFullscreen": "Exit fullscreen",
        "fastForward": "Forward {seektime}s",
        "frameTitle": "Player for {title}",
        "loop": "Loop",
        "menuBack": "Go back to previous menu",
        "mute": "Mute",
        "normal": "Normal",
        "pause": "Pause",
        "play": "Play",
        "played": "Played",
        "quality": "Quality",
        "qualityBadge": {
          "1080": "HD",
          "1440": "HD",
          "2160": "4K",
          "480": "SD",
          "576": "SD",
          "720": "HD"
        },
        "reset": "Reset",
        "restart": "Restart",
        "rewind": "Rewind {seektime}s",
        "seek": "Seek",
        "seekLabel": "{currentTime} of {duration}",
        "settings": "Settings",
        "speed": "Speed",
        "start": "Start",
        "unmute": "Unmute",
        "volume": "Volume"
      },
      "waitFor": {
        "errorAlertMessage": "The data could not be loaded.",
        "retryButtonLabel": "Retry"
      },
      "wordBrowser": {
        "item": {
          "performMenuButtonItems": {
            "delete": "Delete",
            "edit": "Edit"
          }
        }
      },
      "wordDetail": {
        "activeBreadcrumbItemLabel": "{isNew, select, true {New word} false {Edit word} }",
        "explanationEditorLabel": "Explanation",
        "indexBreadcrumbItemLabel": "Wordlists",
        "translationInputLabel": "Translation",
        "wordInputLabel": "Word"
      },
      "wordExplanationBox": {
        "examples": "Examples",
        "explanation": "Explanation",
        "translation": "Translation"
      },
      "wordQuiz": {
        "answer": "Answer",
        "cancel": "Cancel",
        "next": "Next",
        "noWordsMessage": {
          "concepts": "<p>You can’t use this word list as practice since it doesn’t contain words with explanations. Add explanations by editing each word separately.</p><p>You can also add new words to the list or save highlighted words from our texts.</p>",
          "glossaries": "<p>You can’t use this word list as practice since it doesn’t contain words with translations. Add translations by editing each word separately.</p><p>You can also add new words to the list or save highlighted words from our texts.</p>"
        },
        "quiz": {
          "explanation": "Explanation",
          "inputPlaceholder": "Write answer …",
          "practiceOnWords": "Practice",
          "replaceWord": "(word)",
          "summary": "You had {result} of {total} correct answers.",
          "translation": "Translation",
          "word": "Word",
          "wrongAnswer": "Oops! The correct answer is:"
        },
        "restart": "Restart",
        "summary": "You had {result} of {total} correct answers."
      },
      "wordSave": {
        "save": "Save",
        "wordExplanation": {
          "addPlaceholder": "Add to …",
          "examples": "Examples",
          "explanation": "Explanation",
          "listName": "Words from {title}",
          "savedTo": "Saved to: {list}",
          "translation": "Translation",
          "wordNotSavedMessage": "The word could not be saved.",
          "wordSavedMessage": "The word has been saved in the word list."
        }
      },
      "wordlistBrowser": {
        "item": {
          "draftBadgeLabel": "Draft",
          "numWords": "{num, plural, =0 {No words} =1 {# word} other {# words}}",
          "performMenuButtonItems": {
            "addWord": "Add word",
            "delete": "Delete",
            "duplicate": "Save a copy",
            "edit": "Edit",
            "publish": "Publish",
            "quiz": "Practice",
            "unlink": "Unlink",
            "unpublish": "Unpublish"
          }
        }
      },
      "wordlistChooseModal": {
        "additionCollatorEmptyMessage": "<b>You have no wordlists yet.</b> Here you will find all the wordlists you have created in your books. If you want to, you can add the same wordlist to multiple places in your books.",
        "additionCollatorNoResultMessage": "No wordlists were found.",
        "placeholderSearch": "Search in wordlists",
        "title": "Wordlists"
      },
      "wordlistDetail": {
        "activeBreadcrumbItemLabel": "{isNew, select, true {New wordlist} false {Edit wordlist} }",
        "cancelLinkOrButtonLabel": "Cancel",
        "createdLabel": "Created: {created}",
        "deleteButtonLabel": "Delete",
        "indexBreadcrumbItemLabel": "Wordlists",
        "languageSelectLabel": "Language",
        "nameInputLabel": "Title",
        "saveButtonLabel": "{userRole, select, teacher {{isPublished, select, true {Update} false {Publish} }} other {Save} }",
        "saveButtonTooltipLabel": "Share the wordlist with your students",
        "switchToDraftButtonLabel": "{isPublished, select, true {Unpublish} false {Save} }",
        "typeRadioButtonGroupItems": {
          "concepts": {
            "description": "Write explanations of words in the same language in which the words are written.",
            "label": "Terms"
          },
          "glossaries": {
            "description": "Translate words from another language.",
            "label": "Vocabulary"
          }
        },
        "typeRadioButtonGroupLabel": "Type of wordlist"
      },
      "wordlistView": {
        "draftBadgeLabel": "Draft",
        "duplicateButtonLabel": "Save a copy",
        "editLinkLabel": "Edit",
        "indexBreadcrumbItemLabel": "Wordlists",
        "newLinkLabel": "New word",
        "quizButtonLabel": "Practice",
        "unlinkButtonLabel": "Unlink",
        "wordBrowserEmptyAlertMessage": "This wordlist has no words yet."
      },
      "workspace": {
        "assignments": {
          "edit": {
            "activeBreadcrumbItemLabel": "{isNew, select, true {New assignment} false {Edit assignment} }"
          },
          "error": {
            "assignments": {
              "buttonText": "Continue",
              "heading": "The exercise has been removed"
            },
            "notes": {
              "buttonText": "Continue",
              "heading": "The note has been removed"
            },
            "wordlists": {
              "buttonText": "Continue",
              "heading": "The wordlist has been removed"
            }
          },
          "index": {
            "contentWorkspaceTitle": "Assignments",
            "importButtonLabel": "From my archive",
            "newLinkLabel": "New assignment",
            "noAssignments": "<p><strong>There are no assignments here.</strong> The reason might be:</p><ul><li>No assignments have been created in this position.</li><li>A teacher has chosen to hide the assignments.</li></ul>{isTeacher, select, true {<p>As a teacher you can add your own assignments here.</p>} other {}}"
          },
          "show": {
            "backTooltip": "Back to assignments",
            "close": "Close"
          }
        },
        "notes": {
          "edit": {
            "activeBreadcrumbItemLabel": "{isNew, select, true {New note} false {Edit note} }"
          },
          "error": {
            "buttonText": "Continue",
            "heading": "The note has been removed"
          },
          "index": {
            "contentWorkspaceTitle": "Notes",
            "currentNoResultAlertMessage": "You have no current notes for this content.",
            "importButtonLabel": "From my archive",
            "newLinkLabel": "New note",
            "relatedAccordionHeaderLabel": "Related notes"
          }
        },
        "topbar": {
          "close": "Close"
        },
        "wordlist": {
          "edit": {
            "activeBreadcrumbItemLabel": "{isNew, select, true {New wordlist} false {Edit wordlist} }"
          },
          "error": {
            "buttonText": "Continue",
            "heading": "The wordlist has been removed"
          },
          "index": {
            "contentWorkspaceTitle": "Wordlists",
            "currentNoResultAlertMessage": "You have no current wordlists for this content.",
            "importButtonLabel": "From my archive",
            "newLinkLabel": "New wordlist",
            "relatedAccordionHeaderLabel": "Related wordlists"
          },
          "show": {
            "draftBadgeLabel": "Draft"
          },
          "words": {
            "edit": {
              "activeBreadcrumbItemLabel": "{isNew, select, true {New word} false {Edit word} }"
            }
          }
        }
      }
    },
    "compton": {
      "alert": {
        "dismissButtonLabel": "Dismiss"
      },
      "audio": {
        "airPlayLabel": "AirPlay",
        "errorMessage": "The audio could not be loaded.",
        "loadingLabel": "Loading …",
        "pauseLabel": "Pause",
        "playLabel": "Play",
        "settingsLabel": "Settings",
        "speedUnit": "x"
      },
      "await": {
        "errorMessage": "The data could not be loaded.",
        "loaderLabel": "Loading ...",
        "retryButtonLabel": "Retry"
      },
      "confirm": {
        "cancelLabel": "Cancel",
        "okLabel": "OK"
      },
      "copyrightList": {
        "item": {
          "detailLabel": "© {copyright}",
          "typeLabel": "{type, select, audios {Audio} images {Image} videos {Video} interactiveImages {Interactive image} other {Material} }",
          "typeWithIndexLabel": "{type, select, audios {Audio} images {Image} videos {Video} interactiveImages {Interactive image} other {Material} } in chapter {index}"
        }
      },
      "copyrightPopup": {
        "headingLabel": "Copyright"
      },
      "copyrightPopupButton": {
        "label": "Copyright"
      },
      "datepicker": {
        "months": "January,February,March,April,May,June,July,August,September,October,November,December",
        "nextMonth": "Next month",
        "previousMonth": "Previous month",
        "weekdays": "Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday",
        "weekdaysShort": "Sun,Mon,Tue,Wed,Thu,Fri,Sat"
      },
      "dialog": {
        "header": {
          "closeButtonLabel": "Close"
        }
      },
      "dismissibleTooltip": {
        "dismissButtonLabel": "Dismiss"
      },
      "humanSize": {
        "units": {
          "byte": "{size, plural, one {Byte} other {Bytes} }",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "tb": "TB"
        }
      },
      "image": {
        "errorMessage": "The image could not be loaded."
      },
      "imageViewer": {
        "overlay": {
          "closeButtonLabel": "Close",
          "nextButtonLabel": "Next image",
          "previousButtonLabel": "Previous image"
        }
      },
      "input": {
        "clearButtonLabel": "Clear"
      },
      "interactiveImage": {
        "close": "Close",
        "errorMessage": "The image could not be loaded.",
        "fullscreen": "Fullscreen",
        "legendsMenuButtonLabel": "Legends",
        "menu": {
          "copyrightHeadingLabel": "Copyright",
          "legendsHeadingLabel": "Color key"
        },
        "nextStepButtonLabel": "Next step",
        "previousStepButtonLabel": "Previous step",
        "showMarkerTip": "Click on the marker",
        "showStepTip": "Proceed to the next step",
        "showZoomTip": "Zoom the image",
        "stepProgressLabel": "Step {step} of {totalSteps}",
        "zoomInButtonLabel": "Zoom in",
        "zoomOutButtonLabel": "Zoom out"
      },
      "pagination": {
        "firstLabel": "First",
        "lastLabel": "Last",
        "nextLabel": "Next",
        "page": "Page",
        "prevLabel": "Previous",
        "totalLabel": "out of {count}"
      },
      "select": {
        "label": "{count} options selected"
      },
      "snackbar": {
        "snack": {
          "dismissButtonLabel": "Dismiss"
        }
      },
      "tag": {
        "addNewButtonLabel": "Add new",
        "emptyMessage": "Add tags",
        "removeButtonLabel": "Remove"
      },
      "upload": {
        "multipleLabel": "Upload {format, select, image {images} video {videos} audio {audios} subtitle {subtitles} other {files} } … ",
        "singleLabel": "Upload {format, select, image {image} video {video} audio {audio} subtitle {subtitle} other {file} } … "
      },
      "uploadProgressModal": {
        "abortButtonLabel": "Abort",
        "message": "{uploadedSize} of {totalSize}",
        "title": "Uploading ..."
      },
      "video": {
        "subtitleLabel": {
          "en": "English",
          "sv": "Swedish"
        }
      },
      "vrImage": {
        "button": "View 360",
        "loading": "Loading ..."
      }
    },
    "controllers": {
      "application": {
        "navigationText": "The page navigation is complete. You may now navigate the page content as you wish.",
        "skipText": "Skip to main content"
      },
      "master": {
        "courses": {
          "show": {
            "scopeMenuButtonItems": {
              "all": "All",
              "ended": "Ended",
              "upcoming": "Upcoming",
              "visible": "Visible"
            },
            "sortMenuButtonItems": {
              "createdAsc": "Oldest",
              "createdDesc": "Latest",
              "titleAsc": "Alphabetical (a-z)",
              "titleDesc": "Alphabetical (z-a)"
            }
          }
        }
      }
    },
    "detroit": {
      "canvasPresentor": {
        "pickColor": "Pick color",
        "pickPresetColor": "Customized",
        "presetColors": "Color",
        "removeObject": "Remove object",
        "restartCanvas": "Reset board",
        "restartConfirm": "Do you wish to restart?",
        "saved": "Saved",
        "saving": "Saving"
      },
      "confirm": {
        "cancelLabel": "Cancel",
        "okLabel": "OK"
      }
    },
    "helpers": {
      "fileTypeItems": {
        "audio": "Audio",
        "blank": "All",
        "document": "Document",
        "image": "Image",
        "video": "Video"
      },
      "languageItems": {
        "deDE": "German",
        "enGB": "English",
        "esES": "Spanish",
        "frFR": "French",
        "itIT": "Italian",
        "svSE": "Swedish"
      },
      "wordlistTypeItems": {
        "concepts": "Terms",
        "glossaries": "Vocabulary"
      }
    },
    "models": {
      "answer": {
        "calendar": {
          "lastWeek": "Last week",
          "today": "Today",
          "yesterday": "Yesterday"
        },
        "unknownDate": "Unknown date"
      },
      "entity": {
        "fallbackTitles": {
          "archives": "Archive",
          "areas": "Area",
          "assignments": "Assignment",
          "books": "Book",
          "books_external": "Book",
          "collections": "Collection",
          "contents": "Content",
          "exercises": "Exercise",
          "folders": "Folder",
          "glossaries": "Word list",
          "interactives": "Assignments",
          "materials": "Material",
          "missingType": "Content",
          "root": "Root",
          "sections": "Section",
          "studies": "Self study"
        },
        "level": "Level {level}",
        "typeLabel": {
          "archives": "Archive",
          "areas": "Area",
          "assignments": "Assignment",
          "books": "Book",
          "collections": "Collection",
          "contents": "Content",
          "exercises": "Exercise",
          "folders": "Folder",
          "glossaries": "Word list",
          "interactives": "Interactive",
          "sections": "Section",
          "studies": "Self study"
        }
      },
      "file": {
        "titleWithFallback": "Untitled file",
        "validations": {
          "name": {
            "presence": "You must enter a name."
          },
          "url": {
            "presence": "You must enter a link.",
            "valid": "The text is not a valid address."
          }
        }
      },
      "material": {
        "typeLabel": {
          "articles": "Article",
          "attachments": "Attachment",
          "audios": "Audio",
          "embeds": "Embed",
          "guidances": "Teacher's Manual",
          "images": "Image",
          "interactiveImages": "Interactive image",
          "links": "External Link",
          "videos": "Video",
          "vrImages": "360 Image",
          "words": "Word"
        }
      },
      "mission": {
        "validations": {
          "title": {
            "presence": "You must enter a title"
          }
        }
      },
      "note": {
        "nameWithFallback": "Untitled note"
      },
      "teacherAssignment": {
        "titleWithFallback": "Untitled assignment"
      },
      "user": {
        "nameMissing": "Name missing"
      },
      "validations": {
        "presence": "The field cannot be empty."
      },
      "wordlist": {
        "nameWithFallback": "Untitled wordlist"
      }
    },
    "routes": {
      "404": {
        "content": "The link you are trying to reach does not exist. This may be because the page was deleted or you followed a link that was incorrect.",
        "cta": "Go to the start page",
        "heading": "The page you are looking for does not exist",
        "notFound": "Page not found"
      },
      "master": {
        "about": "Information",
        "contents": {
          "assignments": {
            "close": "Close",
            "contentWorkspaceTitle": "Assignments",
            "index": {
              "importButtonLabel": "From my archive",
              "newLinkLabel": "New assignment"
            }
          },
          "categoryIsHidden": "category is hidden.",
          "everythingIsHidden": "All content is hidden.",
          "notes": {
            "contentWorkspaceTitle": "Notes",
            "index": {
              "currentNoResultAlertMessage": "You have no current notes for this content.",
              "importButtonLabel": "From my archive",
              "newLinkLabel": "New note",
              "relatedAccordionHeaderLabel": "Related notes"
            }
          },
          "wordlists": {
            "contentWorkspaceTitle": "Wordlists",
            "index": {
              "currentNoResultAlertMessage": "You have no current wordlists for this content.",
              "importButtonLabel": "From my archive",
              "newLinkLabel": "New wordlist",
              "relatedAccordionHeaderLabel": "Related wordlists"
            }
          }
        },
        "courses": {
          "show": {
            "audit": {
              "comments": {
                "pageTitle": "Comments"
              },
              "inbox": {
                "inboxNote": "* Note that this view only shows submissions from our teacher-assessed assignments and not diagnoses, self-corrected exercises and self-study questions.",
                "pageTitle": "New"
              },
              "index": {
                "comments": {
                  "buttonText": "Review comments",
                  "description": "Here you can see all comments on exercises and assignments in chronological order. You can choose to see comments from the entire group of students in the course or for each student individually.",
                  "title": "Comments to exercises"
                },
                "inbox": {
                  "buttonText": "Review new",
                  "description": "Here you can see all new answers that have been submitted for review. The list is updated when new answers are submitted and when you review. You can choose to review the entire group of students in the course or each student individually.",
                  "title": "Inbox for assignments"
                },
                "log": {
                  "buttonText": "Review student",
                  "description": "Here you can see what an individual student in the course has worked with. The student's activity and results are displayed in chronological order.",
                  "title": "Follow an individual student"
                },
                "missions": {
                  "auditButton": "Review",
                  "buttonText": "Review mission",
                  "description": "Here you can see the students' answers and results on all exercises and assignments in each mission. You can choose to review the entire group of students or each student individually.",
                  "disabledText": "There are no missions to review",
                  "noAudits": "There are no exercises here.",
                  "title": "Results in missions"
                },
                "notification": "{num, plural, other {{num} new}}",
                "overview": {
                  "buttonText": "Review all",
                  "description": "Here you can see the students' answers and results on all exercises and assignments in the course. The answers are arranged based on the structure of the books. You can choose to review the entire group of students in the course or each student individually.",
                  "title": "All exercises and assignments"
                },
                "pageTitle": "Follow-up"
              },
              "log": {
                "noStudentSelected": "No student is selected.",
                "pageTitle": "Students"
              },
              "missions": {
                "index": {
                  "audit": "Follow-up",
                  "breadcrumb": {
                    "index": "Index",
                    "missions": "Missions"
                  },
                  "deletedUser": "Deleted user",
                  "pageTitle": "To-do"
                },
                "show": {
                  "invalidAlertMessage": "This content cannot be displayed. It belongs to a book that is not included in this course.",
                  "pageTitle": "All",
                  "rejectedAlertMessage": "This part of the book cannot be displayed. This may be because your license has expired or the content has been removed in a revision."
                }
              },
              "overview": {
                "pageTitle": "All",
                "types": {
                  "diagnosis": "Diagnoses",
                  "standard": "Exercises",
                  "teacher_assignments": "Teacher assignments"
                }
              },
              "pageTitle": "Follow-up"
            },
            "books": {
              "booksPage": {
                "booksWarning": "Your course contains {numBooks} books. It can be difficult to get an overview of the material and your students' results with so many. The course will still work, but consider hiding some books inside the course (you can choose to view them again at any time). Another alternative is to divide the books into several courses, for example one course per subject.",
                "heading": "Content"
              },
              "noEntities": "The course has no books. Go to Administer course and select books for the course.",
              "noEntitiesStudent": "The course has no books.",
              "pageTitle": "Content",
              "showBooksForStudentsButton": "Show books on the overview"
            },
            "index": {
              "addNewMissionButtonLabel": "Add mission",
              "booksHeading": "Content",
              "createMissionButtonLabel": "Create mission",
              "hideBooksButton": "Hide books on the overview",
              "missionIntroCardHeadingLabel": "{isTeacher, select, true {Hand out missions} false {Your teacher has not handed out anything yet} }",
              "missionIntroCardParagraphMessage": "{isTeacher, select, true {With missions, you can distribute content to your course participants. In a mission, you can hand out one or more contents from the products' material, but also your own material such as files or links. The content can then be customized and you can easily follow up on what your students have been working on.} false {The missions that your teacher has handed out in the course are shown here. Right now there are no assigned missions.} }",
              "missionsHeadingLabel": "Missions",
              "pageTitle": "Overview",
              "readMoreAboutCoursesButton": "Read more about how to use courses",
              "saveSortOrder": "Done",
              "shortcutsHeadingLabel": "Shortcuts",
              "sort": "Sort",
              "welcomeContent": "<p class=\"paragraph\">This is the course overview page that you share with your course participants. Here you can build up your own planning and distribute content.</p><p class=\"paragraph text-bold\">In the course menu you will find your tools:</p><ul class=\"paragraph unordered-list\"><li class=\"mb1\">The course books in their entirety are available under Content. You can choose whether they should appear on the overview or not.</li><li class=\"my1\">With the help of Missions, you can activate your course participants by handing out content interspersed with your own material.</li><li class=\"my1\">You can create Shortcuts to content, external pages or attachments.</li><li class=\"my1\">You can see and comment on students' responses to assignments under Follow-up.</li><li class=\"mt1\">Via Settings, you can make additional settings for the course.</li></ul>",
              "welcomeContinueButton": "Continue",
              "welcomeHeading": "Welcome to your new course: {name}"
            },
            "missions": {
              "addNewButtonLabel": "Add",
              "headingLabel": "Plan missions",
              "noResultMessage": "{scope, select, all {No missions have been created yet.} visible {No visible missions were found.} upcoming {No upcoming missions were found.} ended {No ended missions were found.} other {No missions were found.} }",
              "pageTitle": "Plan missions",
              "scopeLabel": "Show:",
              "scopeMenuButtonItems": {
                "all": "All",
                "ended": "Ended",
                "upcoming": "Upcoming",
                "visible": "Visible"
              },
              "sortLabel": "Sort:",
              "sortMenuButtonItems": {
                "createdAsc": "Oldest",
                "createdDesc": "Latest",
                "titleAsc": "Alphabetical (a-z)",
                "titleDesc": "Alphabetical (z-a)"
              }
            },
            "myResults": {
              "title": "My activity"
            },
            "settings": {
              "title": "Settings"
            }
          }
        },
        "forbidden": {
          "content": "After a bit of digging, we found that your account does not have a license to the content that the link leads to. This means it cannot be displayed to you.",
          "cta": "Go to the start page",
          "heading": "License missing"
        },
        "guidance": "Teacher guidance",
        "interactives": {
          "diagnosis": {
            "done": {
              "audit": {
                "attemptCompleted": "Completed attempt",
                "goBack": "Back"
              }
            },
            "show": {
              "end": "End diagnosis"
            }
          }
        },
        "missionCustomizer": "Customize content",
        "products": {
          "index": {
            "pageTitle": "Overview"
          },
          "myResults": {
            "title": "My activity"
          }
        }
      }
    },
    "services": {
      "contextHelper": {
        "pageTitle": {
          "error": "Error",
          "homescreen": "Start",
          "notFound": "Page not found"
        }
      },
      "customContentDispatcher": {
        "delete": {
          "confirm": "Are you sure you want to delete this content?",
          "snackbar": {
            "error": "The content could not be deleted. Please try again or contact support if the problem persists.",
            "success": "The content was deleted."
          }
        },
        "save": {
          "snackbar": {
            "error": "The content could not be saved. Please try again or contact support if the problem persists.",
            "success": "The content was saved."
          }
        }
      },
      "diagnosis": {
        "confirmTurnInMessage": "This ends the diagnosis and submits the result to your teacher.",
        "confirmTurnInTitle": "Do you want to end the diagnosis?"
      },
      "fileDispatcher": {
        "delete": {
          "confirm": "Are you sure you want to delete this file?",
          "snackbar": {
            "error": "The file could not be deleted. Please try again or contact support if the problem persists.",
            "success": "The file was deleted."
          }
        },
        "deleteMany": {
          "confirm": "{count, plural, one { Are you sure you want to delete this file?} other {Are you sure you want to delete these files?} } ",
          "snackbar": {
            "error": "{count, plural, one {The file could not be deleted.} other {The files could not be deleted.} } Please try again or contact support if the problem persists.. ",
            "success": "{count, plural, one {The file was deleted.} other {The files were deleted.} } "
          }
        },
        "save": {
          "snackbar": {
            "error": "The file could not be saved. Please try again or contact support if the problem persists.",
            "success": "The file was saved."
          }
        },
        "upload": {
          "snackbar": {
            "error": "The file could not be uploaded. Please try again or contact support if the problem persists.",
            "success": "The file was uploaded."
          }
        }
      },
      "licenseDispatcher": {
        "connect": {
          "snackbar": {
            "error": "The license could not be activated. Please try again or contact support if the problem persists.",
            "success": "The license was activated."
          }
        },
        "tryProduct": {
          "snackbar": {
            "error": "The trial could not be activated. Please try again or contact support if the problem persists.",
            "success": "The trial was activated."
          }
        }
      },
      "missionDispatcher": {
        "activateMany": {
          "snackbar": {
            "error": "{count, plural, one {The mission could not be added.} other {The missions could not be added.} } Please try again or contact support if the problem persists.",
            "success": "{count, plural, one {The mission has been added.} other {The missions have been added.} }"
          }
        },
        "cancelSchedule": {
          "snackbar": {
            "error": "The mission could not be saved. Please try again or contact support if the problem persists.",
            "success": "{wasOngoing, select, true {The mission's scheduling has been canceled, and the mission is hidden on the overview.} false {The mission's scheduling has been canceled.} }"
          }
        },
        "copyManyToCourse": {
          "snackbar": {
            "error": "{count, plural, one {The mission could not be copied.} other {The missions could not be copied.} } Please try again or contact support if the problem persists.",
            "success": "{count, plural, one {The mission has been copied.} other {The missions have been copied.} }"
          }
        },
        "copyToManyCourses": {
          "snackbar": {
            "error": "The mission could not be copied. Please try again or contact support if the problem persists.",
            "success": "The mission has been copied."
          }
        },
        "delete": {
          "confirm": "Are you sure you want to remove this mission?",
          "snackbar": {
            "error": "The mission could not be saved. Please try again or contact support if the problem persists.",
            "success": "The mission was removed."
          }
        },
        "hide": {
          "snackbar": {
            "error": "The mission could not be saved. Please try again or contact support if the problem persists.",
            "success": "{fromOverview, select, true {{wasOngoing, select, true {The mission's scheduling has been canceled, and the mission is hidden on the overview. To edit the mission again, go to the Plan Missions tab.} false {The mission has been hidden on the overview. To edit the mission again, go to the Plan Missions tab.} }} false {{wasOngoing, select, true {The mission's scheduling has been canceled, and the mission is hidden on the overview.} false {The mission has been hidden on the overview.} }} }"
          }
        },
        "save": {
          "snackbar": {
            "error": "The mission could not be saved. Please try again or contact support if the problem persists.",
            "success": "{fromOverview, select, true {{state, select, idle {The mission has been saved. Since it is hidden, it does not appear on the overview. To edit the mission again, go to the Plan Missions tab.} scheduled {The mission has been saved. Since it is scheduled, it does not appear on the overview yet. To edit the mission again, go to the Plan Missions tab.} other {The mission has been saved.} }} false {The mission has been saved.} }"
          }
        },
        "schedule": {
          "snackbar": {
            "error": "The mission could not be saved. Please try again or contact support if the problem persists.",
            "success": "{isOngoing, select, true {The mission has been scheduled and is now shown to students on the overview.} false {The mission has been scheduled.} }"
          }
        },
        "toggleActivation": {
          "snackbar": {
            "error": "The mission could not be saved. Please try again or contact support if the problem persists.",
            "success": "{visible, select, true {The mission is shown on the overview.} false {The mission is hidden on the overview.} }"
          }
        }
      },
      "noteDispatcher": {
        "delete": {
          "confirm": "Are you sure you want to delete this note?",
          "snackbar": {
            "error": "The note could not be deleted. Please try again or contact support if the problem persists.",
            "success": "The note was deleted."
          }
        },
        "deleteMany": {
          "confirm": "{count, plural, one { Are you sure you want to delete this note?} other {Are you sure you want to delete these notes?} } ",
          "snackbar": {
            "error": "{count, plural, one {The note could not be deleted.} other {The notes could not be deleted.} } Please try again or contact support if the problem persists.. ",
            "success": "{count, plural, one {The note was deleted.} other {The notes were deleted.} } "
          }
        },
        "link": {
          "snackbar": {
            "error": "The note could not be linked to the content. Please try again or contact support if the problem persists.",
            "success": "The note was linked to the content."
          }
        },
        "linkMany": {
          "snackbar": {
            "error": "{count, plural, one {The note could not be linked to the content.} other {The notes could not be linked to the content.} } Please try again or contact support if the problem persists. ",
            "success": "{count, plural, one {The note was linked to the content.} other {The notes were linked to the content.} } "
          }
        },
        "save": {
          "snackbar": {
            "error": "The note could not be saved. Please try again or contact support if the problem persists.",
            "success": "The note was saved."
          }
        },
        "unlink": {
          "confirm": "Are you sure you want to unlink this note from the content?",
          "snackbar": {
            "error": "The note could not be unlinked from the content. Please try again or contact support if the problem persists.",
            "success": "The note was unlinked from the content."
          }
        }
      },
      "teacherAssignmentDispatcher": {
        "delete": {
          "confirm": "Are you sure you want to delete this assignment? If your students have answered the assignment, their answers will be deleted.",
          "snackbar": {
            "error": "The assignment could not be deleted. Please try again or contact support if the problem persists.",
            "success": "The assignment was deleted."
          }
        },
        "deleteMany": {
          "confirm": "{count, plural, one {Are you sure you want to delete this assignment? If your students have answered the assignment, their answers will be deleted.} other {Are you sure you want to delete these assignments? If your students have answered any of the assignment, their answers will be deleted.} } ",
          "snackbar": {
            "error": "{count, plural, one {The assignment could not be deleted.} other {The assignments could not be deleted} } Please try again or contact support if the problem persists. ",
            "success": "{count, plural, one {The assignment was deleted.} other {The assignments were deleted.} } "
          }
        },
        "link": {
          "snackbar": {
            "error": "The assignment could not be linked to the content. Please try again or contact support if the problem persists.",
            "success": "The assignment was linked to the content."
          }
        },
        "linkMany": {
          "snackbar": {
            "error": "{count, plural, one {The assignment could not be linked to the content.} other {The assignments could not be linked to the content.} } Please try again or contact support if the problem persists. ",
            "success": "{count, plural, one {The assignment was linked to the content.} other {The assignments were linked to the content.} } "
          }
        },
        "save": {
          "snackbar": {
            "error": "The assignment could not be saved. Please try again or contact support if the problem persists.",
            "success": "The assignment was saved."
          }
        },
        "unlink": {
          "confirm": "Are you sure you want to unlink this assignment from the content?",
          "snackbar": {
            "error": "The assignment could not be unlinked from the content. Please try again or contact support if the problem persists.",
            "success": "The assignment was unlinked from the content."
          }
        }
      },
      "wordDispatcher": {
        "delete": {
          "confirm": "Are you sure you want to delete this word?",
          "snackbar": {
            "error": "The word could not be deleted. Please try again or contact support if the problem persists.",
            "success": "The word was deleted."
          }
        },
        "save": {
          "snackbar": {
            "error": "The word could not be saved. Please try again or contact support if the problem persists.",
            "success": "The word was saved."
          }
        }
      },
      "wordlistDispatcher": {
        "delete": {
          "confirm": "Are you sure you want to delete this wordlist?",
          "snackbar": {
            "error": "The wordlist could not be deleted. Please try again or contact support if the problem persists.",
            "success": "The wordlist was deleted."
          }
        },
        "deleteMany": {
          "confirm": "{count, plural, one {Are you sure you want to delete this wordlist?} other {Are you sure you want to delete these wordlists?} } ",
          "snackbar": {
            "error": "{count, plural, one {The wordlist could not be deleted.} other {The wordlists could not be deleted.} } Please try again or contact support if the problem persists. ",
            "success": "{count, plural, one {The wordlist was deleted.} other {The wordlists were deleted.} } "
          }
        },
        "duplicate": {
          "snackbar": {
            "error": "The wordlist could not be copied. Please try again or contact support if the problem persists.",
            "success": "The wordlist was copied."
          }
        },
        "link": {
          "snackbar": {
            "error": "The wordlist could not be linked to the content. Please try again or contact support if the problem persists.",
            "success": "The wordlist was linked to the content."
          }
        },
        "linkMany": {
          "snackbar": {
            "error": "{count, plural, one {The wordlist could not be linked to the content.} other {The wordlists could not be linked to the content.} } Please try again or contact support if the problem persists. ",
            "success": "{count, plural, one {The wordlist was linked to the content.} other {The wordlists were linked to the content.} } "
          }
        },
        "save": {
          "snackbar": {
            "error": "The wordlist could not be saved. Please try again or contact support if the problem persists.",
            "success": "The wordlist was saved."
          }
        },
        "unlink": {
          "confirm": "Are you sure you want to unlink this wordlist from the content?",
          "snackbar": {
            "error": "The wordlist could not be unlinked from the content. Please try again or contact support if the problem persists.",
            "success": "The wordlist was unlinked from the content."
          }
        }
      }
    },
    "utils": {
      "generateFallbackTitle": {
        "archives": "Archive",
        "areas": "Area",
        "assignments": "Assignment",
        "books": "Book",
        "books_external": "Book",
        "collections": "Collection",
        "contents": "Content",
        "exercises": "Exercise",
        "folders": "Folder",
        "glossaries": "Word list",
        "interactives": "Assignments",
        "materials": "Material",
        "missingType": "Content",
        "root": "Root",
        "sections": "Section",
        "studies": "Self study"
      },
      "licenseInfo": {
        "expired": "Expired {date}",
        "expires": "Expires {date}",
        "externalExpired": "External license expired {date}",
        "externalExpires": "External license expires {date}",
        "freeExpires": "Demo license expires {date}",
        "freeUsed": "Demo used",
        "missingLicense": "Missing license",
        "personalExpired": "Personal license expired {date}",
        "personalExpires": "Personal license expires {date}",
        "personalValidThru": "Personal license valid thru {date}",
        "ready": "Ready to be started",
        "started": "Started",
        "validThru": "Valid thru {date}"
      }
    }
  }], ["sv", {
    "components": {
      "additionCollator": {
        "filterSelectItems": {
          "all": "Allt"
        },
        "filterSelectLabel": "Filtrera",
        "searchButtonLabel": "Sök",
        "searchInputLabel": "Sök",
        "searchInputPlaceholder": "Sök ..."
      },
      "additionCustomizer": {
        "group": {
          "section": {
            "toggleButtonLabel": "{isExpanded, select, true {Stäng} false {Öppna} }",
            "typeLabel": "{type, select, teacherAssignments {Uppgifter} wordlists {Ordlistor} other {Tillägg} }"
          }
        }
      },
      "answerComments": {
        "calendar": {
          "lastWeekAtTime": "I {dayName}s kl. {time}",
          "lastWeekAtTimeLower": "i {dayName}s kl. {time}",
          "todayAtTime": "Idag kl. {time}",
          "todayAtTimeLower": "idag kl. {time}",
          "yesterdayAtTime": "Igår kl. {time}",
          "yesterdayAtTimeLower": "igår kl. {time}"
        },
        "commentButton": "Kommentera",
        "commentPlaceholder": "Skriv en kommentar",
        "events": {
          "heading": "Historik",
          "rejectedUserCommented": "<em>Borttagen användare</em> skrev",
          "rejectedUserCorrect": "<em>Borttagen användare</em> fick rätt på version {version}",
          "rejectedUserStartedVersion": "<em>Borttagen användare</em> påbörjade en ny version",
          "rejectedUserSubmitted": "<em>Borttagen användare</em> lämnade in version {version}",
          "rejectedUserWrong": "<em>Borttagen användare</em> fick fel på version {version}",
          "someoneCommented": "{name} skrev",
          "someoneCorrect": "{name} fick rätt på version {version}",
          "someoneStartedVersion": "{name} påbörjade en ny version",
          "someoneSubmitted": "{name} lämnade in version {version}",
          "someoneWrong": "{name} fick fel på version {version}",
          "youCommented": "Du skrev",
          "youCorrect": "Du fick rätt på version {version}",
          "youNoted": "Du noterade",
          "youStartedVersion": "Du påbörjade en ny version",
          "youSubmitted": "Du lämnade in version {version}",
          "youWrong": "Du fick fel på version {version}"
        },
        "noComments": "Inga kommentarer",
        "noteButton": "Notera",
        "notePlaceholder": "Skriv en notering",
        "notesDisclaimer": "Det är endast du som kan se dina noteringar",
        "remove": "Ta bort",
        "tabs": {
          "comment": "Kommentera",
          "note": "Egen notering"
        }
      },
      "answerLog": {
        "commentFilter": {
          "all": "Visa alla",
          "commented": "Visa kommenterade"
        },
        "diagnosis": {
          "aborted": "Försöket avbröts",
          "active": "Försöket är aktivt",
          "attempt": "Försök {attempt}",
          "noResults": "Inga resultat"
        },
        "entityFilter": {
          "allOption": "Alla",
          "areaLabel": "Arbetsområde",
          "areaPlaceholder": "Välj innehåll först",
          "assignmentSearchLabel": "Sök",
          "assignmentSearchPlaceholder": "Sök uppgift",
          "bookLabel": "Innehåll",
          "contentLabel": "Innehåll",
          "contentPlaceholder": "Välj arbetsområde först"
        },
        "files": {
          "filterOptions": {
            "all": "Alla"
          }
        },
        "filterShowing": "Visar:",
        "filterString": "{numStatuses, plural, =0 {Inga statusar} =1 {{lastStatus}} =7 {Alla statusar} other {{otherStatuses} och {lastStatus}}} {commentStatus, select, commented {med kommentarer} other {}} i {selectedContent, select, null {allt material} other {{selectedContent}}} {assignmentSearch, select, null {} other {där uppgiftens titel innehåller {assignmentSearch}}}",
        "markAllAsRead": "Markera alla i urvalet som granskade",
        "results": {
          "entityFailed": "Svaret kan inte visas eftersom du inte längre har tillgång till uppgiften.",
          "entityMissing": "Svaret kan inte visas eftersom uppgiften inte längre är tillgänglig.",
          "numStudentsAnswered": "{num} svar",
          "types": {
            "diagnosis": "Diagnoser",
            "standard": "Uppgifter",
            "teacher_assignments": "Läraruppgifter"
          }
        },
        "standard": {
          "allAssignments": "Alla uppgifter",
          "autoCorrected": "Autorättade",
          "filters": {
            "audited": "Granskade",
            "correct": "Rätt svar",
            "inbox": "Inlämnade*",
            "started": "Påbörjade",
            "teacherCorrect": "Mål uppnått",
            "teacherWrong": "Mål ej uppnått",
            "turnedIn": "Inlämnade",
            "wrong": "Fel svar"
          },
          "hideFilters": "Dölj filter",
          "noResults": "Inga resultat",
          "showFilters": "Visa filter",
          "teacherCorrected": "Lärarrättade"
        },
        "teacherAssignment": {
          "noResults": "Inga resultat"
        },
        "unknownDate": "Okänt datum"
      },
      "answerStatusIcon": {
        "exercise": {
          "status": {
            "audited": "Granskad",
            "correct": "Rätt",
            "done": "Besvarad",
            "editing": "Redigerar …",
            "incomplete": "Svar saknas",
            "notStarted": "Inte påbörjad",
            "saving": "Sparar …",
            "started": "Påbörjad",
            "teacherCorrect": "Mål uppnått",
            "teacherWrong": "Mål ej uppnått",
            "turnedIn": "Inlämnad",
            "wrong": "Fel"
          }
        }
      },
      "archiveForm": {
        "cancelLinkOrButtonLabel": "Avbryt",
        "deleteButtonLabel": "Ta bort",
        "saveButtonLabel": "Spara"
      },
      "archiveView": {
        "allCategories": "Alla kategorier",
        "category": "Kategori",
        "orderBy": "Sortering",
        "random": "Slumpa",
        "search": {
          "noResultsOn": "Din sökning gav inga resultat. Om du vill söka i allt innehåll kan du använda den allmänna sökfunktionen.",
          "placeholder": "Sök i titlarna på sidan …",
          "relatedSearchResults": "Relaterade sökresultat",
          "searchResults": "Sökresultat",
          "searching": "Söker …",
          "showingResults": "Visar {results} av {total} resultat."
        },
        "showAllInFolder": "Visa alla i {folder}",
        "sort": {
          "date_asc": "Senaste först",
          "date_desc": "Äldsta först",
          "inverse": "Omvänd",
          "name_asc": "Titel A-Ö",
          "name_desc": "Titel Ö-A",
          "random": "Slumpa",
          "standard": "Standard"
        }
      },
      "areaView": {
        "allCategories": "Alla kategorier",
        "category": "Kategori",
        "orderBy": "Sortering",
        "random": "Slumpa",
        "search": {
          "label": "Sökresultat",
          "noResultsOn": "Din sökning gav inga resultat. Om du vill söka i allt innehåll kan du använda den allmänna sökfunktionen.",
          "placeholder": "Sök i titlarna på sidan …",
          "results": "Visar {hits} resultat.",
          "searching": "Söker …"
        },
        "sort": {
          "name_asc": "Titel A-Ö",
          "name_desc": "Titel Ö-A",
          "standard": "Standard"
        },
        "view": {
          "repeat": "Repetera"
        }
      },
      "attachLinkModal": {
        "actions": {
          "attach": "Bifoga",
          "cancel": "Avbryt"
        },
        "link": {
          "label": "Länk",
          "placeholder": "Klistra in din länk här"
        },
        "modalHeader": "Bifoga länk",
        "name": {
          "label": "Namn",
          "placeholder": "Ange ett namn"
        },
        "saveFailed": "Länken kunde inte bifogas"
      },
      "attachmentPreview": {
        "item": {
          "removeButtonLabel": "Ta bort"
        }
      },
      "availableProducts": {
        "assignedLicenses": "{is_assigned} av {total} licenser",
        "externalLicenses": "{total} externa licenser",
        "extraMaterials": "Extramaterial",
        "licenseTime": "Licenser är giltiga i {licenseTime} efter start",
        "licenseTimeUntil": "Licenser är giltiga till {licenseTime}",
        "manageLicenses": "Hantera licenser",
        "noProducts": "Hittade inga produkter",
        "showLicenses": "Visa licenser",
        "unlimitedLicenses": "Obegränsade licenser"
      },
      "bookBrowser": {
        "checkboxLabel": "Visa för eleverna"
      },
      "carouselResult": {
        "close": "Stäng",
        "completed": "Bra jobbat, du har alla rätt!",
        "redo": "Gör om",
        "result": "{numCorrect} av {numTotal} rätt",
        "tryAgain": "Försök igen",
        "tryAgainInfo": "{numCorrect} av {numTotal} rätt totalt"
      },
      "chapterNavigation": {
        "ofIndex": "av",
        "tableOfContents": "Innehållsförteckning"
      },
      "chooseModal": {
        "cancelButtonLabel": "Avbryt",
        "chooseButtonLabel": "{count, plural, =0 {Välj} one {Välj} other {Välj ({count})} }",
        "deleteButtonLabel": "{count, plural, =0 {Radera} one {Radera} other {Radera ({count})} }"
      },
      "collectionAudio": {
        "audioToolTip": "Tryck här för att lyssna"
      },
      "collectionExerciseIndex": {
        "item": {
          "draftBadgeLabel": "Utkast",
          "numAnswers": "Antal svar"
        }
      },
      "collectionList": {
        "assignments": "Uppgifter"
      },
      "collectionListItem": {
        "carousel": {
          "continue": "Fortsätt",
          "result": "{score} av {scoreMax} rätt"
        },
        "diagnosis": {
          "continue": "Fortsätt",
          "result": "{score} av {scoreMax} rätt"
        },
        "diagnosisInfo": "{count, plural, one {# uppgift} other {# uppgifter}}, {time, plural, one {# minut} other {# minuter}}, {attempts, plural, one {# försök kvar} other {# försök kvar}}",
        "numExercises": "{count, plural, one {# uppgift} other {# uppgifter}}"
      },
      "collectionModal": {
        "assignments": "Uppgifter"
      },
      "collectionOptions": {
        "clear": "Rensa alla svar",
        "preview": "Förhandsgranska",
        "reset": "Detta rensar alla dina svar på uppgifterna i samlingen <b>{collection}</b>. Vill du fortsätta?",
        "share": "Dela",
        "showKey": "Visa facit"
      },
      "collectionSummary": {
        "close": "Stäng",
        "title": "Sammanfattning"
      },
      "collectionTopbar": {
        "pagination": {
          "firstPage": "Första sidan",
          "lastPage": "Sista sidan",
          "nextPage": "Nästa sida",
          "prevPage": "Föregående sida"
        },
        "step": "{number} av {total}"
      },
      "collectionView": {
        "top": {
          "practice": "Uppgifter"
        }
      },
      "contentChapter": {
        "contentIsHidden": "Det finns inget innehåll här."
      },
      "contentCover": {
        "list": {
          "collectionSpaceTitle": "Uppgifter",
          "noSectionsWarning": "Det finns inget innehåll här.",
          "sectionSpaceTitle": "Avsnitt",
          "wordlistSpaceTitle": "Ordlistor"
        },
        "noSectionsMessage": "Inget innehåll",
        "start": "Starta"
      },
      "contentMeta": {
        "edition": "Version",
        "editionTooltip": "Textversion",
        "levelTooltip": "Svårighetsgrad",
        "typeTooltip": "Typ av innehåll",
        "types": {
          "archives": "Arkiv",
          "areas": "Arbetsområde",
          "assignments": "Arbetsuppgift",
          "books": "Bok",
          "collections": "Uppgiftsgrupp",
          "contents": "Innehåll",
          "exercises": "Övning",
          "folders": "Mapp",
          "glossaries": "Ordlista",
          "interactives": "Uppgifter",
          "sections": "Avsnitt",
          "studies": "Instudering"
        }
      },
      "contentMissionNext": {
        "nextContentLabel": "Nästa innehåll",
        "proceedButton": "Gå vidare"
      },
      "contentPagination": {
        "back": "Backa",
        "done": "Klar",
        "nextPage": "Nästa sida"
      },
      "contentPlaysettings": {
        "alwaysSpeechSynthesis": "Alltid talsyntes",
        "behavior": "Beteende",
        "marking": "Markering för talsyntes",
        "markings": {
          "none": "Ingen",
          "onlySentences": "Enbart meningar",
          "onlyWords": "Enbart ord",
          "wordsAndSentences": "Ord och meningar"
        },
        "showSpeakerInfo": "Visa instruktioner",
        "speechSpeed": {
          "fast": "Snabb",
          "slow": "Långsam",
          "standard": "Förval",
          "verySlow": "Mycket långsam"
        },
        "speechSpeedDisabled": "Inställningen fungerar inte för inläst tal på din enhet, men du kan fortfarande ställa in hastighet för talsyntes.",
        "speed": "Rösthastighet",
        "stickWithText": "Textmedföljning",
        "voice": "Röst för talsyntes"
      },
      "contentSpeakerInfoModal": {
        "click": "Starta eller stäng av uppläsningsfunktionen genom att trycka på <b>Lyssna</b> ovanför texten. Tryck på ett textstycke för att starta eller pausa uppläsningen.",
        "clickHeading": "Du kan lyssna på all text",
        "confirm": "Okej, jag förstår",
        "customize": "Du kan få uppläsningen precis som du vill ha den, till exempel ändra hastighet och röst. Tryck på knappen <b>Anpassa</b> längst upp om du vill göra särskilda inställningar för uppläsningen. Där kan du också hitta den här informationen igen.",
        "customizeHeading": "Inställningar för uppläsning",
        "heading": "Uppläsning av innehåll",
        "keyboard": "Du kan styra uppläsningen med tangentbordet. Gör så här: <ul class=\"nt1\"><li><b>Shift och mellanslag: Start och stopp</b></li><li><b>Shift och pil upp: Förra stycket</b></li><li><b>Shift och pil ned: Nästa stycke</b></li></ul>",
        "keyboardHeading": "Styr med tangenterna"
      },
      "contentSummary": {
        "header": "Du är på sista sidan",
        "ingress": "Det finns mer att jobba med till det här innehållet:"
      },
      "contentTopbar": {
        "exercises": "Uppgifter",
        "notes": "Anteckningar",
        "settings": {
          "customize": "Anpassa",
          "listen": "Lyssna",
          "presentation": "Presentation",
          "reading": "Uppläsning",
          "speakerToggleButtonTooltip": "Uppläsning är inte tillgänglig för det aktuella språket.",
          "textVersions": "Textversioner"
        },
        "wordlists": "Ordlistor"
      },
      "contentsStory": {
        "hideText": "Dölj text",
        "showText": "Visa text"
      },
      "contextSelect": {
        "selectCourseContext": {
          "heading": "Välj kurs",
          "info": "Det här innehållet ingår i flera kurser som du är med i. Välj en kurs för att fortsätta."
        },
        "selectProductContext": {
          "heading": "Välj produkt",
          "info": "Den här boken ingår i fler än en produkt som du har licens till. Välj produkt för att fortsätta."
        }
      },
      "cookieMessage": {
        "about": "Om cookies och personuppgifter",
        "accept": "Acceptera cookies",
        "info": "Vi använder cookies för att ge dig en bättre upplevelse av vår webbplats och våra digitala produkter.",
        "title": "Sidan använder cookies"
      },
      "countdownTimer": {
        "minutesShort": "min"
      },
      "courseAdaptSettings": {
        "changeImageButtonText": "Byt bild",
        "chooseImageButtonText": "Välj bild",
        "coverImage": "Omslagsbild",
        "hasInternalAndExternalProducts": "Kursen innehåller titlar som inte påverkas av dessa inställningar.",
        "hideDiagnosis": "Lås diagnoser",
        "hideDiagnosisSubLabel": "Lås upp en diagnos genom att dela ut den genom ett uppdrag",
        "hideKey": "Dölj facit & lösningsförslag",
        "hideLabel": "Facit och diagnoser i kursen",
        "language": {
          "en": "English",
          "noSelectedLanguage": "Användarens inställning",
          "sv": "Svenska"
        },
        "languageLabel": "Språk",
        "overviewSortSetting": "Ordning",
        "removeImageButtonText": "Ta bort bild",
        "showProducts": "Visa vilka",
        "sort": {
          "missions": "Uppdrag överst",
          "shortcuts": "Genvägar överst"
        }
      },
      "courseAudit": {
        "audit": "Uppföljning",
        "bottombar": {
          "edit": "Ändra",
          "hideKeyTooltip": "Dölj facit",
          "hideSolutionTooltip": "Dölj lösningsförslag",
          "keyTooltip": "Visa facit",
          "nextStudent": "Nästa elev",
          "solutionTooltip": "Visa lösningsförslag",
          "status": {
            "audited": "Granskad",
            "correct": "Rätt",
            "done": "Besvarad",
            "editing": "Redigerar …",
            "incomplete": "Svar saknas",
            "notStarted": "Inte påbörjad",
            "saving": "Sparar …",
            "started": "Påbörjad",
            "teacherCorrect": "Mål uppnått",
            "teacherWrong": "Mål ej uppnått",
            "turnedIn": "Inlämnad",
            "wrong": "Fel"
          },
          "version": "Version {version}"
        },
        "breadcrumb": {
          "comments": "Kommentarer",
          "inbox": "Nytt",
          "index": "Översikt",
          "log": "Elever",
          "missions": "Uppdrag",
          "overview": "Allt innehåll"
        },
        "collection": {
          "assignments": "Uppgifter",
          "compare": "Jämför",
          "exercise": {
            "noAnswers": "Inget inlämnat svar finns till uppgiften"
          },
          "students": "Elever"
        },
        "comments": {
          "calendar": {
            "lastWeekAtTimeLower": "i {dayName}s kl. {time}",
            "todayAtTimeLower": "idag kl. {time}",
            "yesterdayAtTimeLower": "igår kl. {time}"
          },
          "commentedOn": "{userString} kommenterade {dateString}",
          "noComments": "Hittade inga kommentarer.",
          "you": "Du"
        },
        "diagnosis": {
          "group": {
            "attempts": "{num} försök"
          },
          "noDiagnosis": "Titeln innehåller inga diagnoser.",
          "part": {
            "attempts": "{num} försök",
            "diagnosisResultTypes": {
              "attempt": "Försök {attempt}",
              "best": "Det bästa försöket",
              "first": "Det första försöket",
              "latest": "Det senaste försöket"
            },
            "loadingDiagnosis": "Hämtar försök",
            "noAttempts": "Inga försök"
          }
        },
        "modalTitle": {
          "comments": "Kommentarer",
          "inbox": "Nytt",
          "log": "Elever",
          "overview": "Allt"
        },
        "standard": {
          "entity": {
            "compare": "Jämför",
            "loading": "Hämtar svar",
            "noAnswers": "Inga svar",
            "noAudits": "Det finns inga övningar här.",
            "teacherAssignments": "Läraruppgifter"
          },
          "exercise": {
            "numAnswered": "{count} av {total}"
          }
        },
        "studentList": {
          "numAnswers": "Antal svar"
        },
        "teacherAssignments": {
          "noTeacherAssignments": "Titeln innehåller inga läraruppgifter.",
          "teacherCollection": {
            "compare": "Jämför"
          }
        },
        "versionPopup": {
          "version": "Version {version}",
          "versions": "Versioner"
        }
      },
      "courseMain": {
        "menu": {
          "audit": "Uppföljning",
          "books": "Innehåll",
          "missions": "Planera uppdrag",
          "myResults": "Min aktivitet",
          "overview": "Översikt",
          "settings": "Inställningar"
        },
        "pages": "Kurssidor",
        "restore": "Återställ",
        "restoreInfo": "Kursen är arkiverad och därför inte synlig för medlemmarna.",
        "teacherLicenseAlert": {
          "linkAction": "Administrera kurs",
          "studentMissingLicense": "Det finns användare som saknar licens i kursen.",
          "teacherMissingLicense": "Du saknar licens."
        },
        "userLicenseAlert": {
          "studentMessage": "Du saknar licens. Meddela din lärare."
        }
      },
      "courseMissionActivateModal": {
        "activateButtonLabel": "{count, plural, 0 {Lägg till valda} other {Lägg till valda ({count})} } ",
        "cancelButtonLabel": "Avbryt",
        "title": "Lägg till från befintlig kurs"
      },
      "courseMissionCopyFromModal": {
        "cancelButtonLabel": "Avbryt",
        "chooseCourseButtonLabel": "Välj kurs",
        "copyButtonLabel": "{count, plural, =0 {Kopiera valda} other {Kopiera valda ({count})} } ",
        "instructionsMessage": "Välj kurs att kopiera uppdrag ifrån:",
        "noMissionsSelected": "Du måste välja minst ett uppdrag att kopiera.",
        "title": "Kopiera uppdrag"
      },
      "courseMissionCopyToModal": {
        "cancelButtonLabel": "Avbryt",
        "copyButtonLabel": "Kopiera till ({count})",
        "instructionsMessage": "Välj kurser att kopiera uppdraget till",
        "title": "Kopiera uppdrag",
        "validationErrorMessage": "Du måste välja minst en kurs att kopiera till."
      },
      "courseProductCard": {
        "new": "Ny"
      },
      "courseShortcutActionMenu": {
        "archive": "Mitt arkiv",
        "file": "Egen fil",
        "internalLink": "Produktinnehåll",
        "link": "Länk"
      },
      "courseShortcuts": {
        "addShortcut": "Lägg till genväg",
        "done": "Klar",
        "noShortcuts": "Inga genvägar tillagda",
        "sort": "Ordna"
      },
      "courseUserList": {
        "courseNoUsers": "Kursen har inga elever just nu. Du kan lägga till elever i Administrera kurs.",
        "courseSettings": "Administrera kurs",
        "hasNotLoggedIn": "Har inte loggat in",
        "helpList": {
          "activate": "Starta hjälplistan",
          "active": "Nu syns symbolen för hjälplistan hos dina kursdeltagare. När någon ber om hjälp kommer du att se det här.",
          "deactivate": "Stäng av hjälplistan",
          "description": "I hjälplistan kan du se vems tur det är att få hjälp.",
          "empty": "Ingen har bett om hjälp än",
          "info": "När du startar hjälplistan blir den aktiv i 90 minuter eller tills du stänger av den.",
          "title": "Hjälplista"
        },
        "popover": {
          "licenses": "Licenser"
        },
        "product": {
          "extended": "Kommer förlängas",
          "handleLicenses": "Hantera licenser"
        },
        "students": "Elever",
        "teachers": "Lärare",
        "user": {
          "administerUser": "Administrera elev",
          "administerUserDescription": "Hantera elevinställningar som klass, namn och lösenord, samt se elevens licenser och kurser.",
          "auditComments": "Granska kommentarer",
          "auditExercises": "Granska uppgifter",
          "showLog": "Visa elevlogg"
        }
      },
      "customContentDetailModal": {
        "cancelButtonLabel": "Avbryt",
        "numberOfTriesInputLabel": "Antal försök",
        "numberOfTriesInputPlaceholder": "Obegränsat",
        "saveButtonLabel": "Spara",
        "teacherMaterial": "Lärarmaterial",
        "title": "Anpassa: {entityTitle}"
      },
      "diagnosisDone": {
        "close": "Stäng diagnos",
        "textResult": "Du hade {correct} rätt av {total} möjliga",
        "yourAnswers": "Dina svar"
      },
      "diagnosisIntro": {
        "aborted": "Avbrutet",
        "cancelButton": "Avbryt",
        "diagnosisDisabledInCourse": "Diagnoser är avstängda av din lärare.",
        "info": "Tid: {allowedTime} min, Övningar: {count} st",
        "noAttemptsLeft": "Du har inga försök kvar.",
        "noResult": "Inga tidigare försök",
        "onTimeUp": "Om tiden går ut avslutas diagnosen.",
        "pointsRequired": "Om du får mindre än {required} rätt behöver du öva mer.",
        "result": "{score} av {scoreMax}",
        "resultsSummary": {
          "aborted": "Avbrutet",
          "attemptNumber": "Försök {attempt}",
          "result": "{score} av {scoreMax} rätt"
        },
        "showAttempts": "Visa försök ({attempts} st)",
        "startButton": "Starta försök {count} av {total}",
        "startInfo": "När diagnosen startats kan den inte pausas.",
        "teacherAttemptsInfo": "Lärare kan alltid starta en diagnos."
      },
      "diagnosisShow": {
        "savingResult": "Sparar ditt resultat"
      },
      "entityBrowser": {
        "tree": {
          "item": {
            "collapse": "Stäng",
            "expand": "Öppna",
            "external": "Extern produkt"
          }
        }
      },
      "entityCustomizer": {
        "tree": {
          "group": {
            "toggleButtonLabel": "{isAllIncluded, select, true {Avmarkera alla} false {Markera alla} }"
          },
          "item": {
            "numberOfTriesHintLabel": "försök",
            "timeAllowed": "minuter",
            "toggleButtonLabel": "{isExpanded, select, true {Stäng} false {Öppna} }"
          }
        }
      },
      "entityImportModal": {
        "cancelButtonLabel": "Avbryt",
        "importButtonLabel": "Välj",
        "noEntities": "Kursen har inget innehåll. Gå till administrera kurs för att välja vilka titlar som ska ingå.",
        "noEntitiesStudent": "Kursen har inget innehåll.",
        "title": "Välj innehåll"
      },
      "entityIndex": {
        "item": {
          "headingLabel": "Uppgifter"
        }
      },
      "entityInspector": {
        "detail": {
          "authors": "{count, plural, other {Författare} }",
          "codeCopiedToClipboard": "Koden har kopierats till urklipp",
          "codeFailedToCopyToClipboard": "Koden kunde inte kopieras till urklipp",
          "copyReference": "Kopiera källhänvisning",
          "description": "Beskrivning",
          "illustrators": "{count, plural, one {Illustratör} other {Illustratörer} }",
          "includedInProducts": "{num, plural, =0 {Materialet ingår inte i några produkter.} =1 {Materialet ingår i produkten:} other {Materialet ingår i produkterna:}}",
          "isbn": "(ISBN) {id}",
          "language": "Språk",
          "languageCodes": {
            "Danska": "Danska",
            "da_DK": "Danska",
            "de_DE": "Tyska",
            "en_GB": "Engelska",
            "es_ES": "Spanska",
            "fr_FR": "Franska",
            "it_IT": "Italienska",
            "nn_NO": "Norska",
            "sv_SE": "Svenska"
          },
          "lastUpdated": "Senast uppdaterad",
          "level": "Nivå",
          "loading": "Laddar …",
          "locationCopiedToClipboard": "Länken lades till i urklipp",
          "locationFailedToCopyToClipboard": "Länken kunde inte läggas till i urklipp",
          "overview": "Allmänt",
          "product": {
            "articleNumber": "Artikelnummer",
            "authors": "{count, plural, other {Författare} }",
            "firstReleased": "Första utgåva",
            "illustrators": "{count, plural, one {Illustratör} other {Illustratörer} }",
            "translators": "{count, plural, other {Översättare} }"
          },
          "productInformation": "Produktinformation",
          "sourceCopiedToClipboard": "Källan lades till i urklipp",
          "sourceDigilar": "Använda det här innehållet som källa",
          "sourceFailedToCopyToClipboard": "Källan kunde inte läggas till i urklipp",
          "sourceReference": "Källhänvisning",
          "sourceString": "{url} (tillgänglig: {datetime})",
          "textVersions": "Textversioner",
          "translators": "{count, plural, other {Översättare} }",
          "type": "Innehållstyp",
          "versionTab": {
            "pane": {
              "about": "Information",
              "copyright": "Copyright",
              "entityMaterialGroupList": {
                "item": {
                  "detailLabel": "© {copyright}",
                  "typeLabel": "{type, select, audios {Ljud} images {Bild} videos {Video} other {Material} }",
                  "typeWithIndexLabel": "{type, select, audios {Ljud} images {Bild} videos {Video} other {Material} } i avsnitt {index}"
                }
              },
              "numSections": "Antal avsnitt",
              "numWords": "Antal ord",
              "readingTime": "Uppskattad lästid",
              "readingTimeMin": "{time} min"
            }
          }
        },
        "guidanceViewer": {
          "noResultMessage": "På den här nivån finns {type, select, guidances {ingen lärarhandledning} }.",
          "show": {
            "tableOfContents": "Innehållsförteckning"
          }
        },
        "missionCustomizer": {
          "categoryIsHidden": "textversionen är nu dold för alla i uppdraget",
          "chooseCategory": "Välj textversion att anpassa",
          "description": "Här kan du välja vilka delar som ska vara synliga i innehållet för kursdeltagarna.",
          "firstChooseCategory": "Välj först en textversion innan du börjar anpassa",
          "hideCategory": "Dölj hela textversionen",
          "teacherMaterial": "Lärarmaterial",
          "teacherMaterialDescription": "Anpassning av lärarmaterial gäller för alla textversioner."
        }
      },
      "entityMeta": {
        "guidance": "Lärarhandledning"
      },
      "entityPreviewPage": {
        "login": "Logga in",
        "types": {
          "archives": "Arkiv",
          "areas": "Arbetsområde",
          "assignments": "Arbetsuppgift",
          "books": "Bok",
          "collections": "Uppgiftsgrupp",
          "contents": "Innehåll",
          "exercises": "Övning",
          "folders": "Mapp",
          "glossaries": "Ordlista",
          "interactives": "Uppgifter",
          "sections": "Avsnitt",
          "studies": "Instudering"
        }
      },
      "entitySearch": {
        "noHit": "Tyvärr sökningen gav inga resultat.",
        "results": "Visar {hitsLow}-{hitsHigh} av {totalHits}",
        "searchInputToShort": "Sökordet måste innehålla minst 3 tecken",
        "searchSubmit": "Sök",
        "serachInputPlaceholder": "Sök efter innehåll"
      },
      "errorPage": {
        "content": "Något gick fel när vi försökte hämta data från servern. Försök igen om en liten stund.",
        "heading": "Innehållet kunde inte hämtas",
        "noInternetConnection": "Innehållet är inte tillgängligt offline. Anslut till internet och försök igen.",
        "other": "Innehållet kunde inte hämtas. Försök igen om en liten stund.",
        "toHomescreen": "Gå till startsidan",
        "tryAgain": "Försök igen"
      },
      "exerciseBottombar": {
        "button": {
          "correct": "Svara",
          "done": "Klar",
          "hideKeyTooltip": "Dölj facit",
          "hideSolutionTooltip": "Dölj lösningsförslag",
          "keyTooltip": "Visa facit",
          "keyboardTooltip": "Visa tangentbord",
          "next": "Nästa",
          "resetTooltip": "Rensa svar",
          "solutionTooltip": "Visa lösningsförslag",
          "submit": "Lämna in"
        },
        "status": {
          "audited": "Granskad",
          "correct": "Rätt",
          "done": "Besvarad",
          "editing": "Redigerar …",
          "incomplete": "Svar saknas",
          "notStarted": "Inte påbörjad",
          "saving": "Sparar …",
          "started": "Påbörjad",
          "teacherCorrect": "Mål uppnått",
          "teacherWrong": "Mål ej uppnått",
          "turnedIn": "Inlämnad",
          "wrong": "Fel"
        }
      },
      "exerciseTopbar": {
        "goToExercise": "Gå till uppgiften"
      },
      "exerciseView": {
        "assignment": {
          "check": {
            "incompleteMessage": "Du måste markera att du är klar."
          },
          "choice": {
            "hint": "<strong>Tips!</strong> Du har inte bockat för alla svarsalternativ som är rätt.",
            "incompleteMessage": "Välj ett svarsalternativ.",
            "incompleteMessageMultiple": "Välj ett eller flera svarsalternativ."
          },
          "editableMatrix": {
            "incompleteMessage": "Svar saknas."
          },
          "editor": {
            "incompleteMessage": "Skriv ett svar."
          },
          "media": {
            "access": "Du har inte gett tillgång till din mikrofon",
            "incomplete": "Du måste spela in ett ljud för att lämna in.",
            "noRecording": "Inget ljud inspelat",
            "processing": "Bearbetar",
            "readMore": "Läs mer",
            "record": "Spela in ljud",
            "recordingFinished": "Spela upp ljud",
            "removeConfirm": "Är du säker? Detta raderar ljudet som du har spelat in.",
            "removeSoundFile": "Ta om",
            "stop": "Stop"
          },
          "missingAnswer": {
            "info": "Svaret kan inte visas. Uppgiften har besvarats vid något tidigare tillfälle. Svaret finns tyvärr inte kvar men resultatet finns sparat. Om du vill kan du besvara uppgiften på nytt.",
            "startOver": "Gör uppgiften på nytt"
          },
          "numberLine": {
            "hint": "<strong>Tips!</strong> Du har inte fyllt i alla korrekta svar"
          },
          "placeChoices": {
            "highlightMarkersMessage": "Prova att trycka på en punkt på nytt",
            "hint": "<strong>Tips!</strong> Du har inte bockat för alla svarsalternativ som är rätt.",
            "incompleteMessage": "Välj ett svarsalternativ.",
            "incompleteMessageMultiple": "Välj ett eller flera svarsalternativ."
          },
          "placeImages": {
            "hint": "Tips!",
            "hintMessage": "Det finns bilder kvar som du behöver placera ut på rätt ställe.",
            "imagesEmpty": "Inga fler bilder att placera ut",
            "triedDrag": "Tryck på en punkt i bilden för att placera bilden."
          },
          "placeWords": {
            "hint": "Tips!",
            "hintMessage": "Det finns ord kvar som du behöver placera ut på rätt ställe.",
            "triedDrag": "Tryck på en punkt i bilden för att placera ordet.",
            "wordsEmpty": "Inga fler ord att placera ut"
          },
          "reply": {
            "close": "Stäng",
            "instruction": "Instruktion",
            "mathHelp": {
              "content": "<p>Så här skriver du matematik med tangentbordet:<br> <b>Multiplikation:</b> Skriv tecknet *<br> <b>Division och bråk:</b> Skriv tecknet /<br> <b>Bråk i blandad form:</b> Skriv mellanrum efter heltalet 3 2/5<br> <b>Tal och matematiska tecken:</b> Skriv utan mellanrum 3+5=8 <i>eller</i> 4*3 <i>eller</i> 6x</p>",
              "title": "Matematik med tangentbordet"
            }
          },
          "sort": {
            "hint": "<strong>Tips!</strong> Det finns svarsalternativ kvar som du behöver dra till rätt ställe."
          },
          "textGap": {
            "incompleteMessage": "Svar saknas."
          }
        },
        "comments": "Kommentarer",
        "common": {
          "dragAlternatives": {
            "imageDragEmpty": "Inga fler bilder att dra.",
            "textGapDragEmpty": "Inga fler ord att dra."
          }
        },
        "draftBadgeLabel": "Utkast",
        "edit": "Redigera",
        "editor": {
          "solution": "Lösningsförslag"
        },
        "hideDescription": "Dölj beskrivning",
        "missingContenteditor": "<p class=\"text-secondary\">Något gick fel!</p> Det gick inte att ladda övningen.",
        "removeFromWorkspace": "Koppla bort",
        "reset": {
          "confirm": "Är du säker på att du vill rensa ditt svar?"
        },
        "showDescription": "Visa beskrivning"
      },
      "fileAssignment": {
        "attachButtonLabel": "Bifoga",
        "attachedHeadingLabel": "Bifogade filer ({count})",
        "fromArchiveButton": "Mitt arkiv",
        "fromDeviceButton": "Egen fil",
        "fromLinkButton": "Länk"
      },
      "fileChooseModal": {
        "additionCollatorEmptyMessage": "<b>Du har inga filer än.</b> Här kommer du hitta alla bilder, filmer, ljudfiler och dokument som du har bifogat till olika uppgifter i dina produkter. Du kan också ladda upp nya filer här. Du kan återanvända filer och bifoga dem till flera uppgifter i dina produkter.",
        "additionCollatorNoResultMessage": "Inga filer hittades.",
        "title": "Filer"
      },
      "fileType": {
        "item": {
          "typeLabel": "{type, select, audio {ljudfil} document {dokument} image {bild} video {video} }"
        }
      },
      "fileUpload": {
        "performButtonLabel": "Ladda upp fil ..."
      },
      "fileWarning": {
        "missingFiles": "{num, plural, one {En bifogad fil} other {# bifogade filer}} kunde inte hittas."
      },
      "focusMode": {
        "action": "Dölj menyer",
        "close": "Visa menyer",
        "description": "Anpassningar du gör följer med i fokusläget.",
        "title": "Fokusera"
      },
      "footnoteList": {
        "headingLabel": "Fotnoter"
      },
      "header": {
        "base": {
          "helpButton": {
            "button": "Be om hjälp",
            "cancel": "Lämna kö",
            "description": "Om du ber om hjälp ser läraren det och du hamnar i en kö.",
            "info": "Om många vill ha hjälp samtidigt kan det ta en liten stund.",
            "labelStudent": "Be läraren om hjälp",
            "labelTeacher": "Hjälplista",
            "que": "Du har plats <strong>{que}</strong> i kön?",
            "title": "Behöver du hjälp?",
            "waitingTitle": "Du ber nu om hjälp"
          },
          "logoLink": {
            "logoTitle": "Gå till startsidan för NOKportalen"
          },
          "metaButton": {
            "tooltip": "Innehållsalternativ"
          },
          "metaMenu": {
            "breadcrumb": {
              "courseTitle": "Kursens startsida",
              "locationCopiedToClipboard": "Länken lades till i urklipp",
              "locationFailedToCopyToClipboard": "Länken kunde inte läggas till i urklipp",
              "menu": {
                "copyLink": "Kopiera djuplänk",
                "share": "Dela"
              },
              "productTitle": "Produktens startsida"
            },
            "courseUsers": "Deltagare",
            "createCourse": "Skapa kurs",
            "customize": "Anpassa innehållet",
            "information": "Information"
          },
          "navigationDrawer": {
            "courseSettings": "Administrera kurs",
            "fallbackTitle": "Innehåll",
            "start": "Kursens startsida"
          },
          "supportButton": {
            "label": "Support"
          },
          "teacherButton": {
            "course": "Skapa kurs",
            "customize": "Anpassa innehållet",
            "descriptionCourse": "Arbeta tillsammans med dina elever och tilldela dem licens.",
            "descriptionCustomize": "Välj vilka delar som ska visas för dina elever.",
            "descriptionGuidance": "Information om hur du kan arbeta med läromedlet.",
            "descriptionHelpList": "Se vilka elever som ingår i kursen, samt möjlighet att starta en digital hjälplista.",
            "guidance": "Lärarhandledning",
            "helpList": "Elevlista",
            "label": "För lärare",
            "news": "Nyhet!",
            "teacherButtonInfo": "Nu samlar vi funktioner för lärare under en knapp. Om det finns en lärarhandledning till innehållet hittar du den här.",
            "title": "Lärarfunktioner samlas på ett ställe"
          },
          "userDrawer": {
            "account": "Mitt konto"
          }
        },
        "book": {
          "search": "Sök"
        },
        "course": {
          "search": "Sök",
          "switchCoursePopup": {
            "switchCourse": "Byt kurs"
          }
        },
        "courseUsersButton": {
          "ariaButtonLabel": "Öppna deltagarmenyn",
          "tooltip": "Deltagarmeny"
        },
        "courseUsersDrawer": {
          "title": "Elevlista"
        },
        "mission": {
          "customizeButtonTooltip": "Anpassa innehållet",
          "customizeInfoPopup": {
            "description": "Här kan du anpassa vilka delar av innehållet och övningarna som visas för dina kursdeltagare.",
            "title": "Anpassa innehåll"
          },
          "leaveMission": "Avsluta uppdrag",
          "returnButtonTooltip": "Tillbaka"
        },
        "product": {
          "search": "Sök"
        }
      },
      "imageCardCollection": {
        "isCourse": "Kurs",
        "students": "{students} elever",
        "teachers": "{teachers} lärare"
      },
      "interactives": {
        "collectionItem": {
          "carousel": {
            "continue": "Fortsätt",
            "numExercises": "{count, plural, one {# uppgift} other {# uppgifter}}",
            "result": "{score} av {scoreMax} rätt"
          },
          "diagnosis": {
            "continue": "Fortsätt",
            "diagnosisInfo": "{count, plural, one {# uppgift} other {# uppgifter}}, {time, plural, one {# minut} other {# minuter}}, {attempts, plural, one {# försök kvar} other {# försök kvar}}",
            "result": "{score} av {scoreMax} rätt"
          }
        }
      },
      "latestVisitedContent": {
        "latestVisited": "Senast besökta:"
      },
      "materialViewer": {
        "attachmentDialog": {
          "downloadHeadingLabel": "Ladda ner ...",
          "retryButtonLabel": "Laddas inte filen ned? Försök igen"
        },
        "lightbox": {
          "closeButton": {
            "label": "Stäng"
          }
        },
        "linkDialog": {
          "cancelButtonLabel": "Avbryt",
          "continueButtonLabel": "Fortsätt",
          "message": "Alla länkar är noga utvalda och kvalitetsgranskade av våra författare. De kontrolleras kontinuerligt, men vi kan inte ansvara för de eventuella förändringar eller fel som över tid uppstår på andra hemsidor. Hjälp oss gärna genom att rapportera eventuella problem till oss genom vårt <a class=\"ui-link ui-link--link--default\" href=\"{contactUrl}\" target=\"_blank\">kontaktformulär</a>.",
          "title": "Du lämnar nu Natur & Kulturs plattform"
        }
      },
      "missionAddActionMenu": {
        "items": {
          "copy": {
            "description": "Kopiera uppdrag du tidigare har skapat i en kurs.",
            "label": "Kopiera uppdrag"
          },
          "new": {
            "description": "Skapa ett nytt uppdrag.",
            "label": "Nytt uppdrag"
          }
        }
      },
      "missionBrowser": {
        "item": {
          "auditLinkLabel": "Uppföljning",
          "cancelSchedulingButtonLabel": "Avbryt",
          "editButtonLabel": "Redigera",
          "endBadgeLabel": "{daysLeft, plural, =0 {Avslutas idag} one {Avslutas imorgon} other {Avslutas om # dagar} }",
          "hideButtonLabel": "Dölj på översikten",
          "openLinkLabel": "Öppna",
          "scheduleButtonLabel": "Schemalägg",
          "scheduledLabel": "Schemalagd:",
          "scheduledMessage": "{schedule, select, until {Visas på översikten till och med {endDate}} infinite {Visas på översikten} ended {Uppdraget avslutades {endDate}} between {Visas på översikten mellan {startDate} och {endDate}} equal {Visas på översikten {startDate}} from {{daysUntil, plural, one {Visas på översikten från och med imorgon} other {Visas på översikten från och med {startDate}} }} }",
          "targetedBadgeLabel": "Riktat",
          "toggleButtonLabel": "{isExpanded, select, true {Fäll ut} false {Fäll ihop} }",
          "visibleSwitchLabel": "Visa på översikten"
        }
      },
      "missionCollator": {
        "resultAlertMessage": "{hasSearch, select, true {Din sökning gav inga resultat.} false {Inga uppdrag hittades.} }",
        "searchInputLabel": "Sök uppdrag",
        "searchInputPlaceholder": "Sök på uppdragets namn"
      },
      "missionContent": {
        "customContentList": {
          "item": {
            "customizeButtonLabel": "Anpassa",
            "invalidAlertMessage": "Det här innehållet kan inte visas. Det beror på att det ingår i en titel som inte hör till kursen.",
            "rejectedAlertMessage": "Den här delen av läromedlet kan inte visas. Det kan bero på att din licens har gått ut eller att innehållet har tagits bort i samband med en revidering."
          }
        },
        "customizeContent": "Anpassa innehåll",
        "emptyMessage": "{isTeacher, select, true {Det finns inget innehåll till detta uppdrag. Klicka på Redigera för att lägga till innehåll.} false {Det finns inget innehåll till detta uppdrag.} }",
        "types": {
          "archives": "Arkiv",
          "areas": "Arbetsområde",
          "assignments": "Arbetsuppgift",
          "books": "Bok",
          "books_external": "Bok",
          "collections": "Uppgiftsgrupp",
          "contents": "Innehåll",
          "exercises": "Övning",
          "folders": "Mapp",
          "glossaries": "Ordlista",
          "interactives": "Uppgifter",
          "sections": "Avsnitt",
          "studies": "Instudering"
        }
      },
      "missionDetailModal": {
        "attachMenuButtonItems": {
          "fromArchive": "Mitt arkiv",
          "fromDevice": "Egen fil",
          "internalLink": "Produktinnehåll",
          "link": "Länk"
        },
        "attachMenuButtonLabel": "Bifoga",
        "cancelButtonLabel": "Avbryt",
        "customContentList": {
          "addNewButtonLabel": "Lägg till innehåll",
          "customizeMessage": "Du kan anpassa enskilt innehåll när uppdraget är sparat eller publicerat.",
          "headingLabel": "Innehåll",
          "item": {
            "invalidAlertMessage": "Det här innehållet kan inte visas. Det beror på att det ingår i en titel som inte hör till kursen.",
            "rejectedAlertMessage": "Den här delen av läromedlet kan inte visas. Det kan bero på att din licens har gått ut eller att innehållet har tagits bort i samband med en revidering.",
            "removeButtonLabel": "Ta bort innehåll"
          }
        },
        "customizeInfo": "Du kan dölja / visa delar av innehållet genom att besöka innehållet inuti uppdraget.",
        "deleteButtonLabel": "Ta bort",
        "descriptionEditorLabel": "Beskrivning",
        "hideSolutionsCheckboxLabel": "Dölj facit",
        "optionsHeadingLabel": "Alternativ",
        "saveButtonLabel": "Spara",
        "targetIndividualReceiversCheckboxLabel": "Rikta till enskilda elever",
        "title": "{isNew, select, true {Nytt uppdrag} false {Redigera uppdrag} }",
        "titleInputLabel": "Titel",
        "titleInputPlaceholder": "Ange en titel",
        "types": {
          "archives": "Arkiv",
          "areas": "Arbetsområde",
          "assignments": "Arbetsuppgift",
          "books": "Bok",
          "books_external": "Bok",
          "collections": "Uppgiftsgrupp",
          "contents": "Innehåll",
          "exercises": "Övning",
          "folders": "Mapp",
          "glossaries": "Ordlista",
          "interactives": "Uppgifter",
          "sections": "Avsnitt",
          "studies": "Instudering"
        },
        "visibilityHeadingLabel": "Synlighet",
        "visibilityOptions": {
          "hidden": "Dölj på översikten",
          "scheduled": "Schemalägg",
          "visible": "Visa på översikten"
        }
      },
      "missionLinkedEntity": {
        "invalidLink": "Det här innehållet kan inte visas. Det beror på att det ingår i en titel som inte hör till kursen.",
        "types": {
          "archives": "Arkiv",
          "areas": "Arbetsområde",
          "assignments": "Arbetsuppgift",
          "books": "Bok",
          "books_external": "Bok",
          "collections": "Uppgiftsgrupp",
          "contents": "Innehåll",
          "exercises": "Övning",
          "folders": "Mapp",
          "glossaries": "Ordlista",
          "interactives": "Uppgifter",
          "sections": "Avsnitt",
          "studies": "Instudering"
        }
      },
      "missionList": {
        "item": {
          "contentLabel": "Innehåll:",
          "noContentMessage": "Inget innehåll"
        }
      },
      "missionOptionsActionMenu": {
        "items": {
          "audit": {
            "label": "Uppföljning"
          },
          "copy": {
            "label": "Kopiera uppdrag"
          },
          "edit": {
            "label": "Redigera"
          },
          "hide": {
            "label": "Dölj på översikten"
          },
          "schedule": {
            "label": "Schemalägg"
          },
          "share": {
            "label": "Dela uppdrag"
          },
          "show": {
            "label": "Visa på översikten"
          }
        }
      },
      "missionOptionsButton": {
        "label": "Alternativ"
      },
      "missionScheduleFormPart": {
        "endDateDatepickerLabel": "Avslutas",
        "startDateDatepickerLabel": "Startar"
      },
      "missionShareLinkModal": {
        "title": "Dela uppdrag"
      },
      "modelRejection": {
        "noInternetConnection": "Innehållet är inte tillgängligt offline. Anslut till internet och försök igen.",
        "other": "Innehållet kunde inte hämtas. Försök igen om en liten stund."
      },
      "noteBrowser": {
        "performMenuButtonItems": {
          "delete": "Ta bort",
          "edit": "Redigera",
          "unlink": "Koppla bort"
        }
      },
      "noteChooseModal": {
        "additionCollatorEmptyMessage": "<b>Du har inga anteckningar än.</b> Här kommer du hitta alla anteckningar som du har gjort i dina produkter. Om du vill kan du lägga till samma anteckning till flera ställen i dina produkter.",
        "additionCollatorNoResultMessage": "Inga anteckningar hittades.",
        "placeholderSearch": "Sök i anteckningar",
        "title": "Anteckningar"
      },
      "noteDetail": {
        "activeBreadcrumbItemLabel": "{isNew, select, true {Ny anteckning} false {Redigera anteckning} }",
        "contentInputLabel": "Innehåll",
        "createdLabel": "Skapad: {created}",
        "indexBreadcrumbItemLabel": "Anteckningar",
        "titleInputLabel": "Titel"
      },
      "noticeManager": {
        "maintenanceMessage": {
          "buttonText": "Okej, tack"
        },
        "snacks": {
          "close": "Stäng"
        },
        "topMessage": {
          "close": "Stäng meddelande"
        }
      },
      "pageContentMenu": {
        "courseAdministrationDescriptionMessage": "Hantera deltagare och produkter för kursen.",
        "courseAdministrationLinkLabel": "Administrera kurs"
      },
      "productCollator": {
        "noResultAlertMessage": "Inga produkter hittades.",
        "searchButtonLabel": "Sök",
        "searchInputPlaceholder": "Sök produkt eller extramaterial",
        "showExtraMaterial": "Visa extramaterial",
        "stageSelectPlaceholder": "Alla åldrar",
        "subjectSelectPlaceholder": "Alla kategorier"
      },
      "productLicenseBrowser": {
        "overlay": {
          "readMoreLinkLabel": "Läs mer om produkten"
        },
        "schoolItem": {
          "activateButtonLabel": "Lägg till",
          "activatedTextLabel": "Tilldelad",
          "daysLeftOnLicenseTextLabel": "{daysLeft, plural, one {En dag} other {# dagar} } kvar på din licens",
          "daysLeftOnTextLabel": "Licensen går ut {days}",
          "demoLicenseOnTextLabel": "Testlicensen går ut om {days} dagar",
          "expiredTextLabel": "Testlicensen gick ut {date}",
          "extendButtonLabel": "Förläng",
          "extramaterialTextLabel": "Extramaterial",
          "extramaterialTooltip": "Gratis extramaterial",
          "freeTextLabel": "Prova gratis",
          "freeTooltip": "Prova gratis i 30 dagar",
          "numberOfLicensesText": "{numberOfUsesLeft, plural, one {1 licens} other {# licenser} }",
          "numberOfLicensesTooltip": "Antalet licenser att tilldela",
          "purchase": "Köp",
          "unlimitedTextLabel": "Obegränsad",
          "unlimitedTooltip": "Obegränsat antal licenser att tilldela",
          "waitingForExtension": "Licensen väntar på förlängning"
        },
        "trialItem": {
          "activatedTextLabel": "Tilldelad",
          "buyLinkLabel": "Köp",
          "daysLeftOnTrialTextLabel": "Testlicens går ut {date}",
          "extramaterialTextLabel": "Extramaterial",
          "extramaterialTooltip": "Gratis extramaterial",
          "freeTextLabel": "Prova gratis",
          "freeTooltip": "Prova gratis i 30 dagar",
          "freeTooltipUntil": "Prova gratis till {date}",
          "trialEndedText": "Testlicens förbrukad",
          "tryButtonLabel": "Lägg till",
          "unabledToCreateLicense": "Du har redan provat denna produkt.",
          "unlimitedTextLabel": "Obegränsad",
          "unlimitedTooltip": "Obegränsat antal licenser att tilldela"
        }
      },
      "productLicenseManagerModal": {
        "activationCode": "Aktiveringskod",
        "addBooksMessageHeading": "Vill du köpa fler produkter till skolan?",
        "addBooksMessageParagraph": "Vill du köpa fler licenser till någon produkt eller nya produkter som din skola ännu inte använder? Besök din återförsäljare (Läromedia, GR eller Skolon) eller <a href=\"https://nok.se\" target=\"_blank\" class=\"ui-link ui-link--default\">nok.se</a>. Du kan alltid prova en produkt gratis i 30 dagar via fliken <em>Prova gratis</em>.",
        "handledExternally": "Skolans licenser hanteras i {provider}",
        "handledInUnknownSystem": "annat system",
        "navItems": {
          "products": "Produkter",
          "tryForFree": "Prova gratis"
        },
        "title": "Lägg till produkt eller extramaterial"
      },
      "productPicker": {
        "item": {
          "extramaterialTextLabel": "Extramaterial",
          "numberOfLicensesText": "{numberOfLicensesLeft, plural, one {1 licens} other {# licenser} }",
          "pickButtonLabel": "Lägg till",
          "trialTextLabel": "Prova gratis",
          "unableToPickButtonLabel": {
            "invalidType": "Det går inte att blanda Digilär-produkter med andra produkter i samma kurs för tillfället. Detta är under utveckling.",
            "teachersOnly": "Produkten är endast för lärare och kan därför inte läggas till i kursen"
          },
          "unlimitedTextLabel": "Obegränsad",
          "unpickButtonLabel": "Ta bort"
        }
      },
      "productPickerModal": {
        "abortButtonLabel": "Avbryt",
        "saveButtonLabel": "Spara",
        "title": "Välj produkter"
      },
      "productQuerier": {
        "errorAlertMessage": "Produkterna kunde inte hämtas.",
        "extraCheckboxLabel": "Visa extramaterial",
        "noResultAlertMessage": "Inga produkter hittades.",
        "searchButtonLabel": "Sök",
        "searchInputPlaceholder": "Sök produkt eller extramaterial",
        "stageSelectPlaceholder": "Alla åldrar",
        "subjectSelectPlaceholder": "Alla kategorier"
      },
      "productRoot": {
        "productInformation": "Produktinformation"
      },
      "products": {
        "productActivityList": {
          "continueWhereIwas": "Fortsätt",
          "welcomeProducts": "Innehåll"
        },
        "productMain": {
          "books": "Innehåll",
          "myResults": "Min aktivitet",
          "pages": "Produktsidor"
        }
      },
      "productsCoursesOverview": {
        "filters": {
          "ordering": {
            "all": "Kurser överst",
            "courses": "Kurser överst",
            "label": "Visar:",
            "products": "Produkter överst"
          },
          "sorting": {
            "date": "Senast",
            "label": "Sortering:",
            "title": "A - Ö"
          }
        },
        "subtitles": {
          "courses": "Kurser",
          "products": "Produkter"
        }
      },
      "productsOverview": {
        "filter": {
          "all": "Allt",
          "courses": "Kurser",
          "products": "Produkter"
        },
        "order": {
          "date": "Senast",
          "title": "Bokstavsordning"
        }
      },
      "qrCodeModal": {
        "closeButtonLabel": "Stäng"
      },
      "readSettings": {
        "action": "Dölj menyer",
        "behavior": "Beteende",
        "browse": "Bläddra",
        "colorSchemes": {
          "dark": "Mörkt",
          "dyslexi": "Gult",
          "standard": "Standard"
        },
        "defaultFont": "Förval",
        "font": "Typsnitt",
        "fontsize": "Textstorlek",
        "fontsizes": {
          "large": "Större",
          "medium": "Förval"
        },
        "lineHeight": "Radavstånd",
        "lineHeights": {
          "large": "Större",
          "medium": "Förval"
        },
        "marking": "Färgmarkeringar i text",
        "markings": {
          "colored": "Helfärgade",
          "discreet": "Diskreta",
          "underlined": "Understrukna"
        },
        "onlyImage": "Endast bild",
        "onlyText": "Endast text",
        "readingRuler": "Läslinjal",
        "scroll": "Skrolla",
        "title": "Fokusera",
        "tools": "Hjälpmedel",
        "translationLink": "Översätt"
      },
      "readingBuddy": {
        "avatarImageAlt": "Hund",
        "restartButton": "Från början",
        "states": {
          "ended": "Tack för samtalet! Tryck på play om du vill prata igen.",
          "listening": "Prata in ditt svar, jag lyssnar …",
          "notStarted": "Hej! Prata med mig.",
          "paused": "Jag har pausat.",
          "speaking": "Pratar …"
        }
      },
      "scheduleDetailModal": {
        "cancelButtonLabel": "Avbryt",
        "message": "Schemalägg visning av uppdraget för dina elever på översikten.",
        "scheduleButtonLabel": "Schemalägg",
        "title": "Schemalägg uppdrag"
      },
      "shareLinkModal": {
        "copy": "Kopiera länk",
        "copyLink": "Kopiera länk",
        "downloadQrCodeButtonLabel": "Ladda ner QR-kod",
        "includeProviderSwitchLabel": "Inkludera inloggning via {provider} i länken",
        "locationCopiedToClipboard": "Länken lades till i urklipp",
        "locationFailedToCopyToClipboard": "Länken kunde inte läggas till i urklipp",
        "message": {
          "body": "{title}",
          "title": "{title}"
        },
        "shareMessage": "Mottagaren av länken måste ha tillgång till innehållet för att se det.",
        "shareTo": "Dela till",
        "showQrCodeButtonLabel": "Visa QR-kod",
        "title": "Dela innehåll"
      },
      "shareMeta": {
        "locationCopiedToClipboard": "Länken lades till i urklipp",
        "locationFailedToCopyToClipboard": "Länken kunde inte läggas till i urklipp",
        "menu": {
          "aboutContent": "Information",
          "copyLink": "Kopiera djuplänk",
          "share": "Dela"
        }
      },
      "shareMetaMenu": {
        "modal": {
          "breadcrumb": {
            "courseTitle": "Kursens startsida",
            "productTitle": "Produktens startsida"
          },
          "createCourse": "Skapa kurs",
          "information": "Information"
        }
      },
      "simpleSpeechPlayer": {
        "listen": "Lyssna"
      },
      "speakerContext": {
        "noAutoplayAction": "Tryck här för att prova igen",
        "noAutoplayContent": "Av någon anledning ville din webbläsare inte starta uppläsningen.",
        "noAutoplayHeading": "Uppläsningen kunde inte startas"
      },
      "stickyUpdateNotice": {
        "buttonLabel": "Hämta ändringar",
        "message": "Licenserna har ändrats av en annan användare."
      },
      "storyModal": {
        "header": "Bildberättelse",
        "ingress": "Detta är ett bildspel som kan spelas upp automatiskt med ljudet. Välj Lyssna för att starta bildspelet! Du kan när som helst stoppa uppläsningen och bara läsa på egen hand.",
        "listenButton": "Lyssna",
        "onlyImageCheckboxLabel": "Endast bild (döljer texten medan du lyssnar)",
        "readButton": "Jag vill bara läsa"
      },
      "studentSelect": {
        "allStudents": "Alla elever i kursen",
        "allStudentsInMission": "Alla elever i uppdraget",
        "selectStudent": "Välj elev"
      },
      "tableOfContents": {
        "list": {
          "assignments": "Uppgifter",
          "wordlists": "Ordlistor"
        }
      },
      "tableOfContentsItem": {
        "headingLabel": "Uppgifter",
        "numAssignments": "{count, plural, =0 {Inga uppgifter} =1 {En uppgift} other {# uppgifter}}"
      },
      "tbRender": {
        "assignmentLink": {
          "excludedAlertMessage": "Läraren har tagit bort uppgiften"
        },
        "attachment": {
          "download": "Ladda ner"
        },
        "gallery": {
          "fullscreen": "Förstora bild",
          "indexLabel": "Bild",
          "indexOf": "av",
          "next": "Nästa bild",
          "prev": "Föregående bild"
        },
        "image": {
          "fullscreenTooltip": "Visa i helskärmsläge"
        },
        "placeholder": {
          "toolTip": "Du har valt anpassningen Endast text"
        }
      },
      "teacherAssignmentBrowser": {
        "item": {
          "draftBadgeLabel": "Utkast"
        }
      },
      "teacherAssignmentChooseModal": {
        "additionCollatorEmptyMessage": "<b>Du har inga läraruppgifter än.</b> Här kommer du hitta alla uppgifter som du som lärare har skapat till olika delar i dina produkter. Om du vill kan du återanvända uppgifter och koppla dem till flera ställen i dina produkter eller i flera kurser till olika elevgrupper.",
        "additionCollatorNoResultMessage": "Inga läraruppgifter hittades.",
        "placeholderSearch": "Sök i uppgifter",
        "title": "Uppgifter"
      },
      "teacherAssignmentDetail": {
        "activeBreadcrumbItemLabel": "{isNew, select, true {Ny uppgift} false {Redigera uppgift} }",
        "answerTemplateEditorLabel": "Svarsmall",
        "cancelLinkLabel": "Avbryt",
        "deleteButtonLabel": "Ta bort",
        "indexBreadcrumbItemLabel": "Uppgifter",
        "moreOptionsButtonLabel": "Fler alternativ",
        "questionEditorLabel": "Uppgift",
        "saveButtonLabel": "Publicera",
        "saveButtonTooltipLabel": "Dela uppgiften med dina elever",
        "switchToDraftButtonLabel": "{isPublished, select, true {Avpublicera} false {Spara} }",
        "titleInputLabel": "Titel",
        "titleInputPlaceholder": "Skriv titel"
      },
      "teacherGuidanceButton": {
        "guidanceButton": "Lärarhandledning"
      },
      "themeSwitch": {
        "colorScheme": "Tema",
        "colorSchemes": {
          "dark": "Mörkt",
          "dyslexi": "Gult",
          "standard": "Standard"
        }
      },
      "userExternalLinkDialog": {
        "cancelButtonLabel": "Avbryt",
        "continueButtonLabel": "Fortsätt",
        "message": "Du kommer att förflyttas till följande adress. Vill du fortsätta?",
        "title": "Du lämnar nu Natur & Kulturs plattform"
      },
      "userProfile": {
        "logout": "Logga ut"
      },
      "userSelect": {
        "cancel": "Avbryt",
        "done": "Klar",
        "list": {
          "noUsersMessage": "Inga användare",
          "numStudents": "{count, plural, one {En elev } other {{count} elever }}{hasGroups, select, true { i {countGroups, plural, one {en grupp} other {{countGroups} grupper}}} other {}}",
          "searchPlaceholder": "Sök användare"
        },
        "noAddedUsers": "Inga elever valda",
        "searchHeading": "Sök",
        "selectUser": "Välj användare",
        "table": {
          "userEmailHeading": "E-post",
          "userNameHeading": "Namn"
        }
      },
      "videoPlayer": {
        "advertisement": "Annons",
        "all": "Alla",
        "buffered": "Buffrat",
        "captions": "Undertexter",
        "currentTime": "Nuvarande tid",
        "disableCaptions": "Stäng av undertexter",
        "disabled": "Avstängd",
        "duration": "Längd",
        "enableCaptions": "Visa undertexter",
        "enabled": "Påslagen",
        "end": "Slut",
        "enterFullscreen": "Slå på fullskärm",
        "exitFullscreen": "Stäng av fullskärm",
        "fastForward": "Spola fram {seektime}s",
        "frameTitle": "Spelare för {title}",
        "loop": "Loopa",
        "menuBack": "Tillbaka till föregående meny",
        "mute": "Stäng av ljudet",
        "normal": "Normal",
        "pause": "Pausa",
        "play": "Starta",
        "played": "Spelat",
        "quality": "Kvalitet",
        "qualityBadge": {
          "1080": "HD",
          "1440": "HD",
          "2160": "4K",
          "480": "SD",
          "576": "SD",
          "720": "HD"
        },
        "reset": "Återställ",
        "restart": "Starta om",
        "rewind": "Spola tillbaka {seektime}s",
        "seek": "Sök",
        "seekLabel": "{currentTime} av {duration}",
        "settings": "Inställningar",
        "speed": "Hastighet",
        "start": "Start",
        "unmute": "Slå på ljudet",
        "volume": "Volym"
      },
      "waitFor": {
        "errorAlertMessage": "Datan kunde inte laddas.",
        "retryButtonLabel": "Försök igen"
      },
      "wordBrowser": {
        "item": {
          "performMenuButtonItems": {
            "delete": "Ta bort",
            "edit": "Redigera"
          }
        }
      },
      "wordDetail": {
        "activeBreadcrumbItemLabel": "{isNew, select, true {Nytt ord} false {Redigera ord} }",
        "explanationEditorLabel": "Förklaring",
        "indexBreadcrumbItemLabel": "Ordlistor",
        "translationInputLabel": "Översättning",
        "wordInputLabel": "Ord"
      },
      "wordExplanationBox": {
        "examples": "Exempel",
        "explanation": "Förklaring",
        "translation": "Översättning"
      },
      "wordQuiz": {
        "answer": "Svara",
        "cancel": "Avsluta",
        "next": "Nästa",
        "noWordsMessage": {
          "concepts": "<p>Du kan inte träna på denna ordlista för att den saknar ord med förklaring. Lägg till en förklaring på orden genom att redigera dem ett ord i taget.</p><p>Du kan också lägga till nya ord i listan eller spara de gulmarkerade ord som finns i våra texter.</p>",
          "glossaries": "<p>Du kan inte träna på denna ordlista för att den saknar ord med översättning. Lägg till en översättning på orden genom att redigera dem ett ord i taget.</p><p>Du kan också lägga till nya ord i listan eller spara de gulmarkerade ord som finns i våra texter.</p>"
        },
        "quiz": {
          "explanation": "Förklaring",
          "inputPlaceholder": "Skriv ord …",
          "practiceOnWords": "Träna på ord",
          "replaceWord": "(ord)",
          "summary": "Du hade {result} rätt av {total} möjliga.",
          "translation": "Översättning",
          "word": "Ord",
          "wrongAnswer": "Tyvärr, det rätta svaret är:"
        },
        "restart": "Starta om",
        "summary": "Du hade {result} rätt av {total} möjliga."
      },
      "wordSave": {
        "save": "Spara",
        "wordExplanation": {
          "addPlaceholder": "Lägg till …",
          "examples": "Exempel",
          "explanation": "Förklaring",
          "listName": "Ord från {title}",
          "savedTo": "Sparad till: {list}",
          "translation": "Översättning",
          "wordNotSavedMessage": "Ordet kunde inte sparas just nu.",
          "wordSavedMessage": "Ordet har sparats i ordlistan."
        }
      },
      "wordlistBrowser": {
        "item": {
          "draftBadgeLabel": "Utkast",
          "numWords": "{num, plural, =0 {Inga ord} =1 {# ord} other {# ord}}",
          "performMenuButtonItems": {
            "addWord": "Lägg till ord",
            "delete": "Ta bort",
            "duplicate": "Spara en kopia",
            "edit": "Redigera",
            "publish": "Publicera",
            "quiz": "Träna",
            "unlink": "Koppla bort",
            "unpublish": "Avpublicera"
          }
        }
      },
      "wordlistChooseModal": {
        "additionCollatorEmptyMessage": "<b>Du har inga ordlistor än.</b> Här kommer du hitta alla ordlistor som du har gjort i dina produkter. Om du vill kan du lägga till samma ordlista till flera ställen i dina produkter.",
        "additionCollatorNoResultMessage": "Inga ordlistor hittades.",
        "placeholderSearch": "Sök i ordlistor",
        "title": "Ordlistor"
      },
      "wordlistDetail": {
        "activeBreadcrumbItemLabel": "{isNew, select, true {Ny ordlista} false {Redigera ordlista} }",
        "cancelLinkOrButtonLabel": "Avbryt",
        "createdLabel": "Skapad: {created}",
        "deleteButtonLabel": "Ta bort",
        "indexBreadcrumbItemLabel": "Ordlistor",
        "languageSelectLabel": "Språk",
        "nameInputLabel": "Titel",
        "saveButtonLabel": "{userRole, select, teacher {{isPublished, select, true {Uppdatera} false {Publicera} }} other {Spara} }",
        "saveButtonTooltipLabel": "Dela ordlistan med dina elever",
        "switchToDraftButtonLabel": "{isPublished, select, true {Avpublicera} false {Spara} }",
        "typeRadioButtonGroupItems": {
          "concepts": {
            "description": "Skriv förklaringar till ord, med samma språk som orden är skrivna på.",
            "label": "Begrepp"
          },
          "glossaries": {
            "description": "Översätt ord från ett annat språk.",
            "label": "Översättning"
          }
        },
        "typeRadioButtonGroupLabel": "Typ av ordlista"
      },
      "wordlistView": {
        "draftBadgeLabel": "Utkast",
        "duplicateButtonLabel": "Spara en kopia",
        "editLinkLabel": "Redigera",
        "indexBreadcrumbItemLabel": "Ordlistor",
        "newLinkLabel": "Lägg till ord",
        "quizButtonLabel": "Träna",
        "unlinkButtonLabel": "Koppla bort",
        "wordBrowserEmptyAlertMessage": "Den här ordlistan har inga ord än."
      },
      "workspace": {
        "assignments": {
          "edit": {
            "activeBreadcrumbItemLabel": "{isNew, select, true {Ny uppgift} false {Redigera uppgift} }"
          },
          "error": {
            "assignments": {
              "buttonText": "Fortsätt",
              "heading": "Uppgiften har tagits bort"
            },
            "notes": {
              "buttonText": "Fortsätt",
              "heading": "Anteckningen har tagits bort"
            },
            "wordlists": {
              "buttonText": "Fortsätt",
              "heading": "Ordlistan har tagits bort"
            }
          },
          "index": {
            "contentWorkspaceTitle": "Uppgifter",
            "importButtonLabel": "Från mitt arkiv",
            "newLinkLabel": "Ny uppgift",
            "noAssignments": "<p><strong>Det finns inga uppgifter här.</strong> Det kan bero på:</p><ul><li>Det finns inga färdiga uppgifter på denna position.</li><li>En lärare har valt att dölja de färdiga uppgifter som finns.</li></ul>{isTeacher, select, true {<p>Du som är lärare kan lägga till dina egna uppgifter här.</p>} other {}}"
          },
          "show": {
            "backTooltip": "Tillbaka till uppgifter",
            "close": "Stäng"
          }
        },
        "notes": {
          "edit": {
            "activeBreadcrumbItemLabel": "{isNew, select, true {Ny anteckning} false {Redigera anteckning} }"
          },
          "error": {
            "buttonText": "Fortsätt",
            "heading": "Anteckningen har tagits bort"
          },
          "index": {
            "contentWorkspaceTitle": "Anteckningar",
            "currentNoResultAlertMessage": "Du har inga aktuella anteckningar till detta innehåll.",
            "importButtonLabel": "Från mitt arkiv",
            "newLinkLabel": "Ny anteckning",
            "relatedAccordionHeaderLabel": "Relaterade anteckningar"
          }
        },
        "topbar": {
          "close": "Stäng"
        },
        "wordlist": {
          "edit": {
            "activeBreadcrumbItemLabel": "{isNew, select, true {Ny ordlista} false {Redigera ordlista} }"
          },
          "error": {
            "buttonText": "Fortsätt",
            "heading": "Ordlistan har tagits bort"
          },
          "index": {
            "contentWorkspaceTitle": "Ordlistor",
            "currentNoResultAlertMessage": "Du har inga aktuella ordlistor till detta innehåll.",
            "importButtonLabel": "Från mitt arkiv",
            "newLinkLabel": "Ny ordlista",
            "relatedAccordionHeaderLabel": "Relaterade ordlistor"
          },
          "show": {
            "draftBadgeLabel": "Utkast"
          },
          "words": {
            "edit": {
              "activeBreadcrumbItemLabel": "{isNew, select, true {Nytt ord} false {Redigera ord} }"
            }
          }
        }
      }
    },
    "compton": {
      "alert": {
        "dismissButtonLabel": "Avfärda"
      },
      "audio": {
        "airPlayLabel": "AirPlay",
        "errorMessage": "Ljudfilen kunde inte laddas.",
        "loadingLabel": "Laddar …",
        "pauseLabel": "Pausa",
        "playLabel": "Spela upp",
        "settingsLabel": "Inställningar",
        "speedUnit": "x"
      },
      "await": {
        "errorMessage": "Datan kunde inte laddas.",
        "loaderLabel": "Laddar ...",
        "retryButtonLabel": "Försök igen"
      },
      "confirm": {
        "cancelLabel": "Avbryt",
        "okLabel": "OK"
      },
      "copyrightList": {
        "item": {
          "detailLabel": "© {copyright}",
          "typeLabel": "{type, select, audios {Ljud} images {Bild} videos {Video} interactiveImages {Interaktiv bild} other {Material} }",
          "typeWithIndexLabel": "{type, select, audios {Ljud} images {Bild} videos {Video} interactiveImages {Interaktiv bild} other {Material} } i avsnitt {index}"
        }
      },
      "copyrightPopup": {
        "headingLabel": "Copyright"
      },
      "copyrightPopupButton": {
        "label": "Copyright"
      },
      "datepicker": {
        "months": "Januari,Februari,Mars,April,Maj,Juni,Juli,Augusti,September,Oktober,November,December",
        "nextMonth": "Nästa månad",
        "previousMonth": "Föregående månad",
        "weekdays": "Söndag,Måndag,Tisdag,Onsdag,Torsdag,Fredag,Lördag",
        "weekdaysShort": "Sön,Mån,Tis,Ons,Tor,Fre,Lör"
      },
      "dialog": {
        "header": {
          "closeButtonLabel": "Stäng"
        }
      },
      "dismissibleTooltip": {
        "dismissButtonLabel": "Avfärda"
      },
      "humanSize": {
        "units": {
          "byte": "{size, plural, one {byte} other {bytes} }",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "tb": "TB"
        }
      },
      "image": {
        "errorMessage": "Bilden kunde inte laddas."
      },
      "imageViewer": {
        "overlay": {
          "closeButtonLabel": "Stäng",
          "nextButtonLabel": "Nästa bild",
          "previousButtonLabel": "Föregående bild"
        }
      },
      "input": {
        "clearButtonLabel": "Rensa"
      },
      "interactiveImage": {
        "close": "Stäng",
        "errorMessage": "Bilden kunde inte laddas.",
        "fullscreen": "Fullskärm",
        "legendsMenuButtonLabel": "Färgförklaring",
        "menu": {
          "copyrightHeadingLabel": "Copyright",
          "legendsHeadingLabel": "Färgförklaring"
        },
        "nextStepButtonLabel": "Nästa steg",
        "previousStepButtonLabel": "Föregående steg",
        "showMarkerTip": "Klicka på punkten",
        "showStepTip": "Gå vidare till nästa steg",
        "showZoomTip": "Zooma i bilden",
        "stepProgressLabel": "Steg {step} av {totalSteps}",
        "zoomInButtonLabel": "Zooma in",
        "zoomOutButtonLabel": "Zooma ut"
      },
      "pagination": {
        "firstLabel": "Första",
        "lastLabel": "Sista",
        "nextLabel": "Nästa",
        "page": "Sida",
        "prevLabel": "Föregående",
        "totalLabel": "av {count}"
      },
      "select": {
        "label": "{count} alternativ valda"
      },
      "snackbar": {
        "snack": {
          "dismissButtonLabel": "Avfärda"
        }
      },
      "tag": {
        "addNewButtonLabel": "Lägg till",
        "emptyMessage": "Lägg till taggar",
        "removeButtonLabel": "Ta bort"
      },
      "upload": {
        "multipleLabel": "Ladda upp {format, select, image {bilder} video {videos} audio {ljudfiler} subtitle {undertexter} other {filer} } … ",
        "singleLabel": "Ladda upp {format, select, image {bild} video {video} audio {ljudfil} subtitle {undertext} other {fil} } … "
      },
      "uploadProgressModal": {
        "abortButtonLabel": "Avbryt",
        "message": "{uploadedSize} av {totalSize}",
        "title": "Laddar upp ..."
      },
      "video": {
        "subtitleLabel": {
          "en": "Engelska",
          "sv": "Svenska"
        }
      },
      "vrImage": {
        "button": "Visa 360-bild",
        "loading": "Laddar ..."
      }
    },
    "controllers": {
      "application": {
        "navigationText": "Navigeringen är klar",
        "skipText": "Hoppa till huvudinnehållet"
      },
      "master": {
        "courses": {
          "show": {
            "scopeMenuButtonItems": {
              "all": "Alla",
              "ended": "Avslutade",
              "upcoming": "Kommande",
              "visible": "Synliga"
            },
            "sortMenuButtonItems": {
              "createdAsc": "Äldst",
              "createdDesc": "Senast",
              "titleAsc": "Alfabetisk (A-Ö)",
              "titleDesc": "Alfabetisk (Ö-A)"
            }
          }
        }
      }
    },
    "detroit": {
      "canvasPresentor": {
        "pickColor": "Välj färg",
        "pickPresetColor": "Anpassad",
        "presetColors": "Färg",
        "removeObject": "Ta bort objekt",
        "restartCanvas": "Börja om",
        "restartConfirm": "Vill du börja om?",
        "saved": "Sparad",
        "saving": "Sparar"
      },
      "confirm": {
        "cancelLabel": "Avbryt",
        "okLabel": "OK"
      }
    },
    "helpers": {
      "fileTypeItems": {
        "audio": "Ljud",
        "blank": "Alla",
        "document": "Dokument",
        "image": "Bild",
        "video": "Video"
      },
      "languageItems": {
        "deDE": "Tyska",
        "enGB": "Engelska",
        "esES": "Spanska",
        "frFR": "Franska",
        "itIT": "Italienska",
        "svSE": "Svenska"
      },
      "wordlistTypeItems": {
        "concepts": "Begreppslista",
        "glossaries": "Gloslista"
      }
    },
    "models": {
      "answer": {
        "calendar": {
          "lastWeek": "Senaste veckan",
          "today": "Idag",
          "yesterday": "Igår"
        },
        "unknownDate": "Okänt datum"
      },
      "entity": {
        "fallbackTitles": {
          "archives": "Arkiv",
          "areas": "Arbetsområde",
          "assignments": "Arbetsuppgift",
          "books": "Bok",
          "books_external": "Bok",
          "collections": "Uppgiftsgrupp",
          "contents": "Innehåll",
          "exercises": "Övning",
          "folders": "Mapp",
          "glossaries": "Ordlista",
          "interactives": "Uppgifter",
          "materials": "Material",
          "missingType": "Innehåll",
          "root": "Rot",
          "sections": "Avsnitt",
          "studies": "Instudering"
        },
        "level": "Nivå {level}",
        "typeLabel": {
          "archives": "Arkiv",
          "areas": "Arbetsområde",
          "assignments": "Arbetsuppgift",
          "books": "Bok",
          "collections": "Uppgiftsgrupp",
          "contents": "Innehåll",
          "exercises": "Övning",
          "folders": "Mapp",
          "glossaries": "Ordlista",
          "interactives": "Uppgifter",
          "sections": "Avsnitt",
          "studies": "Instudering"
        }
      },
      "file": {
        "titleWithFallback": "Namnlös fil",
        "validations": {
          "name": {
            "presence": "Du måste ange ett namn."
          },
          "url": {
            "presence": "Du måste ange en länk.",
            "valid": "Texten är inte en giltig URL."
          }
        }
      },
      "material": {
        "typeLabel": {
          "articles": "Artikel",
          "attachments": "Fil",
          "audios": "Ljudfil",
          "embeds": "Inbäddat material",
          "guidances": "Lärarhandledning",
          "images": "Bild",
          "interactiveImages": "Interaktiv bild",
          "links": "Extern länk",
          "videos": "Film",
          "vrImages": "360-bild",
          "words": "Ord"
        }
      },
      "mission": {
        "validations": {
          "title": {
            "presence": "Du måste ange en titel"
          }
        }
      },
      "note": {
        "nameWithFallback": "Namnlös anteckning"
      },
      "teacherAssignment": {
        "titleWithFallback": "Namnlös uppgift"
      },
      "user": {
        "nameMissing": "Namn saknas"
      },
      "validations": {
        "presence": "Fältet kan inte vara tomt."
      },
      "wordlist": {
        "nameWithFallback": "Namnlös ordlista"
      }
    },
    "routes": {
      "404": {
        "content": "Länken du försöker nå finns inte hos oss. Detta kan bero på att sidan är borttagen eller att du har följt en länk som är felaktig.",
        "cta": "Gå till startsidan",
        "heading": "Sidan du letar efter finns inte",
        "notFound": "Sidan kunde inte hittas"
      },
      "master": {
        "about": "Information",
        "contents": {
          "assignments": {
            "close": "Stäng",
            "contentWorkspaceTitle": "Uppgifter",
            "index": {
              "importButtonLabel": "Från mitt arkiv",
              "newLinkLabel": "Ny uppgift"
            }
          },
          "categoryIsHidden": "textversion är nu dold för alla i uppdraget.",
          "everythingIsHidden": "Allt innehåll är dolt.",
          "notes": {
            "contentWorkspaceTitle": "Anteckningar",
            "index": {
              "currentNoResultAlertMessage": "Du har inga aktuella anteckningar till detta innehåll.",
              "importButtonLabel": "Från mitt arkiv",
              "newLinkLabel": "Ny anteckning",
              "relatedAccordionHeaderLabel": "Relaterade anteckningar"
            }
          },
          "wordlists": {
            "contentWorkspaceTitle": "Ordlistor",
            "index": {
              "currentNoResultAlertMessage": "Du har inga aktuella ordlistor till detta innehåll.",
              "importButtonLabel": "Från mitt arkiv",
              "newLinkLabel": "Ny ordlista",
              "relatedAccordionHeaderLabel": "Relaterade ordlistor"
            }
          }
        },
        "courses": {
          "show": {
            "audit": {
              "comments": {
                "pageTitle": "Kommentarer"
              },
              "inbox": {
                "inboxNote": "* Notera att du här endast får in svar till inlämningsuppgifter och ej till diagnoser, självrättande övningar och instuderingsfrågor.",
                "pageTitle": "Nytt"
              },
              "index": {
                "comments": {
                  "buttonText": "Granska kommentarer",
                  "description": "Här sammanställs alla kommentarer till uppgifter i kronologisk ordning. Du kan välja att se kommentarer för hela elevgruppen samtidigt eller för en enskild elev.",
                  "title": "Kommentarer till uppgifter"
                },
                "inbox": {
                  "buttonText": "Granska nytt",
                  "description": "Här samlas nya uppgiftssvar som eleverna har lämnat in. Listan uppdateras när nya svar lämnas in och när du granskar. Du kan välja att granska hela elevgruppen i kursen eller en enskild elev.",
                  "title": "Inkorg för inlämningsuppgifter"
                },
                "log": {
                  "buttonText": "Granska enskild elev",
                  "description": "Här kan du se vad en enskild elev i kursen har arbetat med. Elevens aktivitet och resultat visas i kronologisk ordning.",
                  "title": "Följ en enskild elev"
                },
                "missions": {
                  "auditButton": "Uppföljning",
                  "buttonText": "Granska uppdrag",
                  "description": "Här kan du se elevernas svar och resultat på alla uppgifter som ingår i ett enskilt uppdrag. Du kan välja att granska hela elevgruppen i uppdraget eller en enskild elev.",
                  "disabledText": "Det finns inga uppdrag att granska",
                  "noAudits": "Det finns inga övningar här.",
                  "title": "Resultat i uppdrag"
                },
                "notification": "{num, plural, =1 {1 ny} other {{num} nya}}",
                "overview": {
                  "buttonText": "Granska allt",
                  "description": "Här kan du se elevernas svar och resultat på samtliga uppgifter som ingår i kursen. Svaren är ordnade utifrån innehållets struktur. Du kan välja att granska hela elevgruppen i kursen eller en enskild elev.",
                  "title": "Alla uppgifter och övningar"
                },
                "pageTitle": "Uppföljning"
              },
              "log": {
                "noStudentSelected": "Ingen elev har valts.",
                "pageTitle": "Elever"
              },
              "missions": {
                "index": {
                  "audit": "Uppföljning",
                  "breadcrumb": {
                    "index": "Översikt",
                    "missions": "Uppdrag"
                  },
                  "deletedUser": "Borttagen användare",
                  "pageTitle": {
                    "missions": "Uppdrag"
                  }
                },
                "show": {
                  "invalidAlertMessage": "Det här innehållet kan inte visas. Det beror på att det ingår i en titel som inte hör till kursen.",
                  "pageTitle": "Allt",
                  "rejectedAlertMessage": "Den här delen av läromedlet kan inte visas. Det kan bero på att din licens har gått ut eller att innehållet har tagits bort i samband med en revidering."
                }
              },
              "overview": {
                "pageTitle": "Allt",
                "types": {
                  "diagnosis": "Diagnoser",
                  "standard": "Uppgifter",
                  "teacher_assignments": "Läraruppgifter"
                }
              },
              "pageTitle": "Uppföljning"
            },
            "books": {
              "booksPage": {
                "booksWarning": "Din kurs innehåller {numBooks} titlar. Det kan vara svårt att få en överblick över materialet och dina elevers resultat med så många. Kursen kommer ändå att fungera, men överväg att dölja vissa titlar inuti kursen (du kan när som helst välja att visa dem igen). Ett annat alternativt är att dela upp titlarna i flera kurser, till exempel en kurs per ämne.",
                "heading": "Innehåll"
              },
              "noEntities": "Kursen har inget innehåll. Gå till administrera kurs för att välja vilka titlar som ska ingå.",
              "noEntitiesStudent": "Kursen har inget innehåll.",
              "pageTitle": "Innehåll",
              "showBooksForStudentsButton": "Visa titlar på översikten"
            },
            "index": {
              "addNewMissionButtonLabel": "Lägg till uppdrag",
              "booksHeading": "Innehåll",
              "createMissionButtonLabel": "Skapa uppdrag",
              "hideBooksButton": "Dölj titlar på översikten",
              "missionIntroCardHeadingLabel": "{isTeacher, select, true {Dela ut uppdrag} false {Din lärare har inte delat ut något än} }",
              "missionIntroCardParagraphMessage": "{isTeacher, select, true {Med uppdrag kan du dela ut innehåll till dina kursdeltagare. I ett uppdrag kan du dela ut ett eller flera innehåll från produkternas material, men också eget material så som filer eller länkar. Innehållet kan sedan anpassas och du kan enkelt följa upp vad dina elever har arbetat med.} false {Här visas de uppdrag som din lärare har delat ut i kursen. Just nu finns det inga utdelade uppdrag.} }",
              "missionsHeadingLabel": "Uppdrag",
              "pageTitle": "Översikt",
              "readMoreAboutCoursesButton": "Läs mer om hur du använder kurser",
              "saveSortOrder": "Klar",
              "shortcutsHeadingLabel": "Genvägar",
              "sort": "Ordna",
              "welcomeContent": "<p class=\"paragraph\">Det här är kursens översiktssida som du delar med dina kursdeltagare. Här kan du bygga upp en egen planering och dela ut innehåll.</p> <p class=\"paragraph text-bold\">I kursens meny finns dina verktyg:</p><ul class=\"paragraph unordered-list\"><li class=\"mb1\">Kursens titlar i sin helhet finns tillgängliga under Innehåll. Du kan välja om de ska visas på översikten eller inte.</li><li class=\"my1\">Med hjälp av Uppdrag kan du aktivera dina kursdeltagare genom att dela ut innehåll varvat med eget material.</li><li class=\"my1\">Du kan skapa genvägar till innehåll, externa sidor eller bifogade filer.</li><li class=\"my1\">Du kan se och kommentera elevernas svar på uppgifter under Uppföljning.</li><li class=\"mt1\">Via Inställningar kan du göra ytterligare inställningar till kursen.</li></ul>",
              "welcomeContinueButton": "Fortsätt",
              "welcomeHeading": "Välkommen till din nya kurs: {name}"
            },
            "missions": {
              "addNewButtonLabel": "Lägg till",
              "headingLabel": "Planera uppdrag",
              "noResultMessage": "{scope, select, all {Inga uppdrag har skapats än.} visible {Inga syngliga uppdrag hittades.} upcoming {Inga kommande uppdrag hittades.} ended {Inga avslutade uppdrag hittades.} other {Inga uppdrag hittades.} }",
              "pageTitle": "Planera uppdrag",
              "scopeLabel": "Visa:",
              "scopeMenuButtonItems": {
                "all": "Alla",
                "ended": "Avslutade",
                "upcoming": "Kommande",
                "visible": "Synliga"
              },
              "sortLabel": "Sortering:",
              "sortMenuButtonItems": {
                "createdAsc": "Äldst",
                "createdDesc": "Senast",
                "titleAsc": "Alfabetisk (A-Ö)",
                "titleDesc": "Alfabetisk (Ö-A)"
              }
            },
            "myResults": {
              "title": "Min aktivitet"
            },
            "settings": {
              "title": "Inställningar"
            }
          }
        },
        "forbidden": {
          "content": "Efter lite grävande har vi upptäckt att ditt konto saknar licens till den produkt som länken leder till, vilket betyder att den inte kan visas för dig.",
          "cta": "Gå till startsidan",
          "heading": "Licens saknas"
        },
        "guidance": "Lärarhandledning",
        "interactives": {
          "diagnosis": {
            "done": {
              "audit": {
                "attemptCompleted": "Genomfört försök",
                "goBack": "Tillbaka"
              }
            },
            "show": {
              "end": "Avsluta diagnos"
            }
          }
        },
        "missionCustomizer": "Anpassa innehållet",
        "products": {
          "index": {
            "pageTitle": "Översikt"
          },
          "myResults": {
            "title": "Min aktivitet"
          }
        }
      }
    },
    "services": {
      "contextHelper": {
        "pageTitle": {
          "error": "Fel",
          "homescreen": "Start",
          "notFound": "Sidan kunde inte hittas"
        }
      },
      "customContentDispatcher": {
        "delete": {
          "confirm": "Är du säker på att du vill ta bort det här innehållet?",
          "snackbar": {
            "error": "Innehållet kunde inte tas bort. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Innehållet är borttaget."
          }
        },
        "save": {
          "snackbar": {
            "error": "Innehållet kunde inte sparas. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Innehållet har sparats."
          }
        }
      },
      "diagnosis": {
        "confirmTurnInMessage": "Det här avslutar diagnosen och lämnar in resultatet till din lärare.",
        "confirmTurnInTitle": "Vill du avsluta diagnosen?"
      },
      "fileDispatcher": {
        "delete": {
          "confirm": "Är du säker på att du vill ta bort den här filen?",
          "snackbar": {
            "error": "Filen kunde inte tas bort. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Filen är borttagen."
          }
        },
        "deleteMany": {
          "confirm": "{count, plural, one {Är du säker på att du vill ta bort den här filen?} other {Är du säker på att du vill ta bort de här filerna?} } ",
          "snackbar": {
            "error": "{count, plural, one {Filen kunde inte tas bort.} other {Filerna kunde inte tas bort.} } Försök igen eller kontakta kundservice om problemet kvarstår. ",
            "success": "{count, plural, one {Filen är borttagen.} other {Filerna är borttagna.} } "
          }
        },
        "save": {
          "snackbar": {
            "error": "Filen kunde inte sparas. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Filen har sparats."
          }
        },
        "upload": {
          "snackbar": {
            "error": "Filen kunde inte laddas upp. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Filen är uppladdad."
          }
        }
      },
      "licenseDispatcher": {
        "connect": {
          "snackbar": {
            "error": "Licensen kunde inte aktiverats. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Licensen har aktiverats."
          }
        },
        "tryProduct": {
          "snackbar": {
            "error": "Provperoiden kunde inte aktiverats. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Provperoiden har aktiverats"
          }
        }
      },
      "missionDispatcher": {
        "activateMany": {
          "snackbar": {
            "error": "{count, plural, one {Uppdraget kunde inte läggas till.} other {Uppdragen kunde inte läggas till.} } Försök igen eller kontakta kundsupport om problemet kvarstår.",
            "success": "{count, plural, one {Uppdraget har lagts till.} other {Uppdragen har lagts till.} }"
          }
        },
        "cancelSchedule": {
          "snackbar": {
            "error": "Uppdraget kunde inte sparas. Försök igen eller kontakta kundsupport om problemet kvarstår.",
            "success": "{wasOngoing, select, true {Uppdragets schemaläggning har avbrutits och uppdraget är dolt på översikten.} false {Uppdragets schemaläggning har avbrutits.} }"
          }
        },
        "copyManyToCourse": {
          "snackbar": {
            "error": "{count, plural, one {Uppdraget kunde inte kopieras.} other {Uppdragen kunde inte kopieras.} } Försök igen eller kontakta kundsupport om problemet kvarstår.",
            "success": "{count, plural, one {Uppdraget har kopierats.} other {Uppdragen har kopierats.} }"
          }
        },
        "copyToManyCourses": {
          "snackbar": {
            "error": "Uppdraget kunde inte kopieras. Försök igen eller kontakta kundsupport om problemet kvarstår.",
            "success": "Uppdraget har kopierats."
          }
        },
        "delete": {
          "confirm": "Är du säker på att du vill ta bort det här uppdraget?",
          "snackbar": {
            "error": "Uppdraget kunde inte tas bort. Försök igen eller kontakta kundsupport om problemet kvarstår.",
            "success": "Uppdraget är borttaget."
          }
        },
        "hide": {
          "snackbar": {
            "error": "Uppdraget kunde inte sparas. Försök igen eller kontakta kundsupport om problemet kvarstår.",
            "success": "{fromOverview, select, true {{wasOngoing, select, true {Uppdragets schemaläggning har nu avbrutits och uppdraget är dolt på översikten. För att redigera uppdraget igen går du till fliken Planera uppdrag.} false {Uppdraget är dolt på översikten. För att redigera uppdraget igen går du till fliken Planera uppdrag.} }} false {{wasOngoing, select, true {Uppdragets schemaläggning har nu avbrutits och uppdraget är dolt på översikten.} false {Uppdraget är dolt på översikten.} }} }"
          }
        },
        "save": {
          "snackbar": {
            "error": "Uppdraget kunde inte sparas. Försök igen eller kontakta kundsupport om problemet kvarstår.",
            "success": "{fromOverview, select, true {{state, select, idle {Uppdraget har sparats. Eftersom det är dolt så syns det inte på översikten. För att redigera uppdraget igen går du till fliken Planera uppdrag.} scheduled {Uppdraget har sparats. Eftersom det är schemalagt så syns det inte på översikten än. För att redigera uppdraget igen går du till fliken Planera uppdrag.} other {Uppdraget har sparats.} }} false {Uppdraget har sparats.} }"
          }
        },
        "schedule": {
          "snackbar": {
            "error": "Uppdraget kunde inte sparas. Försök igen eller kontakta kundsupport om problemet kvarstår.",
            "success": "{isOngoing, select, true {Uppdraget har schemalagts och visas på översikten.} false {Uppdraget har schemalagts.} }"
          }
        },
        "toggleActivation": {
          "snackbar": {
            "error": "Uppdraget kunde inte sparas. Försök igen eller kontakta kundsupport om problemet kvarstår.",
            "success": "{visible, select, true {Uppdraget visas på översikten.} false {Uppdraget är dolt på översikten.} }"
          }
        }
      },
      "noteDispatcher": {
        "delete": {
          "confirm": "Är du säker på att du vill ta bort den här anteckningen?",
          "snackbar": {
            "error": "Anteckningen kunde inte tas bort. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Anteckningen är borttagen."
          }
        },
        "deleteMany": {
          "confirm": "{count, plural, one {Är du säker på att du vill ta bort den här anteckningen?} other {Är du säker på att du vill ta bort de här anteckningarna?} } ",
          "snackbar": {
            "error": "{count, plural, one {Anteckningen kunde inte tas bort.} other {Anteckningarna kunde inte tas bort.} } Försök igen eller kontakta kundservice om problemet kvarstår. ",
            "success": "{count, plural, one {Anteckningen är borttagen.} other {Anteckningarna är borttagna.} } "
          }
        },
        "link": {
          "snackbar": {
            "error": "Anteckningen kunde inte kopplas till innehållet. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Anteckningen har kopplats till innehållet."
          }
        },
        "linkMany": {
          "snackbar": {
            "error": "{count, plural, one {Anteckningen kunde inte kopplas till innehållet.} other {Anteckningarna kunde inte kopplas till innehållet.} } Försök igen eller kontakta kundservice om problemet kvarstår. ",
            "success": "{count, plural, one {Anteckningen har kopplats till innehållet.} other {Anteckningarna har kopplats till innehållet.} } "
          }
        },
        "save": {
          "snackbar": {
            "error": "Anteckningen kunde inte sparas. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Anteckningen har sparats."
          }
        },
        "unlink": {
          "confirm": "Är du säker på att du vill koppla bort den här anteckningen från innehållet?",
          "snackbar": {
            "error": "Anteckningen kunde inte kopplas bort från innehållet. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Anteckningen är bortkopplad från innehållet."
          }
        }
      },
      "teacherAssignmentDispatcher": {
        "delete": {
          "confirm": "Är du säker på att du vill ta bort den här läraruppgiften? Om dina elever har svarat på uppgiften kommer deras svar att tas bort.",
          "snackbar": {
            "error": "Uppgiften kunde inte tas bort. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Uppgiften är borttagen."
          }
        },
        "deleteMany": {
          "confirm": "{count, plural, one {Är du säker på att du vill ta bort den här läraruppgiften? Om dina elever har svarat på uppgiften kommer deras svar att tas bort.} other {Är du säker på att du vill ta bort de här läraruppgifterna? Om dina elever har svarat på någon av uppgifteerna kommer deras svar att tas bort.} } ",
          "snackbar": {
            "error": "{count, plural, one {Uppgiften kunde inte tas bort.} other {Uppgifterna kunde inte tas bort.} } Försök igen eller kontakta kundservice om problemet kvarstår. ",
            "success": "{count, plural, one {Uppgiften är borttagen.} other {Uppgifterna är borttagen.} } "
          }
        },
        "link": {
          "snackbar": {
            "error": "Uppgiften kunde inte kopplas till innehållet. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Uppgiften har kopplats till innehållet."
          }
        },
        "linkMany": {
          "snackbar": {
            "error": "{count, plural, one {Uppgiften kunde inte kopplas till innehållet.} other {Uppgifterna kunde inte kopplas till innehållet.} } Försök igen eller kontakta kundservice om problemet kvarstår. ",
            "success": "{count, plural, one {Uppgiften har kopplats till innehållet.} other {Uppgifterna har kopplats till innehållet.} } "
          }
        },
        "save": {
          "snackbar": {
            "error": "Uppgiften kunde inte sparas. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Uppgiften har sparats."
          }
        },
        "unlink": {
          "confirm": "Är du säker på att du vill koppla bort den här läraruppgiften från innehållet?",
          "snackbar": {
            "error": "Uppgiften kunde inte kopplas bort från innehållet. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Uppgiften är bortkopplad från innehållet."
          }
        }
      },
      "wordDispatcher": {
        "delete": {
          "confirm": "Är du säker på att du vill ta bort det här ordet?",
          "snackbar": {
            "error": "Ordet kunde inte tas bort. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Ordet är borttaget."
          }
        },
        "save": {
          "snackbar": {
            "error": "Ordet kunde inte sparas. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Ordet har sparats."
          }
        }
      },
      "wordlistDispatcher": {
        "delete": {
          "confirm": "Är du säker på att du vill ta bort den här ordlistan?",
          "snackbar": {
            "error": "Ordlistan kunde inte tas bort. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Ordlistan är borttagen."
          }
        },
        "deleteMany": {
          "confirm": "{count, plural, one {Är du säker på att du vill ta bort den här ordlistan?} other {Är du säker på att du vill ta bort de här ordlistorna?} } ",
          "snackbar": {
            "error": "{count, plural, one {Ordlistan kunde inte tas bort.} other {Ordlistorna kunde inte tas bort.} } Försök igen eller kontakta kundservice om problemet kvarstår. ",
            "success": "{count, plural, one {Ordlistan är borttagen.} other {Ordlistorna är borttagna.} } "
          }
        },
        "duplicate": {
          "snackbar": {
            "error": "Ordlistan kunde inte kopieras. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Ordlistan har kopierats."
          }
        },
        "link": {
          "snackbar": {
            "error": "Ordlistan kunde inte kopplas till innehållet. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Ordlistan har kopplats till innehållet."
          }
        },
        "linkMany": {
          "snackbar": {
            "error": "{count, plural, one {Ordlistan kunde inte kopplas till innehållet.} other {Ordlistorna kunde inte kopplas till innehållet.} } Försök igen eller kontakta kundservice om problemet kvarstår. ",
            "success": "{count, plural, one {Ordlistan har kopplats till innehållet.} other {Ordlistorna har kopplats till innehållet.} } "
          }
        },
        "save": {
          "snackbar": {
            "error": "Ordlistan kunde inte sparas. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Ordlistan har sparats."
          }
        },
        "unlink": {
          "confirm": "Är du säker på att du vill koppla bort den här ordlistan från innehållet?",
          "snackbar": {
            "error": "Ordlistan kunde inte kopplas bort från innehållet. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Ordlistan är bortkopplad från innehållet."
          }
        }
      }
    },
    "utils": {
      "generateFallbackTitle": {
        "archives": "Arkiv",
        "areas": "Arbetsområde",
        "assignments": "Arbetsuppgift",
        "books": "Bok",
        "books_external": "Bok",
        "collections": "Uppgiftsgrupp",
        "contents": "Innehåll",
        "exercises": "Övning",
        "folders": "Mapp",
        "glossaries": "Ordlista",
        "interactives": "Uppgifter",
        "materials": "Material",
        "missingType": "Innehåll",
        "root": "Rot",
        "sections": "Avsnitt",
        "studies": "Instudering"
      },
      "licenseInfo": {
        "expired": "Gick ut {date}",
        "expires": "Går ut {date}",
        "externalExpired": "Extern licens gick ut {date}",
        "externalExpires": "Extern licens går ut {date}",
        "freeExpires": "Testlicens går ut {date}",
        "freeUsed": "Testlicens förbrukad",
        "missingLicense": "Saknar licens",
        "personalExpired": "Personlig licens gick ut {date}",
        "personalExpires": "Personlig licens går ut {date}",
        "personalValidThru": "Personlig licens giltig till {date}",
        "ready": "Klar att starta",
        "started": "Startad",
        "validThru": "Giltig till {date}"
      }
    }
  }]];
  _exports.default = _default;
});