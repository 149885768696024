import { service } from '@ember/service';

import ApplicationAdapter from './application';

export default class ProductAdapter extends ApplicationAdapter {
  namespace = 'api/v1/public';

  @service session;

  findHasMany(store, snapshot, url, relationship) {
    return super.findHasMany(...arguments).then((response) => {
      if (relationship.name === 'books' && this.session.inPreviewMode) {
        // When previewing the books data from product API sometimes overwrites
        // the books data from the entity API. The product API cannot handle
        // preview mode and therefore always servers the published versions of
        // the books. The following code makes sure that books that are already
        // present in the store are not overwritten.
        // For more info see DINO-4594

        response.data.map((book, index) => {
          const record = store.peekRecord('entity', book.id);

          if (record) {
            response.data[index] = record.serialize({ includeId: true }).data;
          }
        });
      }

      return response;
    });
  }
}
