import Component from '@ember/component';
import { service } from '@ember/service';

export default Component.extend({
  // SETUP

  colorTheme: service(),

  classNames: ['note-detail'],

  // PARAMS

  note: null,

  indexRouteName: null,

  unlinkable: false,

  hasBreadcrumb: true,
});
