import Component from '@ember/component';

export default Component.extend({
  contents: null,

  visibleTagGroup: null,

  classNames: ['grid'],

  hideEditions: false,
});
