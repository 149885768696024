import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"editor-assignment {{this.presetClass}}\" ...attributes>\n  <div class=\"editor-wrapper\" {{did-update this.inputChanged this.input}}>\n    {{#if this.ready}}\n      <XEditor\n        @value={{this.input}}\n        @capabilities={{this.editorCapabilities}}\n        @toolbarOptions={{this.editorToolbarOptions}}\n        @theme={{this.colorTheme.currentTheme}}\n        @readOnly={{@disabled}}\n        @onChange={{this.updateText}}\n        {{ref (set this \"editorElement\")}}\n      />\n    {{/if}}\n  </div>\n\n  {{#if this.isIncomplete}}\n    <div class=\"incomplete-message\">\n      {{t \"components.exerciseView.assignment.editor.incompleteMessage\"}}\n    </div>\n  {{/if}}\n</div>", {"contents":"<div class=\"editor-assignment {{this.presetClass}}\" ...attributes>\n  <div class=\"editor-wrapper\" {{did-update this.inputChanged this.input}}>\n    {{#if this.ready}}\n      <XEditor\n        @value={{this.input}}\n        @capabilities={{this.editorCapabilities}}\n        @toolbarOptions={{this.editorToolbarOptions}}\n        @theme={{this.colorTheme.currentTheme}}\n        @readOnly={{@disabled}}\n        @onChange={{this.updateText}}\n        {{ref (set this \"editorElement\")}}\n      />\n    {{/if}}\n  </div>\n\n  {{#if this.isIncomplete}}\n    <div class=\"incomplete-message\">\n      {{t \"components.exerciseView.assignment.editor.incompleteMessage\"}}\n    </div>\n  {{/if}}\n</div>","moduleName":"babel/components/exercise-view/assignment/editor/index.hbs","parseOptions":{"srcName":"babel/components/exercise-view/assignment/editor/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

export default class ExerciseViewAssignmentEditorComponent extends Component {
  /**
   * Arguments:
   * assignment (object)
   * canHaveAttachments (bool)
   * assignmentAnswer (object)
   * answer (object)
   * handleOnChange (function)
   * showValidation (bool)
   * showKey (bool)
   * disabled (bool)
   * showSuggestion (bool)
   * incomplete (bool)
   * image (object)
   */

  @service colorTheme;

  editorElement = null;

  get input() {
    return this.args.assignmentAnswer?.input;
  }

  get ready() {
    return (
      (this.input !== undefined && this.input !== false) || this.args.disabled
    );
  }

  get isIncomplete() {
    if (this.args.incomplete) {
      return (
        isEmpty(this.input) &&
        this.args.canHaveAttachments &&
        this.args.answer?.files?.length === this.args.numberOfMediaAssignments
      );
    }

    return false;
  }

  get presetClass() {
    return ['preset', this.args.assignment?.settings?.preset].join('-');
  }

  @cached
  get editorToolbarOptions() {
    const preset = this.args.assignment?.settings?.preset;

    switch (preset) {
      case 'minimal':
        return {
          textFormats: false,
          headings: false,
          lists: false,
          tables: false,
        };
      case 'basic':
        return {
          textFormats: ['bold', 'italic', 'underline'],
          headings: false,
          lists: false,
          tables: false,
        };
      case 'balanced':
        return {
          textFormats: ['bold', 'italic', 'underline'],
          headings: [1, 2, 3],
          lists: true,
          tables: true,
        };

      default:
        return {
          textFormats: [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
          ],
          headings: [1, 2, 3],
          lists: true,
          tables: true,
        };
    }
  }

  @cached
  get editorCapabilities() {
    return {
      textFormats: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'subscript',
        'superscript',
      ],
      headings: [1, 2, 3],
      lists: true,
      tables: true,
    };
  }

  @action
  updateText(value) {
    if (!this.args.disabled) {
      if (this.args.assignmentAnswer) {
        this.args.assignmentAnswer.input = value;
      }

      this.args.handleOnChange?.(value);
    }
  }

  @action
  inputChanged() {
    // Update editor version if input is cleared or
    // equal to pattern / answer template
    if (
      this.input === this.args.assignment?.content?.pattern ||
      this.input == null ||
      this.input == undefined ||
      this.input == ''
    ) {
      this.editorElement.setValueFromHtml('');
    }
  }
}
