import EmberObject, { computed } from '@ember/object';
import { alias, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import DS from 'ember-data';
import { all, hash, resolve } from 'rsvp';

import Model from './model';

const { attr, belongsTo, hasMany, PromiseArray, PromiseObject } = DS;

function createPromiseObject(promise) {
  return PromiseObject.create({ promise });
}

function createPromiseArray(promise) {
  return PromiseArray.create({ promise });
}

const SV_SE = 'sv_SE';

const ALLOWED_TYPES_FOR_HIDE_SORT_NUMBERS = [
  'areas',
  'archives',
  'contents',
  'interactives',
];

// The entire path must exist for each file
const DEFAULT_IMAGES = [
  { name: 'archives', path: '/assets/images/defaults/archives.svg' },
  { name: 'areas', path: '/assets/images/defaults/areas.svg' },
  {
    name: 'contents-chapter',
    path: '/assets/images/defaults/contents-chapter.svg',
  },
  {
    name: 'contents-story',
    path: '/assets/images/defaults/contents-story.svg',
  },
  {
    name: 'contents-workflow',
    path: '/assets/images/defaults/contents-workflow.svg',
  },
  {
    name: 'interactives',
    path: '/assets/images/defaults/interactives.svg',
  },
];

export default Model.extend({
  session: service(),

  intl: service(),

  store: service(),

  contextHelper: service(),

  // ATTRIBUTES

  title: attr(),

  heading: attr(),

  body: attr(),

  type: attr(),

  breadcrumb: attr(),

  tags: attr(),

  metadata: attr(),

  template: attr(),

  sort: attr(),

  sortBy: attr('string'),

  url: attr('string'),

  featured_image: attr('string'),

  edition: attr(),

  published: attr('date'),

  latestUpdated: attr('date'),

  questionHtml: attr('string'),

  document_id: attr('string'),

  // TODO Remove:
  book_id: attr(),
  parent_id: attr(),
  parent_type: attr(),
  children_ids: attr(),

  space: attr(),

  meta: attr(),

  childrenSum: attr('number'),

  // RELATIONSHIPS

  parent: belongsTo('entity', { async: true, inverse: null }),

  book: belongsTo('entity', { async: true, inverse: null }),

  ancestors: hasMany('entity', { async: true, inverse: null }),

  descendents: hasMany('entity', { async: true, inverse: null }),

  children: hasMany('entity', { async: true, inverse: null }),

  materials: hasMany('material', { async: true, inverse: null }),

  guidances: hasMany('material', { async: true, inverse: null }),

  tagFilters: hasMany('material', { async: true, inverse: null }),

  tagGroups: hasMany('tag-group', { async: true, inverse: null }),

  editors: hasMany('editor', { async: true, inverse: null }),

  contenteditor: belongsTo('editor', { async: true, inverse: null }), // TODO Rename to content editor

  coverImage: belongsTo('material', { async: false, inverse: null }),

  logo: belongsTo('material', { async: false, inverse: null }),

  image: belongsTo('material', { async: false, inverse: null }),

  audio: belongsTo('material', { async: false, inverse: null }),

  cat: belongsTo('category', { async: false, inverse: null }), // TODO: Rename to category.

  category: alias('cat'),

  categories: hasMany('category', { async: true, inverse: null }),

  answer: belongsTo('answer', { inverse: null, async: false }),

  // Used for temporarely created entities by user
  owner: belongsTo('user', { inverse: null, async: false }),

  products: hasMany('product', { async: true, inverse: null }),

  isTeacherCollection: attr('boolean'),

  isTeacherAssignment: attr('boolean'),

  teacherAssignment: belongsTo('teacher-assignment', {
    async: false,
    inverse: null,
  }),

  selectedMaterial: belongsTo('material', { async: true, inverse: null }),

  // PROPERTIES

  footnotes: readOnly('contenteditor.footnotes'),

  hideSortNumbers: computed(
    'type',
    'body.hide_sort_numbers',
    'book.hideSortNumbers',
    function () {
      if (this.type === 'books') {
        return Boolean(this.body?.hide_sort_numbers);
      } else if (ALLOWED_TYPES_FOR_HIDE_SORT_NUMBERS.includes(this.type)) {
        // get is needed here since book is a proxy
        return Boolean(this.get('book.hideSortNumbers'));
      } else {
        return false;
      }
    }
  ),

  activeEntitySettings: computed(
    'contextHelper.{inMission,activeCustomContent.includedEntitySettings.[]}',
    'id',
    function () {
      if (
        this.contextHelper.inMission &&
        this.contextHelper.activeCustomContent?.includedEntitySettings
      ) {
        return this.contextHelper.activeCustomContent.includedEntitySettings.findBy(
          'entityId',
          this.id
        );
      }

      return null;
    }
  ),

  pointsRequired: computed('body.points_required', function () {
    return this.body?.points_required ?? 0;
  }),

  timeAllowed: computed(
    'activeEntitySettings.timeAllowed',
    'body.time_allowed',
    function () {
      if (this.activeEntitySettings?.timeAllowed) {
        return this.activeEntitySettings.timeAllowed;
      }

      return this.body?.time_allowed ?? 30;
    }
  ),

  allowedAttempts: computed(
    'activeEntitySettings.numberOfTries',
    'body.num_tries',
    function () {
      if (this.activeEntitySettings?.numberOfTries) {
        return this.activeEntitySettings.numberOfTries;
      }

      return this.body?.num_tries ?? 1;
    }
  ),

  // NOTE This should be a relation.
  product: computed(
    'products.[]',
    'session.user.licenseProducts.[]',
    function () {
      return createPromiseObject(
        all([this.products, this.get('session.user.licenseProducts')]).then(
          ([products, licenseProducts]) => {
            const avialableProducts = products.filter((product) =>
              licenseProducts.mapBy('content').includes(product)
            );

            const firstProduct = avialableProducts.get('firstObject');

            if (firstProduct) {
              return firstProduct;
            }

            return null;
          }
        )
      );
    }
  ),

  // NOTE This should be a relation.
  video: computed('body.video', 'store', function () {
    const videoId = this.get('body.video');

    if (videoId) {
      return createPromiseObject(this.store.findRecord('material', videoId));
    }

    return createPromiseObject(resolve(null));
  }),

  typeLabel: computed('type', 'intl.locale', function () {
    if (!this.type) {
      return null;
    }

    return this.intl.t(
      ['models', 'entity', 'typeLabel', camelize(this.type)].join('.')
    );
  }),

  canHaveCustomizableChildren: computed(
    'schema',
    'template',
    'type',
    function () {
      const { type, template } = this.getProperties('type', 'template');

      switch (type) {
        case 'collections':
          return ['standard', 'flow'].includes(template);
        case 'contents':
          return ['chapter', 'story', 'workflow'].includes(template);
        case 'sections':
          return true;
        case 'interactives':
          return true;
        default:
          return false;
      }
    }
  ),

  defaultImage: computed('type', 'template', function () {
    const templateObj = DEFAULT_IMAGES.findBy(
      'name',
      `${this.type}-${this.template}`
    );
    const typeObj = DEFAULT_IMAGES.findBy('name', this.type);

    if (templateObj && templateObj.path) {
      return templateObj.path;
    } else if (typeObj && typeObj.path) {
      return typeObj.path;
    } else {
      return null;
    }
  }),

  featuredImage: computed(
    'created',
    'featured_image',
    'image.updated',
    'defaultImage',
    'updated',
    function () {
      if (this.featured_image) {
        let updated = this.updated;

        if (this.get('image.updated')) {
          updated = this.get('image.updated');
        }

        if (!updated) {
          updated = this.created;
        }

        return (
          this.featured_image + '?hash=' + this.generateHash(updated.toString())
        );
      }

      return this.defaultImage;
    }
  ),

  level: computed('body.level', 'book.body.levels', 'intl', function () {
    let level = this.get('body.level');

    if (typeof level !== 'number' || level < 1 || level > 3) {
      level = null;
    }

    if (level === null) {
      return;
    }

    const label = (
      (this.get('book.body.levels') || []).findBy('value', level) || {}
    ).label;

    return label || this.intl.t('models.entity.level', { level });
  }),

  copyrightedMaterialEntityGroups: computed(
    'materials.[]',
    'contenteditor',
    'children.[]',
    function () {
      const groups = [];

      const addMaterials = async (entity, model, sort) => {
        if (model) {
          const ids = model.hasMany('materials').ids();
          const materials = [];

          await all(
            ids.map(async (id) => {
              let material;

              try {
                material = await this.store.findRecord('material', id);
              } catch (error) {
                console.error(error);
              }

              if (material?.metadata?.copyright) {
                materials.push(material);
              }
            })
          );

          groups.addObjects(
            materials.map((material) => {
              return {
                entity,
                material,
                sort,
              };
            })
          );
        }
      };

      const addEditors = async (entity, sort) => {
        if (entity) {
          const ids = [];

          if (entity.belongsTo('contenteditor').id()) {
            ids.push(entity.belongsTo('contenteditor').id());
          }

          entity.body?.exercise?.assignments?.forEach((assignment) => {
            if (assignment?.content?.solution_editor_id) {
              ids.push(assignment.content.solution_editor_id);
            }
          });

          await all(
            ids.map(async (id) => {
              let editor;

              try {
                editor = await this.store.findRecord('editor', id);
              } catch (error) {
                console.error(error);
              }

              if (editor) {
                await addMaterials(entity, editor, sort);
              }
            })
          );
        }
      };

      const addChildren = async (entity) => {
        if (['contents', 'interactives'].includes(entity?.type)) {
          const ids = entity.hasMany('children').ids();
          const children = [];

          await all(
            ids.map(async (id) => {
              let child;

              try {
                child = await this.store.findRecord('entity', id);
              } catch (error) {
                console.error(error);
              }

              if (child) {
                children.push(child);
              }
            })
          );

          if (entity.type === 'contents') {
            return all(children.map((x) => addEntity(x, entity.sort)));
          } else {
            return all(children.map((x) => addMaterials(x, x, x.sort)));
          }
        }
      };

      const addEntity = (entity, sort) => {
        return all([
          addMaterials(entity, entity, entity.sort + sort),
          addEditors(entity, entity.sort + sort),
          addChildren(entity),
        ]);
      };

      return PromiseArray.create({
        promise: addEntity(this, 0).then(() => groups.sortBy('sort')),
      });
    }
  ),

  isCarousel: computed('type', 'template', function () {
    return this.type === 'collections' && this.template === 'carousel';
  }),

  isDiagnosis: computed('type', 'template', function () {
    return this.type === 'collections' && this.template === 'diagnosis_part';
  }),

  isFlow: computed('type', 'template', function () {
    return this.type === 'collections' && this.template === 'flow';
  }),

  isStandard: computed('type', 'template', function () {
    return this.type === 'collections' && this.template === 'standard';
  }),

  /*
  scoreMax: computed('body.exercise.assignments.[]', function() {
    const assignments = this.body.exercise && this.body.exercise.assignments;

    if (!assignments) {
      return;
    }

    return MULTIPLE_ASSIGNMENTS.includes(assignments.get('firstObject.template'))
      ? assignments.get('firstObject.content.objects.length')
      : assignments.length;
  }),
  */

  scoreMax: 1,

  versions: computed('children.[]', function () {
    const promise = this.children.then((children) => {
      return all(children.getEach('contenteditor')).then(() => {
        const versions = [];

        const categories = children
          .filterBy('type', 'sections')
          .uniqBy('category');

        categories.map((category) => {
          const version = EmberObject.create({
            version: category.get('category.name'),
            sections: children
              .filter(
                (x) =>
                  x.get('type') === 'sections' &&
                  x.get('category.id') === category.get('category.id')
              )
              .get('length'),
            words: 0,
            minutesToRead: 0,
          });

          children.map((child) => {
            if (
              child.get('type') === 'sections' &&
              child.get('category') === category.get('category')
            ) {
              child.get('contenteditor').then((editor) => {
                if (child.get('heading') && child.get('heading').length > 0) {
                  version.incrementProperty(
                    'words',
                    child.get('heading').split(' ').length
                  );
                }

                if (editor.get('metadata') && editor.get('metadata.words')) {
                  version.incrementProperty(
                    'words',
                    editor.get('metadata.words')
                  );
                }

                version.minutesToRead = Math.max(
                  1,
                  Math.ceil(parseInt(version.words) / 120)
                );
              });
            }
          });

          versions.addObject(version);
        });

        return versions;
      });
    });

    return createPromiseArray(promise);
  }),

  sectionRecordedSpeech: computed('body.section_recorded_speech', function () {
    if (this.get('body.section_recorded_speech') === true) {
      return true;
    } else {
      return false;
    }
  }),

  backgroundType: alias('body.background_type'),

  backgroundColor: alias('body.background_color'),

  textColor: alias('body.text_color'),

  adaptUiFor: alias('body.adapt_ui_for'),

  // METHODS

  transformToWordlist() {
    if (this.type === 'glossaries') {
      const store = this.store;

      return all([this.book, this.parent]).then(([book, parent]) => {
        let wordlist = store.peekRecord('wordlist', this.id);

        if (!wordlist)
          wordlist = store.createRecord('wordlist', { id: this.id });

        wordlist.name = this.title;
        wordlist.language =
          this.body?.language || book?.body?.language || SV_SE;
        wordlist.type = this.template || 'glossaries';
        wordlist.category = this.category;
        wordlist.public = true;
        wordlist.created = null;
        wordlist.set('isTransformed', true);
        wordlist.entity = this;
        wordlist.books = [book];
        wordlist.contents = [parent];

        return this.materials
          .then((materials) => {
            const words = materials.map((material) => {
              const wordId = `${wordlist.id}-${material.id}`;
              let word = store.peekRecord('word', wordId);

              if (!word) word = store.createRecord('word', { id: wordId });

              word.sort = this.body?.words?.indexOf(material?.id) + 1;
              word.word = material.name;
              word.translation = material.metadata?.translation;
              word.explanation = material.metadata?.explanation;
              word.examples = material.metadata?.examples;
              word.wordClass = material.metadata?.word_class;
              word.wordConstruction = material.metadata?.word_construction;

              return word;
            });

            wordlist.words.addObjects(words);
          })
          .then(() => wordlist);
      });
    }
  },

  customizableDescendents: computed(
    'canHaveCustomizableChildren',
    'children.[]',
    function () {
      if (this.canHaveCustomizableChildren) {
        return createPromiseArray(
          this.children.then((children) => {
            return all(children.getEach('customizableDescendents')).then(
              (descendents) => {
                return [...children.toArray(), ...descendents.flat()]
                  .rejectBy('isTeacherAssignment')
                  .rejectBy('isTeacherCollection');
              }
            );
          })
        );
      }

      return createPromiseArray(resolve([]));
    }
  ),

  eachParent(callback) {
    return this.parent
      .then((parent) => {
        if (parent) {
          if (callback != null) {
            callback(parent);
          }
          return parent.eachParent(callback);
        }
      })
      .catch(() => {});
  },

  getParents() {
    const parents = [];

    const add = (parent) => {
      parents.addObject(parent);
    };

    return this.eachParent(add).then(() => {
      return all(parents);
    });
  },

  // PRIVATE

  generateHash(s) {
    return s.split('').reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  },
});
